import { I18nextContext } from "gatsby-plugin-react-i18next";
import { useContext } from "react";

export function useModelSlugPrefix(apiKey) {
  const language = useContext(I18nextContext).language;

  switch (apiKey) {
    case "news":
      return `/${language === "nl" ? "nieuws" : "news"}/`;
    case "case":
      return `/customer-success-stories/`;
    case "sector":
      return `/${language === "nl" ? "sectoren" : "industries"}/`;
    case "job":
      return `/${language === "nl" ? "jobs" : "careers"}/`;
    case "author":
      return `/${language === "nl" ? "auteur" : "author"}/`;
    case "event":
    case "service":
      return `/${apiKey}s/`;
    case "product":
      return `/${language === "nl" ? "producten" : "products"}/`;
    default:
      return `/${apiKey}/`;
  }
}
