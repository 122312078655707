import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { useModelSlugPrefix } from "../hooks/link-util";

const imageUtil = require("../utils/image-util.js");

export default function AuthorBlock({ data }) {
  const { t } = useTranslation();

  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container d-flex flex-column align-items-start p-4">
        <div className="row w-full">
          <div
            className={`${
              data.onBlogPage ? "" : "col-xl-6 offset-xl-3"
            } d-flex flex-column align-items-start p-5 px-6 author`}
          >
            {getAuthorPhoto(data.author.photo)}
            <div className="d-flex flex-column align-items-center justify-content-center flex-wrap w-full">
              <div className="m-0 color-gray-400 font-size-700 font-weight-700">
                {t("author").toUpperCase()}
              </div>
              <Link
                to={`${useModelSlugPrefix("author")}${data.author.pageSlug}`}
                aria-label={`${t("go-to-author-page")} ${data.author.fullName}`}
              >
                <div className="m-0 font-size-700 font-weight-700 mb-3">
                  {data.author.fullName}
                </div>
              </Link>
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{ __html: data.author.bio }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getAuthorPhoto(photo) {
  if (photo) {
    return (
      <div className="author__image d-flex flex-row justify-content-center flex-wrap w-full mb-3">
        {imageUtil.getImageElements(
          photo,
          "m-0 w-92 h-90 overflow-hidden border-radius-circle"
        )}
      </div>
    );
  }
  return (
    <div className="author__image d-flex flex-row justify-content-center flex-wrap w-full mb-3">
      <StaticImage
        src="../images/placeholder-avatar.jpg"
        alt="Avatar placeholder"
        className="m-0 w-92 h-90 overflow-hidden border-radius-circle"
      />
    </div>
  );
}

export const authorFragment = graphql`
  fragment author on DatoCmsAuthor {
    id
    model {
      apiKey
    }
    author {
      bio
      pageSlug
      fullName
      photo {
        gatsbyImageData(width: 100)
        format
        url
        alt
      }
    }
    blockId
  }
`;
