import * as React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout/layout";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsPageNotFound.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function NotFoundPage({ data, location }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !location?.pathname.includes("/en/404") &&
      !location?.pathname.includes("/nl/404")
    ) {
      navigate(`/${location.pathname.includes("/nl/") ? "nl" : "en"}/404/`);
    }
  }, []);

  return (
    <Layout>
      <main role="main">
        <div className="py-6">
          <div className="py-6">
            <div
              className={`container ${
                !location?.pathname.includes("/en/404") &&
                !location?.pathname.includes("/nl/404")
                  ? "visibility-hidden"
                  : ""
              }`}
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="my-3">
                    <h1 className="mb-0 font-size-900 line-height-1 mb-4">
                      {t("page-not-found")}
                    </h1>
                    <div className="font-size-700 color-gray-500 mb-5">
                      <h2>
                        {t("page-does-not-exist")}
                        <br />
                        {t("other-helpful-links")}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <ul className="p-0 list-style-none">
                      {data.datoCmsPageNotFound.links.map((link) => {
                        return getLinkDisplay(link);
                      })}
                      <li key="sitemap">
                        <Link
                          to="/sitemap"
                          className="btn hover-bg-transparent color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 hover-text-decoration-underline focus-text-decoration-underline"
                        >
                          Sitemap
                          <i
                            aria-hidden={true}
                            className="ti ti-chevron-right ml-1"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 py-3">
                  <div className="w-full">
                    <StaticImage src="../images/image-404.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

function getLinkDisplay(link) {
  return (
    <li key={link.id}>
      <Link
        to={"/" + link.slug}
        className="btn hover-bg-transparent color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 hover-text-decoration-underline focus-text-decoration-underline"
      >
        {link.text}
        <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
      </Link>
    </li>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsPageNotFound(locale: $language) {
      id
      seoMetaTags(locale: $language) {
        tags
      }
      links {
        id
        text
        slug
      }
    }
  }
`;
