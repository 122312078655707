import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import LinkToModel3Block from "../building-blocks/link-to-model-3-block";
import Layout from "../components/layout/layout";
import { getBlock, getSectionClassesWithPaddingTop } from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsCase.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function CaseModel({ data, location }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const customerCase = data.datoCmsCase;

  const threeNewestRelatedCases = {
    items: data.threeRelatedCases.nodes,
  };
  const threeMostRecentCases = {
    items: data.threeMostRecentCases.nodes,
  };

  return (
    <Layout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredArticleData() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        <section role="region" className="container pt-6">
          <div className="row">
            <div className="col-12">
              <Link
                to={
                  location?.state?.previous
                    ? `${location.state.previous}${window.location.search}`
                    : "/customer-success-stories"
                }
                className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                <i aria-hidden={true} className="ti ti-chevron-left mr-2" />
                {location?.state?.previous || location?.search
                  ? t("back-to-search")
                  : t("back-to-cases-overview")}
              </Link>
            </div>
          </div>
        </section>
        <div className="container pt-4 ">
          <div className="row">
            <div className="col-xl-12 offset-xl-0">
              {getCategoryEntry(customerCase.primaryCategory.categoryName)}
              {customerCase.secondaryCategory.map((category) =>
                getCategoryEntry(category.categoryName)
              )}
            </div>
          </div>
        </div>
        {customerCase.content.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                customerCase.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-0">
              <div className="d-flex flex-column">
                <div className="heading">
                  <h2 className="mb-0">
                    {threeNewestRelatedCases.items.length > 0
                      ? t("related-cases")
                      : t("latest-cases")}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LinkToModel3Block
          data={
            threeNewestRelatedCases.items.length > 0
              ? threeNewestRelatedCases
              : threeMostRecentCases
          }
        />
      </main>
    </Layout>
  );

  function getStructuredArticleData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${customerCase.headTitle}",
      "image": [
        "${customerCase.coverImage?.url}"
       ],
      "datePublished": "${customerCase.meta.createdAt}",
      "dateModified": "${customerCase.meta.updatedAt}"
    }`;
  }

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/customer-success-stories"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${customerCase.headTitle}",
        "item": "https://www.acagroup.be/${language}/customer-success-stories/${customerCase.pageSlug}"
      }]
    }`;
  }
}

function getCategoryEntry(categoryName) {
  return (
    <Link
      to={`/customer-success-stories/?filter=${categoryName
        .replace("&", "$amp")
        .split(" ")
        .join("+")}`}
      href=""
      key={categoryName}
      className="picture__tag hover-bg-tertiary-500 hover-color-white hover-text-decoration-none color-tertiary-500 font-size-300 font-weight-700 p-1 mr-2"
    >
      {categoryName.toUpperCase()}
    </Link>
  );
}

export const query = graphql`
  query (
    $language: String!
    $originalId: String!
    $primaryCategoryId: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsCase(originalId: { eq: $originalId }, locale: $language) {
      ...caseFull
    }
    threeMostRecentCases: allDatoCmsCase(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: { originalId: { ne: $originalId } }
    ) {
      nodes {
        ...caseShort
      }
    }
    threeRelatedCases: allDatoCmsCase(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: {
        originalId: { ne: $originalId }
        primaryCategory: { id: { eq: $primaryCategoryId } }
      }
    ) {
      nodes {
        ...caseShort
      }
    }
    pageSlugs: datoCmsCase(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
