import React, { useContext } from "react";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";

export default function MenuItemDesktop({ link, title, iconClasses }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <Link
      to={`/${link}`}
      className="d-flex hover-bg-silver-100 hover-color-quaternary-500 border-radius line-height-normal p-3 align-items-center"
      hrefLang={language}
    >
      <span aria-hidden={true} className="color-primary-500 font-size-400 mr-3">
        <i className={iconClasses + " font-size-700"} />
      </span>
      <span className="d-flex flex-column overflow-hidden">
        <span className="color-black font-size-400 font-weight-600">
          {t(title)}
        </span>
      </span>
    </Link>
  );
}
