import React from "react";

export default function SocialMediaIconLinkComponent({
  url,
  platform,
  iconClasses,
}) {
  if (!url) {
    return "";
  }

  return (
    <li className="mr-1 btn btn-icon d-flex">
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        className="color-black hover-color-quaternary-500"
      >
        <i className={iconClasses} aria-hidden="true" />
        <span className="accessibility-visually-hidden">{platform}</span>
      </a>
    </li>
  );
}
