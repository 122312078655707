import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext, useEffect, useState } from "react";
import ContactPersonBlock from "../building-blocks/contact-person-block";
import TitleBlock from "../building-blocks/title-block";
import WorkAtAcaLayout from "../components/layout/work-at-aca-layout";
import {
  getBlock,
  getSectionClassesWithoutPaddingTop,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import ShareOnSocialMediaComponent from "../components/share-on-social-media-component";
import LinkToModel3Block from "../building-blocks/link-to-model-3-block";
import SEO from "../seo/seo";

const dateUtil = require("../hooks/date-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsJob.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function JobModel({ data, location }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const jobData = data.datoCmsJob;
  const latestAcaWeekendBlogPageSlug = data.acaWeekendBlogs.nodes[0]?.pageSlug;
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    setShareUrl(`${window?.location.origin}${window?.location.pathname}`);
  });

  const titleBlock = {
    headTitle: `<h1>${jobData.jobName}</h1>`,
    subTitle: `<h2 class="color-gray-400 h4-styled">${jobData.subtitle}</h2>`,
    description: jobData.shortDescription,
    showImageMobile: jobData.showImageMobile,
    image: jobData.image,
    mediaType: "image",
  };

  const casesAndBlogsList = {
    items: getCasesAndBlogs(
      data.threeRelatedBlogs.nodes,
      data.threeRelatedCases.nodes,
      data.threeMostRecentCases.nodes,
      data.threeMostRecentBlogs.nodes
    ),
  };

  return (
    <WorkAtAcaLayout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredJobData(jobData) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        {getApplicationSubmittedResponse(location?.state?.response)}
        <section role="region" className="container pt-6">
          <div className="row">
            <div className="col-12">
              <Link
                to={
                  location?.state?.previous
                    ? `${location.state.previous}`
                    : `${language === "nl" ? "/jobs" : "/careers"}`
                }
                state={{
                  appliedFilter: location?.state?.appliedFilter,
                }}
                className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                <i aria-hidden={true} className="ti ti-chevron-left mr-2" />
                {t("back-to-jobs-overview")}
              </Link>
            </div>
          </div>
        </section>
        <section role="region" className="pt-3">
          <TitleBlock data={titleBlock} />
        </section>
        <section role="region" className="background last">
          <div className="container">
            <h2>{t("already-eager-to-work-with-us")}</h2>
            <Link
              to={
                language === "nl"
                  ? "/job-applicatie-formulier"
                  : "/job-application-form"
              }
              className="btn font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
              state={{
                jobName: jobData.jobName,
                jobPageSlugs: getPageSlugs(data),
                shortMotivationRequired: jobData.shortMotivationRequired,
              }}
            >
              {t("click-here-to-fill-in-an-application-form")}
            </Link>
          </div>
        </section>
        {getExtraInfoSection(jobData.extraInfo)}
        <section role="region">
          <div className="container">
            <h2>{t("do-you-have-what-it-takes")}</h2>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 p-0">
                  <h3>{t("hard-skills")}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: jobData.hardskills }}
                  />
                </div>
                <div className="col-lg-6 p-0">
                  <h3>{t("soft-skills")}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: jobData.softskills }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {jobData.content?.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithoutPaddingTop(
                jobData.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
        <section role="region" className="py-2">
          <div className="container">
            <h2>{t("our-application-process")}</h2>
            <p>{t("our-application-process-description")}</p>
            {getApplicationProcess()}
          </div>
        </section>
        <section role="region" className="py-2">
          <div className="container">
            <h2>{t("what-is-working-at-aca-like")}</h2>
            <div className="py-2">
              <div className="container">
                <ul className="row list-style-none">
                  {getWhatIsWorkingAtAcaInnovativeEnvironmentBlock(
                    "rocket",
                    t("innovative-work-environment"),
                    t("innovative-work-environment-description")
                  )}
                  {getWhatIsWorkingAtAcaSalaryBlock(
                    "coin",
                    t("customized-salary-package"),
                    t("customized-salary-package-description"),
                    t("customized-salary-package-description-disclaimer")
                  )}
                  {getWhatIsWorkingAtAcaLearnAndGrowBlock(
                    "school hat",
                    t("learn-and-grow")
                  )}
                </ul>
                <ul className="row list-style-none">
                  {getWhatIsWorkingAtAcaBlock(
                    "heart",
                    t("we-got-your-back"),
                    t("we-got-your-back-description")
                  )}
                  {getWhatIsWorkingAtAcaBlock(
                    "smile",
                    t("our-unique-company-culture"),
                    t("our-unique-company-culture-description")
                  )}
                  {getWhatIsWorkingAtAcaEmployeeCentricityBlock(
                    latestAcaWeekendBlogPageSlug
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section role="region">
          <div className="container">
            <h2 className="mb-0">{t("what-we-have-been-up-to")}</h2>
          </div>
          <LinkToModel3Block data={casesAndBlogsList} />
        </section>
        <section role="region" className="background last">
          <div className="container">
            <h2>{`${t("are-you-our-next")} ${jobData.jobName}?`}</h2>
            <Link
              to={
                language === "nl"
                  ? "/job-applicatie-formulier"
                  : "/job-application-form"
              }
              className="btn font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
              state={{
                jobName: jobData.jobName,
                jobPageSlugs: getPageSlugs(data),
                shortMotivationRequired: jobData.shortMotivationRequired,
              }}
            >
              {t("click-here-to-fill-in-an-application-form")}
            </Link>
          </div>
        </section>
        <section role="region">
          <ShareOnSocialMediaComponent url={shareUrl} />
        </section>
        <section role="region">
          {getContactPersonBlock(jobData.contactPerson)}
        </section>
      </main>
    </WorkAtAcaLayout>
  );

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/${
      language === "en" ? "careers" : "jobs"
    }"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${jobData.jobName}",
        "item": "https://www.acagroup.be/${language}/${
      language === "en" ? "careers" : "jobs"
    }/${jobData.pageSlug}"
      }]
    }`;
  }

  function getCasesAndBlogs(
    relatedBlogs,
    relatedCases,
    mostRecentBlogs,
    mostRecentCases
  ) {
    let relatedItems = relatedBlogs
      .concat(relatedCases)
      .sort(function (a, b) {
        return new Date(b.meta.createdAt) - new Date(a.meta.createdAt);
      })
      .slice(0, 3);
    let mostRecentItems = mostRecentBlogs
      .concat(mostRecentCases)
      .sort(function (a, b) {
        return new Date(b.meta.createdAt) - new Date(a.meta.createdAt);
      })
      .slice(0, 3);

    let i = 0;
    let usedIds = [];

    relatedItems.forEach((relatedItem) => {
      usedIds.push(relatedItem.id);
    });

    while (relatedItems.length < 3) {
      if (!usedIds.includes(mostRecentItems[i].id)) {
        relatedItems.push(mostRecentItems[i]);
        usedIds.push(mostRecentItems[i].id);
      }

      i++;
    }

    return relatedItems;
  }
}

function getStructuredJobData(jobData) {
  return `
    {
      "@context" : "https://schema.org/",
      "@type" : "JobPosting",
      "datePosted" : "${dateUtil.useDateFormat(
        jobData.meta.createdAt,
        "YYYY-MM-DD"
      )}",
      "description" : "${jobData.subtitle.replace(
        /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g,
        ""
      )}",
      "hiringOrganization" : {
        "@type" : "Organization",
        "name" : "ACA Group",
        "sameAs" : "https://www.acagroup.be/en/",
        "logo" : "https://www.acagroup.be/images/ACA-Group.svg"
      },
      "jobLocation": [{
      "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Herkenrodesingel 8B 2.01",
          "addressLocality": "Hasselt",
          "addressRegion": "Limburg",
          "postalCode": "3500",
          "addressCountry": "BE"
        }
      },{
        "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dublinstraat 31/010",
            "addressLocality": "Gent",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9000",
            "addressCountry": "BE"
          }
        }],
      "title" : "${jobData.jobName}",
      "validThrough" : "${
        jobData.validThrough
          ? dateUtil.useDateFormat(jobData.validThrough, "YYYY-MM-DDTHH:mm")
          : "9999-12-31T00:00"
      }"
    }
  `;
}

function getApplicationSubmittedResponse(response) {
  const { t } = useTranslation();

  if (response === "ok") {
    return (
      <section
        role="region"
        className="bg-success-a-200 m-0 pb-2 pt-6 py-md-2 success-border"
      >
        <div className="container">
          <h2 className="my-2">{t("application-submitted-success-title")}</h2>
          <p>{t("application-submitted-success-description")}</p>
        </div>
      </section>
    );
  } else if (response === "error") {
    return (
      <section
        role="region"
        className="bg-danger-a-200 m-0 pb-2 pt-6 py-md-2 danger-border"
      >
        <div className="container">
          <h2 className="mt-1">{t("oh-no-an-error-occurred")}</h2>
          <p>
            {t("application-submitted-error-description")}{" "}
            <Link to={/contact/}> {t("this-page")}</Link>.
          </p>
        </div>
      </section>
    );
  }
}

function getApplicationProcess() {
  const { t } = useTranslation();

  return (
    <div className="py-2">
      <div className="container p-0">
        <div className="row job">
          <div className="col-md-12 col-lg-12 py-3 p-0 d-flex process">
            <div className="process__bar process__bar--4"></div>
            {getProcessStep(
              "phone",
              t("fill-in-the-application-form"),
              t("fill-in-the-application-form-description")
            )}
            {getProcessStep(
              "messages",
              t("interview-time"),
              t("interview-time-description")
            )}
            {getProcessStep(
              "documents",
              t("we-will-make-you-an-offer"),
              t("we-will-make-you-an-offer-description")
            )}
            {getProcessStep(
              "users",
              t("welcome-to-the-team"),
              t("welcome-to-the-team-description")
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getContactPersonBlock(contactPerson) {
  const { t } = useTranslation();

  const contactPersonBlockData = {
    title: `<h2>${t("want-to-contact-the-recruiter")}?</h2>`,
    subtitle: `<p>${contactPerson.name} ${t("is-ready-to-help")}</p>`,
    person: contactPerson,
  };

  return <ContactPersonBlock data={contactPersonBlockData} />;
}

function getExtraInfoSection(extraInfo) {
  const { t } = useTranslation();

  if (extraInfo) {
    return (
      <section role="region" className="py-2 pb-6">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-0">
              <h2>{t("your-role-at-aca")}</h2>
              <div dangerouslySetInnerHTML={{ __html: extraInfo }} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function getWhatIsWorkingAtAcaInnovativeEnvironmentBlock(
  iconName,
  title,
  description
) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <div className="col-md-6 col-lg-4 py-3 px-0">
      <div className="py-4 border-radius-lg h-full">
        <div className="mb-3">{getStaticImage(iconName)}</div>
        <div className="font-size-700 line-height-sm font-weight-700 mb-3">
          {title}
        </div>
        <div className="pr-md-6 font-size-500">
          {description}{" "}
          <Link
            className="d-inline"
            to={
              language === "nl"
                ? "/page/ons-team/#flexibel-werken"
                : "/page/our-team/#flexible-work"
            }
          >
            {t("working-from-home")}
          </Link>{" "}
          {t("arrangement")}
        </div>
      </div>
    </div>
  );
}

function getWhatIsWorkingAtAcaEmployeeCentricityBlock(acaWeekendBlogPageSlug) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <div className="col-md-6 col-lg-4 py-3 px-0">
      <div className="py-4 border-radius-lg h-full">
        <div className="mb-3">{getStaticImage("user")}</div>
        <div className="font-size-700 line-height-sm font-weight-700 mb-3">
          {t("employee-centricity")}
        </div>
        <div className="pr-md-6 font-size-500">
          {`${t("employee-centricity-description-1")} `}
          <Link className="d-inline" to={`/blog/${acaWeekendBlogPageSlug}`}>
            {t("aca-weekend")}
          </Link>
          {language === "nl" ? "!" : t("employee-centricity-description-2")}
        </div>
      </div>
    </div>
  );
}

function getWhatIsWorkingAtAcaLearnAndGrowBlock(iconName, title) {
  const { t } = useTranslation();

  return (
    <div className="col-md-6 col-lg-4 py-3 px-0">
      <div className="py-4 border-radius-lg h-full">
        <div className="mb-3">{getStaticImage(iconName)}</div>
        <div className="font-size-700 line-height-sm font-weight-700 mb-3">
          {title}
        </div>
        <div className="pr-md-6 font-size-500">
          {t("working-aca-description-1")}
          {t("working-aca-description-2")}
          <span className="font-italic">{t("working-aca-description-3")}</span>
          <Link className="d-inline" to="/academy">
            {t("academy")}
          </Link>
          {t("working-aca-description-4")}
        </div>
      </div>
    </div>
  );
}

function getWhatIsWorkingAtAcaSalaryBlock(iconName, title, description) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <div className="col-md-6 col-lg-4 py-3 px-0">
      <div className="py-4 border-radius-lg h-full">
        <div className="mb-3">{getStaticImage(iconName)}</div>
        <div className="font-size-700 line-height-sm font-weight-700 mb-3">
          {title}
        </div>
        <div className="pr-md-6 font-size-500">
          {description}{" "}
          <Link
            className="d-inline"
            to={
              language === "nl"
                ? "/page/ons-team/#loonpakket"
                : "/page/our-team/#salary"
            }
          >
            {t("and-more")}
          </Link>
          <div className="mt-8 py-4 mr-16 font-size-400 line-height-sm font-weight-300">
            * {t("aca-wants-to-contribute")} ⚡
          </div>
        </div>
      </div>
    </div>
  );
}

function getWhatIsWorkingAtAcaBlock(iconName, title, description, disclaimer) {
  return (
    <li className="col-md-6 col-lg-4 py-3 px-0">
      <div className="py-4 border-radius-lg h-full">
        <div className="mb-3">{getStaticImage(iconName)}</div>
        <div className="font-size-700 line-height-sm font-weight-700 mb-3">
          {title}
        </div>
        <div className="pr-md-6 font-size-500">{description}</div>
        {getWhatIsWorkingAtAcaBlockDisclaimer(disclaimer)}
      </div>
    </li>
  );
}

function getWhatIsWorkingAtAcaBlockDisclaimer(disclaimer) {
  if (disclaimer) {
    return <div className="mt-3 pr-md-6 font-size-400">{disclaimer}</div>;
  }
}

function getProcessStepStaticImage(iconName) {
  switch (iconName) {
    case "phone":
      return <i aria-hidden={true} className="ti ti-phone font-size-900" />;
    case "messages":
      return <i aria-hidden={true} className="ti ti-messages font-size-900" />;
    case "documents":
      return (
        <svg
          aria-hidden="true"
          role="img"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-ad-2"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="2px"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M11.933 5h-6.933v16h13v-8"></path>
          <path d="M14 17h-5"></path>
          <path d="M9 13h5v-4h-5z"></path>
          <path d="M15 5v-2"></path>
          <path d="M18 6l2 -2"></path>
          <path d="M19 9h2"></path>
        </svg>
      );
    case "users":
      return <i aria-hidden={true} className="ti ti-users font-size-900" />;
  }
}

function getProcessStep(icon, title, description) {
  return (
    <div className="d-flex m-3">
      <div className="process-entry w-92 h-92 overflow-hidden border-radius-circle d-flex justify-content-center align-items-center mb-3 flex-shrink-0">
        {getProcessStepStaticImage(icon)}
      </div>
      <div className="ml-3 ml-lg-0">
        <div className="w-full mt-3 font-size-700 font-size-sm-500 font-weight-700">
          {title}
        </div>
        <div className="w-full mt-3">{description}</div>
      </div>
    </div>
  );
}

function getStaticImage(iconPath) {
  switch (iconPath) {
    case "rocket":
      return <i aria-hidden={true} className="ti ti-rocket font-size-900" />;
    case "coin":
      return <i aria-hidden={true} className="ti ti-coin font-size-900" />;
    case "school hat":
      return <i aria-hidden={true} className="ti ti-school font-size-900" />;
    case "heart":
      return <i aria-hidden={true} className="ti ti-heart font-size-900" />;
    case "smile":
      return (
        <i aria-hidden={true} className="ti ti-mood-smile font-size-900" />
      );
    case "user":
      return (
        <i aria-hidden={true} className="ti ti-user-check font-size-900" />
      );
  }
}

export const query = graphql`
  query (
    $language: String!
    $originalId: String!
    $sortedBlogCategoryIds: [String]!
    $sortedCaseCategoryIds: [String]!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsJob(originalId: { eq: $originalId }, locale: $language) {
      ...jobFull
    }
    acaWeekendBlogs: allDatoCmsBlog(
      locale: $language
      filter: {
        secondaryCategory: {
          elemMatch: { categoryName: { eq: "ACA-weekend" } }
        }
      }
      sort: { meta: { createdAt: DESC } }
      limit: 1
    ) {
      nodes {
        id
        meta {
          createdAt
        }
        pageSlug
      }
    }
    threeMostRecentCases: allDatoCmsCase(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
    ) {
      nodes {
        ...caseShort
        meta {
          createdAt
        }
      }
    }
    threeRelatedCases: allDatoCmsCase(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: { primaryCategory: { id: { in: $sortedCaseCategoryIds } } }
    ) {
      nodes {
        ...caseShort
        meta {
          createdAt
        }
      }
    }
    threeMostRecentBlogs: allDatoCmsBlog(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
    ) {
      nodes {
        ...relatedBlogLink
        meta {
          createdAt
        }
      }
    }
    threeRelatedBlogs: allDatoCmsBlog(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: { primaryCategory: { id: { in: $sortedBlogCategoryIds } } }
    ) {
      nodes {
        ...relatedBlogLink
        meta {
          createdAt
        }
      }
    }
    pageSlugs: datoCmsJob(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
