import React from "react";
import { graphql } from "gatsby";

export default function RichTextBlock({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <div className="row">
          <div className={getAlignment(data.alignment)}>
            <div className="d-flex flex-column">
              <div
                className={
                  data.isHeadingOnly
                    ? "heading"
                    : "html-content custom-html__wrapper"
                }
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getAlignment(alignment) {
  switch (alignment) {
    case "Full":
      return "col-xl-12 offset-xl-0";
    case "Left":
      return "col-xl-6 offset-xl-0";
    case "Right":
      return "col-xl-6 offset-xl-6";
    default:
      return "col-xl-6 offset-xl-3";
  }
}

export const richTextFragment = graphql`
  fragment richText on DatoCmsRichText {
    id
    model {
      apiKey
    }
    greyBackground
    alignment
    blockId
    content
    isHeadingOnly
  }
`;
