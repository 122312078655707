import React from "react";

export default function ShareOnSocialMediaButtonComponent({
  children,
  network,
  url,
  text,
  ariaLabel,
}) {
  return (
    <button
      title={ariaLabel}
      aria-label={ariaLabel}
      onClick={() => windowOpen(getShareUrl())}
    >
      {children}
    </button>
  );

  function getShareUrl() {
    switch (network) {
      case "twitter":
        return `https://twitter.com/share${objectToGetParams({
          url,
          text,
        })}`;
      case "facebook":
        return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
          u: url,
          quote: text ? text : "",
        })}`;
      case "linkedin":
        return `https://linkedin.com/shareArticle${objectToGetParams({
          url,
          title: text,
        })}`;
    }
  }

  function objectToGetParams(object) {
    const params = Object.keys(object).filter((key) => !!object[key]);

    if (!params.length) {
      return "";
    }

    return (
      "?" +
      params.map((key) => `${key}=${encodeURIComponent(object[key])}`).join("&")
    );
  }

  function windowOpen(url, width = 650, height = 450) {
    const left =
      window.outerWidth / 2 +
      (window.screenX || window.screenLeft || 0) -
      width / 2;
    const top =
      window.outerHeight / 2 +
      (window.screenY || window.screenTop || 0) -
      height / 2;
    const config = {
      height,
      width,
      left,
      top,
      location: "no",
      toolbar: "no",
      status: "no",
      directories: "no",
      menubar: "no",
      scrollbars: "yes",
      resizable: "no",
      centerscreen: "yes",
      chrome: "yes",
    };
    const shareDialog = window.open(
      url,
      "share",
      Object.keys(config)
        .map((key) => `${key}=${config[key]}`)
        .join(",")
    );

    return shareDialog;
  }
}
