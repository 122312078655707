import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function DownloadHitComponent({ hit }) {
  const { t } = useTranslation();

  return (
    <div className="event-item" tabIndex="0">
      <div className="container">
        <div className="row">
          <div className="col-12 pb-4">
            <div className="font-size-700 line-height-sm font-weight-700 pb-4 border-bottom">
              <div className="font-weight-600 font-size-700">
                <a href={hit.slug} download>
                  {hit.title}
                </a>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="col-lg-8">
                  <div
                    className="my-1 color-gray-500 font-weight-300 font-size-500"
                    dangerouslySetInnerHTML={{ __html: hit.description }}
                  />
                  <div className="my-1 color-tertiary-500 font-weight-500 font-size-400">
                    {hit.primaryCategory}
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="ml-3">
                    <a
                      download
                      href={hit.slug}
                      className="btn mt-4 font-weight-600 font-size-500 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
                    >
                      {t("download-action")}{" "}
                      <i aria-hidden={true} className="ti ti-download ml-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
