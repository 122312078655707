import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useState } from "react";
import Layout from "../components/layout/layout";
import LoadMoreButtonComponent, {
  LoadMoreContext,
} from "../components/load-more-button-component";
import SEO from "../seo/seo";

const imageUtil = require("../utils/image-util.js");
const dateUtil = require("../hooks/date-util");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsEventOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function EventsPage({ data }) {
  const highlightedEvents = getAllHighlightedEvents(data);
  const upcomingEvents = getAllUpcomingEvents(data);
  const pastEvents = getAllPastEvents(data);
  const { t } = useTranslation();
  const [previousAmountOfEntriesShown, setPreviousAmountOfEntriesShown] =
    useState(-1);
  const [amountOfPastEntriesShown, setAmountOfPastEntriesShown] = useState(5);

  let firstNewEvent = null;

  React.useEffect(() => {
    firstNewEvent?.focus();
  }, [previousAmountOfEntriesShown]);

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="container">
          <div className="my-0 py-2">
            <h1>Events</h1>
          </div>
        </div>
        <div className="py-2">
          <div className="container">
            {highlightedEvents.map((event) => getHighlightedEvent(event))}
          </div>
          <div className="container">
            <h2>{t("upcoming-events")}</h2>
            {upcomingEvents.length > 0
              ? getUpcomingEvents(upcomingEvents)
              : getEmptyMessage()}
          </div>
          <div className="container">
            <h2>{t("past-events")}</h2>
            <ul className="row list-style-none">
              {getEventEntries(pastEvents, amountOfPastEntriesShown)}
            </ul>
            <LoadMoreContext.Provider
              value={{
                amountOfEntriesShown: amountOfPastEntriesShown,
                setAmountOfEntriesShown: setAmountOfPastEntriesShown,
                setPreviousAmountOfEntriesShown,
                length: pastEvents.length,
                renderAmount: 5,
              }}
            >
              <LoadMoreButtonComponent></LoadMoreButtonComponent>
            </LoadMoreContext.Provider>
          </div>
        </div>
      </main>
    </Layout>
  );

  function getAllHighlightedEvents(data) {
    return data.allDatoCmsEvent.nodes
      .filter((event) => event.highlightItem)
      .filter((event) => isUpcomingEvent(event))
      .sort(
        (event1, event2) => Date.parse(event1.date) > Date.parse(event2.date)
      );
  }

  function getAllUpcomingEvents(data) {
    const allUpcomingEvents = data.allDatoCmsEvent.nodes
      .filter((event) => isUpcomingEvent(event))
      .sort(
        (event1, event2) => Date.parse(event1.date) > Date.parse(event2.date)
      );

    if (
      allUpcomingEvents.filter((event) => !event.highlightItem).length === 0 &&
      allUpcomingEvents.length > 0
    ) {
      return allUpcomingEvents;
    }

    return allUpcomingEvents.filter((event) => !event.highlightItem);
  }

  function getAllPastEvents(data) {
    return data.allDatoCmsEvent.nodes
      .filter((event) => isPastEvent(event))
      .sort(
        (event1, event2) => Date.parse(event2.date) - Date.parse(event1.date)
      );
  }

  function isUpcomingEvent(event) {
    if (event.untilDate == null) {
      return dateUtil.useDateIsTodayOrAfterToday(event.date);
    } else {
      return dateUtil.useDateIsTodayOrAfterToday(event.untilDate);
    }
  }

  function isPastEvent(event) {
    if (event.untilDate == null) {
      return dateUtil.useDateIsBeforeToday(event.date);
    } else {
      return dateUtil.useDateIsBeforeToday(event.untilDate);
    }
  }

  function getHighlightedEvent(event) {
    return (
      <div className="row d-flex" key={event.id}>
        {event.externalEventUrl ? (
          <a
            className="col-lg-6 py-3 d-lg-none"
            href={event.externalEventUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            {getHighlightedImageDisplay(event)}
          </a>
        ) : (
          <Link
            to={"/events/" + event.pageSlug}
            className="col-lg-6 py-3 d-lg-none"
          >
            {getHighlightedImageDisplay(event)}
          </Link>
        )}
        <div className="col-lg-6 py-3 d-flex flex-column">
          <div className="my-3">
            <div className="rich-text">
              <h2 className="h1-styled">{event.headTitle}</h2>
              <h3 className="h4-styled color-gray-400">
                {`${dateUtil.useDateFormat(event.date, "DD MMM YYYY")} ${
                  event.untilDate && event.untilDate !== event.date
                    ? " - " +
                      dateUtil.useDateFormat(event.untilDate, "DD MMM YYYY")
                    : ""
                }`}
              </h3>
              <h4 className="color-gray-400 font-weight-400">{`${
                event.eventType.categoryName
              } - ${event.primaryCategory.categoryName} - ${
                event.presentationMethod.categoryName
              }${event.academy ? " - ACAdemy" : ""}`}</h4>
              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: event.excerpt }}
              />
            </div>
            <div className="my-3 d-flex flex-column justify-content-end flex-grow-1">
              {event.externalEventUrl ? (
                <a
                  href={event.externalEventUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t("check-out-this-event")}{" "}
                  <i aria-hidden={true} className="ti ti-chevron-right" />
                </a>
              ) : (
                <Link to={"/events/" + event.pageSlug}>
                  {t("check-out-this-event")}{" "}
                  <i aria-hidden={true} className="ti ti-chevron-right" />
                </Link>
              )}
            </div>
          </div>
        </div>
        {event.externalEventUrl ? (
          <a
            className="col-lg-6 py-3 d-none d-lg-block"
            href={event.externalEventUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            {getHighlightedImageDisplay(event)}
          </a>
        ) : (
          <Link
            to={"/events/" + event.pageSlug}
            className="col-lg-6 py-3 d-none d-lg-block"
          >
            {getHighlightedImageDisplay(event)}
          </Link>
        )}
      </div>
    );
  }

  function getUpcomingEvents(upcomingEvents) {
    return (
      <ul className="row list-style-none">
        {getEventEntries(upcomingEvents, upcomingEvents.length)}
      </ul>
    );
  }

  function getEventEntries(events, amountOfEntriesShown) {
    let eventEntries = [];

    const entriesToShow = Math.min(amountOfEntriesShown, events.length);

    for (let i = 0; i < entriesToShow; i++) {
      const event = events[i];

      eventEntries.push(
        <li
          className="py-2 event-item"
          key={event.id}
          tabIndex="0"
          ref={(event) => {
            if (i === previousAmountOfEntriesShown) {
              firstNewEvent = event;
            }
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 py-3">
                <div className="font-size-700 line-height-sm font-weight-700 py-3 border-bottom">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="event-item__date mr-6">
                      <span>{dateUtil.useDateFormat(event.date, "DD")}</span>{" "}
                      {dateUtil.useDateFormat(event.date, "MMM")}
                    </div>
                    <div className="flex-grow-1">
                      {event.externalEventUrl ? (
                        <a
                          href={event.externalEventUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <div className="rich-text">{event.headTitle}</div>
                        </a>
                      ) : (
                        <Link to={"/events/" + event.pageSlug}>
                          <div className="rich-text">{event.headTitle}</div>
                        </Link>
                      )}
                      <div
                        className="my-1 color-gray-500 font-weight-300 font-size-500"
                        dangerouslySetInnerHTML={{ __html: event.excerpt }}
                      />
                      <div className="my-1 color-tertiary-500 font-weight-600 font-size-500">
                        {`${event.eventType.categoryName} - ${
                          event.primaryCategory.categoryName
                        } - ${event.presentationMethod.categoryName}${
                          event.academy ? " - ACAdemy" : ""
                        }`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    }

    return eventEntries;
  }

  function getEmptyMessage() {
    return (
      <div className="container">
        <h3 className="mb-3">{t("upcoming-events-empty")}</h3>
      </div>
    );
  }

  function getHighlightedImageDisplay(event) {
    return (
      <div className="picture position-relative" data-testid="image">
        <div className="w-full">
          {imageUtil.getImageElements(
            event.image,
            "picture__image--highlighted d-flex"
          )}
        </div>
        <div className="picture__cover">
          <i
            aria-hidden={true}
            className="ti ti-arrow-narrow-right font-size-900 color-white"
          />
        </div>
      </div>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsEventOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
    }
    allDatoCmsEvent(locale: $language) {
      nodes {
        ...eventLong
      }
    }
  }
`;
