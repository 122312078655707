import { useWindowWidth } from "@react-hook/window-size";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Layout from "../components/layout/layout";
import LoadMoreButtonComponent, {
  LoadMoreContext,
} from "../components/load-more-button-component";
import SEO from "../seo/seo";

const imageUtil = require("../utils/image-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsCaseOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function CustomerSuccessStoriesPage({ data, location }) {
  const allAcaCases = data.allDatoCmsCase.nodes;
  const { t } = useTranslation();
  const filterRef = useRef(null);
  const openFilterButtonRef = useRef(null);
  const windowWidth = useWindowWidth();
  const [previousAmountOfEntriesShown, setPreviousAmountOfEntriesShown] =
    useState(-1);
  const [amountOfEntriesShown, setAmountOfEntriesShown] = useState(15);
  const [filteredAcaCases, setFilteredAcaCases] = useState(allAcaCases);
  const [filterOpened, setFilterOpened] = useState(false);
  const [filter, setFilter] = useState("");
  const acaCaseCategories = getAcaCasesCategories(allAcaCases);

  let firstNewEntry = null;
  let highlightedCases = filteredAcaCases.filter(
    (acaCase) => acaCase.highlightItem === true
  );
  let cases = filteredAcaCases.filter(
    (acaCase) => acaCase.highlightItem === false
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentFilter = urlParams.get("filter");

    location?.state?.appliedFilter
      ? setFilter(location.state.appliedFilter)
      : "";
    window.location.hash
      ? setFilter(decodeURI(window.location.hash).replace("#", ""))
      : "";

    if (currentFilter) {
      let currentFilterParts = currentFilter.split(" ");
      let transformedFilterParts = [];

      currentFilterParts.forEach((part) => {
        if (part === "$amp") {
          part = "&";
        }
        transformedFilterParts.push(part);
      });
      setFilter(transformedFilterParts.join(" "));
    }
  }, []);

  useEffect(() => {
    setFilteredAcaCases(
      allAcaCases.filter(
        (acaCase) =>
          getAcaCaseCategories(acaCase).includes(filter) || filter === ""
      )
    );

    if (filterOpened) {
      setFilterOpened(!filterOpened);
    }
  }, [filter]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        filterOpened &&
        filterRef.current !== null &&
        !filterRef.current.contains(e.target) &&
        openFilterButtonRef.current !== null &&
        !openFilterButtonRef.current.contains(e.target)
      ) {
        setFilterOpened(!filterOpened);
        document.body.classList = "";
      }
    };

    if (filterOpened) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [filterOpened]);

  useEffect(() => {
    firstNewEntry?.focus();
  }, [previousAmountOfEntriesShown]);

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="container">
          <div className="my-0 pb-0 py-xl-0 position-xl-relative">
            <div className="row">
              <h1 className="w-auto d-inline m-0 col-10">
                <span className="color-secondary-500">{filter} </span>
                {filter
                  ? t("customer-success-stories").toLowerCase()
                  : t("customer-success-stories")}
              </h1>
              {getOpenFilterButton()}
            </div>
            {getFilter()}
          </div>
        </div>
        <div className="py-2">
          <div className="container">
            {highlightedCases.map((acaCase) =>
              getHighlightedCase(acaCase, windowWidth)
            )}
            <ul className="row list-style-none">
              {getCaseEntries(cases, amountOfEntriesShown)}
            </ul>
            <LoadMoreContext.Provider
              value={{
                amountOfEntriesShown,
                setAmountOfEntriesShown,
                setPreviousAmountOfEntriesShown,
                length: cases.length,
              }}
            >
              <LoadMoreButtonComponent></LoadMoreButtonComponent>
            </LoadMoreContext.Provider>
          </div>
        </div>
        {getMobileOverlay()}
      </main>
    </Layout>
  );

  function getFilterOptions() {
    return acaCaseCategories.map((category) => {
      return (
        <li key={category} className="my-1">
          <button
            onClick={(event) => {
              event.preventDefault();
              setFilter(category);
              document.body.classList = "";
              window.location.hash = `#${encodeURI(category)}`;
            }}
            className={`color-primary-500 w-auto d-block pb-1 ${
              filter && category === filter
                ? "font-weight-800"
                : "font-weight-400"
            }`}
          >
            {category}
          </button>
        </li>
      );
    });
  }

  function getOpenFilterButton() {
    return (
      <div
        className="col-12 quick-filter-button col-sm-2 d-flex justify-content-end align-items-center"
        ref={openFilterButtonRef}
      >
        <button
          onClick={(event) => {
            event.preventDefault();
            setFilterOpened(!filterOpened);
            if (windowWidth < 1200) {
              document.body.className = "overflow-hidden";
            }
          }}
          aria-expanded={filterOpened}
          className="btn quick-filter-button font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
        >
          <i aria-hidden={true} className="ti ti-filter font-size-700 mr-3" />
          Filter posts
        </button>
      </div>
    );
  }

  function getDesktopFilterHeader() {
    if (windowWidth >= 1200) {
      return (
        <div className="py-3 border-bottom-dark">
          <button
            className="font-weight-400 color-primary-500"
            onClick={(event) => {
              event.preventDefault();
              setFilter("");
            }}
          >
            {t("all-customer-success-stories")}
          </button>
        </div>
      );
    }
  }

  function getMobileOverlay() {
    if (windowWidth < 1200 && filterOpened) {
      return <div className="mobile-filter__overlay" />;
    }
  }

  function getMobileFilterHeader() {
    if (windowWidth < 1200) {
      return (
        <div className="row py-3 border-bottom-dark">
          <button
            className="col-10 d-flex color-primary-500"
            onClick={(event) => {
              event.preventDefault();
              setFilter("");
              setFilterOpened(!filterOpened);
              document.body.classList = "";
            }}
          >
            {t("all-customer-success-stories")}
          </button>
          <button
            className="font-weight-700 font-size-600 color-black col-2 align-items-center justify-content-end"
            onClick={(event) => {
              event.preventDefault();
              setFilterOpened(!filterOpened);
              document.body.classList = "";
            }}
          >
            <span className="accessibility-visually-hidden">
              {t("close-filters")}
            </span>
            <i aria-hidden={true} className="ti ti-x font-size-700" />
          </button>
        </div>
      );
    }
  }

  function getFilter() {
    if (filterOpened) {
      return (
        <div className="d-flex align-items-center line-height-normal position-xl-absolute w-auto right-xl-0">
          <div
            ref={filterRef}
            className="dropdown-content position-fixed position-xl-relative z-index-xl-2 mobile-filter top-0 horizontal-center w-full shadow-lg filter-background border border-2 border-secondary-500 border-radius-lg"
          >
            <div className="px-3 py-1 w-min-320">
              <div className="form-group">
                <form role="form" className="pb-2">
                  <div className="">
                    {getDesktopFilterHeader()}
                    {getMobileFilterHeader()}
                    <ul className="px-0 mb-0 mt-3 list-style-none">
                      {getFilterOptions()}
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function getCaseEntries(cases, amountOfEntriesShown) {
    let acaCaseEntries = [];

    for (let i = 0; i < amountOfEntriesShown && i < cases.length; i++) {
      acaCaseEntries.push(
        <li
          data-testid="caseEntry"
          className="col-md-6 col-lg-4 py-3"
          key={cases[i].id}
          tabIndex="0"
          ref={(entry) => {
            if (i === previousAmountOfEntriesShown) {
              firstNewEntry = entry;
            }
          }}
        >
          <div className="py-4 border-radius-lg h-full">
            <Link
              to={"/customer-success-stories/" + cases[i].pageSlug}
              className="flex-column w-full"
            >
              <div className="mb-3 w-full" aria-hidden={true}>
                <div className="picture position-relative">
                  {imageUtil.getImageElements(cases[i].coverImage, "w-full")}
                  <div className="picture__cover">
                    <i className="ti ti-arrow-narrow-right font-size-900 color-white" />
                  </div>
                  <span className="picture__tag bg-white color-tertiary-500 font-size-300 font-weight-700 p-1 px-2 m-3 ">
                    {cases[i].primaryCategory.categoryName.toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="font-size-700 line-height-sm font-weight-700 mb-3 w-full">
                {cases[i].headTitle}
              </div>
            </Link>
            <div className="mb-3 font-weight-700 color-black-a-500">
              {cases[i].customerName}
            </div>
            <div className="mb-3 w-full">
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{ __html: cases[i].excerpt }}
              />
            </div>
          </div>
        </li>
      );
    }

    return acaCaseEntries;
  }

  function getHighlightedCase(acaCase) {
    return (
      <div className="row align-items-center" key={acaCase.id}>
        <Link
          to={"/customer-success-stories/" + acaCase.pageSlug}
          className="col-lg-6 py-3 d-lg-none"
          aria-label={acaCase.headTitle}
        >
          {getHighlightedImageDisplay(acaCase)}
        </Link>
        <div className="col-lg-6 py-3">
          <div className="my-3">
            <div
              onClick={(e) => {
                e.preventDefault();
                setFilter(acaCase.primaryCategory.categoryName);
              }}
              className="mb-0 color-tertiary-500 font-weight-700"
            >
              <span className="mb-0 color-tertiary-500 font-weight-700 hover-bg-tertiary-500 hover-color-white hover-text-decoration-none p-1">
                {acaCase.primaryCategory.categoryName.toUpperCase()}
              </span>
            </div>
            <Link to={"/customer-success-stories/" + acaCase.pageSlug}>
              <h2 className="mb-0">{acaCase.headTitle}</h2>
            </Link>
            <h4>{acaCase.customerName}</h4>
            <div
              className="rich-text mt-08 mb-48"
              dangerouslySetInnerHTML={{ __html: acaCase.excerpt }}
            />
          </div>
        </div>
        <Link
          to={"/customer-success-stories/" + acaCase.pageSlug}
          className="col-lg-6 py-3 d-none d-lg-block"
          aria-label={acaCase.headTitle}
        >
          {getHighlightedImageDisplay(acaCase)}
        </Link>
      </div>
    );
  }
}

function getHighlightedImageDisplay(acaCase) {
  return (
    <div className="picture position-relative" data-testid="image">
      <div className="w-full">
        {imageUtil.getImageElements(
          acaCase.coverImage,
          "picture__image--highlighted d-flex"
        )}
      </div>
      <div className="picture__cover">
        <i
          aria-hidden={true}
          className="ti ti-arrow-narrow-right font-size-900 color-white"
        />
      </div>
    </div>
  );
}

function getAcaCasesCategories(cases) {
  const primaryCategories = [];

  cases.map((acaCase) => {
    primaryCategories.push(acaCase.primaryCategory.categoryName);
  });

  return [...new Set(primaryCategories)].sort();
}

function getAcaCaseCategories(acaCase) {
  const categories = [];

  categories.push(acaCase.primaryCategory.categoryName);
  acaCase.secondaryCategory?.map((category) => {
    categories.push(category.categoryName);
  });

  return categories;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsCaseOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
    }
    allDatoCmsCase(locale: $language, sort: { meta: { createdAt: DESC } }) {
      nodes {
        ...caseLong
      }
    }
    primaryCategories: allDatoCmsCase(locale: $language) {
      distinct(field: { primaryCategory: { categoryName: SELECT } })
    }
  }
`;
