import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import ContactForm from "../components/contact/contact-form";
import Layout from "../components/layout/layout";
import SocialMediaIconLinkComponent from "../components/social-media-icon-link-component";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsContactPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function TalkToUsPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="pt-6">
        <div className="py-6 py-lg-0">
          <div className="container">
            <div className="row align-items-center">
              {getImageDisplay("d-lg-none")}
              <div className="col-lg-6 py-3">
                <div className="my-3">
                  <h1 className="mb-2">{t("hello-lets-connect")}</h1>
                  <h2 className="mt-0 mb-5 h4-styled">
                    {t("contact-page-subtitle")}
                  </h2>
                  {getContactDetails()}
                </div>
              </div>
              {getImageDisplay("d-none d-lg-block")}
            </div>
            {getOfficesInfo()}
            <h2>{t("send-us-your-message")}</h2>
          </div>
          <ContactForm />
        </div>
      </main>
    </Layout>
  );

  function getImageDisplay(customClasses) {
    return (
      <div className={`${customClasses} col-lg-6 py-3`}>
        <StaticImage
          className="w-full h-full"
          src="../images/office-hasselt-1.jpg"
          alt=""
        />
      </div>
    );
  }

  function getOfficesInfo() {
    return (
      <div className="row">
        <div className="row">
          <div className="col-12 col-lg-6 py-4">
            <h2 className="mb-0"> {t("office-hasselt")}</h2>
            <div className="color-black">Herkenrodesingel 8B/2.01</div>
            <div className="color-black">3500 Hasselt</div>
            <a
              target="_blank"
              className="d-block"
              href="https://www.google.be/maps/place/ACA+IT-Solutions+Hasselt/@50.9420748,5.3193945,17z/data=!3m1!4b1!4m5!3m4!1s0x47c12199d2036e87:0x86df6707b3167589!8m2!3d50.9420748!4d5.3215832"
              rel="noreferrer noopener"
            >
              {t("get-directions")}
              <i aria-hidden={true} className="ti ti-chevron-right" />
            </a>
            <span tabIndex={0} className="accessibility-visually-hidden ">
              {t("route-description-hasselt")}
            </span>
            <iframe
              aria-hidden={true}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.007476470235!2d5.319394516035599!3d50.942074779546154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c12199d2036e87%3A0x86df6707b3167589!2sACA%20IT-Solutions%20Hasselt!5e0!3m2!1snl!2sbe!4v1629387218680!5m2!1snl!2sbe"
              className="w-full border-0 mt-5"
              height="450"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="col-12 col-lg-6 py-4">
            <h2 className="mb-0"> {t("office-gent")}</h2>
            <div className="color-black">Dublinstraat 31/010</div>
            <div className="color-black">9000 {t("gent")}</div>
            <a
              target="_blank"
              className="d-block"
              href="https://www.google.com/maps/place/ACA+IT-Solutions+Gent/@51.0776689,3.725629,17z/data=!3m1!4b1!4m5!3m4!1s0x47c370d87bff9411:0xac2c08b8d11e6b8d!8m2!3d51.0776769!4d3.7278167"
              rel="noreferrer noopener"
            >
              {t("get-directions")}
              <i aria-hidden={true} className="ti ti-chevron-right" />
            </a>
            <span tabIndex={0} className="accessibility-visually-hidden ">
              {t("route-description-ghent")}
            </span>
            <iframe
              aria-hidden={true}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.6685192360364!2d3.72562901575424!3d51.077668879566794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c370d87bff9411%3A0xac2c08b8d11e6b8d!2sACA%20IT-Solutions%20Gent!5e0!3m2!1snl!2sbe!4v1629385260875!5m2!1snl!2sbe"
              className="w-full border-0 mt-5"
              height="450"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 py-4">
            <h2 className="mb-0"> {t("office-leuven")}</h2>
            <div className="color-black">Diestsepoort 1</div>
            <div className="color-black">3000 {t("leuven")}</div>
            <a
              target="_blank"
              className="d-block"
              href="https://www.google.com/maps/place/Diestsepoort+1,+3000+Leuven/@50.8827386,4.7118603,17z/data=!3m1!4b1!4m5!3m4!1s0x47c160d3176f1873:0xb05e434df3b2f0e3!8m2!3d50.8827386!4d4.714049"
              rel="noreferrer noopener"
            >
              {t("get-directions")}
              <i aria-hidden={true} className="ti ti-chevron-right" />
            </a>
            <span tabIndex={0} className="accessibility-visually-hidden ">
              {t("route-description-leuven")}
            </span>
            <iframe
              aria-hidden={true}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.214588636261!2d4.714048999999999!3d50.8827386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c160d3176f1873%3A0xb05e434df3b2f0e3!2sDiestsepoort%201%2C%203000%20Leuven!5e0!3m2!1sen!2sbe!4v1662643893456!5m2!1sen!2sbe"
              className="w-full border-0 mt-5"
              height="450"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
          <div className="col-12 col-lg-6 py-4">
            <h2 className="mb-0"> {t("office-antwerpen")}</h2>
            <div className="color-black">{t("office-antwerpen-street")}</div>
            <div className="color-black">2018 {t("antwerpen")}</div>
            <a
              target="_blank"
              className="d-block"
              href="https://www.google.com/maps/place/Anneessensstraat+1%2F3,+2018+Antwerpen/@51.2190928,4.418533,17z/data=!3m1!4b1!4m6!3m5!1s0x47c3f701b572c6ab:0xdef4bff7de50f3b2!8m2!3d51.2190928!4d4.418533!16s%2Fg%2F11r129smdf?entry=ttu"
              rel="noreferrer noopener"
            >
              {t("get-directions")}
              <i aria-hidden={true} className="ti ti-chevron-right" />
            </a>
            <span tabIndex={0} className="accessibility-visually-hidden ">
              {t("route-description-antwerpen")}
            </span>
            <iframe
              aria-hidden={true}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.9990694874546!2d4.418533!3d51.2190928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f701b572c6ab%3A0xdef4bff7de50f3b2!2sAnneessensstraat%201%2F3%2C%202018%20Antwerpen!5e0!3m2!1snl!2sbe!4v1688038355986!5m2!1snl!2sbe"
              className="w-full border-0 mt-5"
              height="450"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  function getContactDetails() {
    return (
      <div>
        <p className="font-weight-500 font-size-500 mb-1">
          {t("phone")} {t("phone-hours")}
        </p>
        <a
          href="tel:003211265010"
          className="hover-color-quaternary-500 font-size-500 font-weight-700 mb-3"
        >
          <span className="accessibility-visually-hidden">{t("call")}</span>
          +32(0)11 26.50.10
        </a>
        <p className="font-weight-500 font-size-500 mb-1">
          WhatsApp {t("whatsapp-hours")}
        </p>
        <a
          href="https://wa.me/3211265015"
          target="_blank"
          rel="noreferrer noopener"
          className="hover-color-quaternary-500 font-size-500 font-weight-700 mb-3"
        >
          <span className="accessibility-visually-hidden">{t("call")}</span>
          +32(0)11 26.50.15
        </a>
        <p className="font-weight-500 font-size-500 mb-1">{t("email")}</p>
        <a
          href="mailto:hello@aca-it.be"
          className="hover-color-quaternary-500 font-size-500 font-weight-700 mb-3"
        >
          <span className="accessibility-visually-hidden">{t("email")}</span>
          hello@acagroup.be
        </a>
        <p className="font-weight-500 font-size-500 mb-1">
          {t("find-us-on-social-media")}
        </p>
        <ul className="list-unstyled p-0 m-0 d-flex align-items center justify-content-start">
          <SocialMediaIconLinkComponent
            platform="Twitter"
            iconClasses="ti ti-brand-twitter"
            url="https://twitter.com/acagroup_be"
          />
          <SocialMediaIconLinkComponent
            platform="Instagram"
            iconClasses="ti ti-brand-instagram"
            url="https://www.instagram.com/theacagroup"
          />
          <SocialMediaIconLinkComponent
            platform="Facebook"
            iconClasses="ti ti-brand-facebook"
            url="https://www.facebook.com/ACAGROUP.BE"
          />
          <SocialMediaIconLinkComponent
            platform="LinkedIn"
            iconClasses="ti ti-brand-linkedin"
            url="https://www.linkedin.com/company/the-aca-group"
          />
          <SocialMediaIconLinkComponent
            platform="Youtube"
            iconClasses="ti ti-brand-youtube"
            url="https://www.youtube.com/c/acaitsolutions/featured"
          />
        </ul>
      </div>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsContactPage {
      seoMetaTags(locale: $language) {
        tags
      }
    }
  }
`;
