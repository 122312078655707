import React from "react";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function Blocks4WithIcons({ data }) {
  return (
    <div
      className="py-2 blocks-4-icons"
      {...(data.blockId ? { id: data.blockId } : {})}
    >
      <div className="container">
        <ul className="row list-style-none">
          {getBlockEntry(
            data.block1Icon,
            data.block1IconSize,
            data.block1Title,
            data.block1Link,
            data.openLink1InNewTab,
            data.block1Content
          )}
          {getBlockEntry(
            data.block2Icon,
            data.block2IconSize,
            data.block2Title,
            data.block2Link,
            data.openLink2InNewTab,
            data.block2Content
          )}
          {getBlockEntry(
            data.block3Icon,
            data.block3IconSize,
            data.block3Title,
            data.block3Link,
            data.openLink3InNewTab,
            data.block3Content
          )}
          {getBlockEntry(
            data.block4Icon,
            data.block4IconSize,
            data.block4Title,
            data.block4Link,
            data.openLink4InNewTab,
            data.block4Content
          )}
        </ul>
      </div>
    </div>
  );
}

function getBlockEntry(
  blockIcon,
  blockIconSize,
  blockTitle,
  blockLink,
  blockNewTab,
  blockContent
) {
  return (
    <li className="col-md-6 col-lg-3 py-3" key={blockTitle}>
      <div className="py-4 border-radius-lg h-full">
        {getIcon(blockIcon, blockIconSize)}
        <div className="w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
          {getBlockTitle(blockTitle, blockLink, blockNewTab)}
        </div>
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: blockContent }}
        />
      </div>
    </li>
  );
}

function getBlockTitle(title, link, openInNewTab) {
  if (link) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={link}
        target={openInNewTab ? "_blank" : "_self"}
        rel={openInNewTab && "noreferrer noopener"}
      >
        {title}
      </a>
    );
  }

  return title;
}

function getIcon(iconImageData, iconSizeData) {
  if (iconImageData) {
    return (
      <div className="mb-3">
        {getBlockIconImage(iconImageData, iconSizeData)}
      </div>
    );
  }
}

function getBlockIconImage(blockIcon, blockIconSize) {
  const blockIconClasses = getIconSizeClass(blockIconSize);

  return imageUtil.getImageElements(blockIcon, blockIconClasses);
}

function getIconSizeClass(iconSize) {
  switch (iconSize) {
    case "32px":
      return "w-32";
    case "36px":
      return "w-36";
    case "40px":
      return "w-40";
    case "full width":
      return "w-max-full";
    default:
      return "w-32";
  }
}

export const blocksWithIconsFourFragment = graphql`
  fragment blocksWithIconsFour on DatoCmsBlocksWithIconsFour {
    id
    model {
      apiKey
    }
    greyBackground
    block1Content
    block1Title
    block1Link
    openLink1InNewTab
    block1Icon {
      gatsbyImageData
      format
      url
    }
    block1IconSize
    block2Content
    block2Title
    block2Link
    openLink2InNewTab
    block2Icon {
      gatsbyImageData
      format
      url
    }
    block2IconSize
    block3Content
    block3Title
    block3Link
    openLink3InNewTab
    block3Icon {
      gatsbyImageData
      format
      url
    }
    block3IconSize
    block4Content
    block4Title
    block4Link
    openLink4InNewTab
    block4Icon {
      gatsbyImageData
      format
      url
    }
    block4IconSize
    blockId
  }
`;
