import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import ShareOnSocialMediaButtonComponent from "./share-on-social-media-button-component";

export default function ShareOnSocialMediaComponent({ url }) {
  const { t } = useTranslation();
  const [copiedUrl, setCopiedUrl] = useState(false);

  return (
    <div className="container">
      <h3>{t("share-on-social-media-title")}</h3>
      <p>{t("share-on-social-media-subtitle")}</p>
      <div className="d-flex share-on-social-media-container">
        <div className="d-flex justify-content-center align-items-center">
          <ShareOnSocialMediaButtonComponent
            network={"linkedin"}
            url={url}
            text={t("share-on-social-media-text")}
            ariaLabel={t("share-on-linkedin")}
          >
            <i aria-hidden={true} className="ti ti-brand-linkedin" />
          </ShareOnSocialMediaButtonComponent>
          <ShareOnSocialMediaButtonComponent
            network={"facebook"}
            url={url}
            text={t("share-on-social-media-text")}
            ariaLabel={t("share-on-facebook")}
          >
            <i aria-hidden={true} className="ti ti-brand-facebook" />
          </ShareOnSocialMediaButtonComponent>
          <ShareOnSocialMediaButtonComponent
            network={"twitter"}
            url={url}
            text={t("share-on-social-media-text")}
            ariaLabel={t("share-on-twitter")}
          >
            <i aria-hidden={true} className="ti ti-brand-twitter" />
          </ShareOnSocialMediaButtonComponent>
          <a
            href={`whatsapp://send?text=${t(
              "share-on-social-media-text"
            )} ${url}`}
            data-action="share/whatsapp/share"
            aria-label={t("share-on-whatsapp")}
            title={t("share-on-whatsapp")}
          >
            <i aria-hidden={true} className="ti ti-brand-whatsapp" />
          </a>
          <button
            title={t("copy-url")}
            aria-label={t("copy-url")}
            onClick={() => copyToClipBoard()}
          >
            <i aria-hidden={true} className="ti ti-link" />
          </button>
        </div>
        {copiedUrl ? (
          <div className="url-message-container">
            <span>{t("copied-url")}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );

  function copyToClipBoard() {
    setCopiedUrl(true);
    navigator.clipboard.writeText(url);
  }
}
