import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import HeaderSearchComponent from "./header-search-component";
import { navigate, Script } from "gatsby";
import MenuItemDesktop from "./menu-item-desktop-component";
import MenuItemMobile from "./menu-item-mobile-component";

export default function HeaderComponent({ pageSlugs, showHeader = true }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const whatWeOfferRef = useRef(null);
  const aboutAcaRef = useRef(null);
  const workAtAcaRef = useRef(null);
  const acaInsightsRef = useRef(null);
  const whatWeOfferMobileRef = useRef(null);
  const aboutAcaMobileRef = useRef(null);
  const workAtAcaMobileRef = useRef(null);
  const acaInsightsMobileRef = useRef(null);
  const chooseLanguageRef = useRef(null);

  const [mobileMenuOpenedState, setMobileMenuOpenedState] = useState(false);
  const [activeMenuItemState, setActiveMenuItemState] = useState("");

  const toggleMenuItem = (event, menuItem) => {
    event.stopPropagation();
    if (activeMenuItemState !== menuItem) {
      setActiveMenuItemState(menuItem);
    } else {
      setActiveMenuItemState("");
    }
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        activeMenuItemState === "whatWeOffer" &&
        whatWeOfferRef.current !== null &&
        !whatWeOfferRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }

      if (
        activeMenuItemState === "aboutAca" &&
        aboutAcaRef.current !== null &&
        !aboutAcaRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }

      if (
        activeMenuItemState === "workAtAca" &&
        workAtAcaRef.current !== null &&
        !workAtAcaRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }
      if (
        activeMenuItemState === "acaInsights" &&
        acaInsightsRef.current !== null &&
        !acaInsightsRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }

      if (
        activeMenuItemState === "whatWeOfferMobile" &&
        whatWeOfferMobileRef.current !== null &&
        !whatWeOfferMobileRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }

      if (
        activeMenuItemState === "aboutAcaMobile" &&
        aboutAcaMobileRef.current !== null &&
        !aboutAcaMobileRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }

      if (
        activeMenuItemState === "workAtAcaMobile" &&
        workAtAcaMobileRef.current !== null &&
        !workAtAcaMobileRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }
      if (
        activeMenuItemState === "acaInsightsMobile" &&
        acaInsightsMobileRef.current !== null &&
        !acaInsightsMobileRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }

      if (
        activeMenuItemState === "chooseLanguage" &&
        chooseLanguageRef.current !== null &&
        !chooseLanguageRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }
    };

    if (activeMenuItemState !== "") {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [activeMenuItemState]);

  function changeLanguage(lang) {
    if (language === lang) return;

    let urlParts = window.location.pathname.split("/").filter((part) => part);
    let prefix;

    if (pageSlugs) {
      prefix = translatePrefix(urlParts[urlParts.length - 2]);
      if (lang === "en") {
        navigate(`/${lang}/${prefix}/${pageSlugs.en}${window.location.search}`);
      } else {
        navigate(`/${lang}/${prefix}/${pageSlugs.nl}${window.location.search}`);
      }
    } else {
      prefix = translatePrefix(urlParts[urlParts.length - 1]);
      if (!prefix || prefix === "en" || prefix === "nl") {
        navigate(`/${lang}`);
      } else {
        navigate(`/${lang}/${prefix}${window.location.search}`);
      }
    }
  }

  function translatePrefix(prefix) {
    switch (prefix) {
      case "stageplaatsen":
        return "internships";
      case "internships":
        return "stageplaatsen";
      case "search":
        return "search";
      case "secure":
        return "secure";
      case "jobs":
        return "careers";
      case "nieuws":
        return "news";
      case "sectoren":
        return "industries";
      case "innovatie":
        return "innovation";
      case "careers":
        return "jobs";
      case "news":
        return "nieuws";
      case "industries":
        return "sectoren";
      case "innovation":
        return "innovatie";
      case "how-we-help":
        return "hoe-wij-helpen";
      case "hoe-wij-helpen":
        return "how-we-help";
      case "legal-information":
        return "juridische-info";
      case "juridische-info":
        return "legal-information";
      case "products":
        return "producten";
      case "producten":
        return "products";
      case "auteur":
        return "author";
      case "author":
        return "auteur";
      default:
        return prefix;
    }
  }

  return (
    <header
      role="banner"
      className={`w-min-320 py-08 d-flex flex-column align-items-center position-fixed w-full bg-white shadow-sm font-size-400 ${
        showHeader ? "" : "d-none"
      }`}
      id="navigation"
    >
      <Script
        key="Hubspot Form"
        charSet="utf-8"
        type="text/javascript"
        src="https://js-eu1.hsforms.net/forms/v2.js"
        id="hubspot-js"
      />
      <div className="header-container container line-height-1 d-flex align-items-center justify-content-between">
        <Link to="/" aria-label={t("go-to-home-page")}>
          <img
            src="/images/ACA-Group.svg"
            alt="Logo ACA Group"
            className="h-32 line-height-1 d-inline-block"
            width="70"
            height="32"
          />
        </Link>
        <nav
          role="navigation"
          className="d-flex align-items-center line-height-normal"
        >
          <div
            className={`dropdown d-none d-xl-block mx-1 ${
              activeMenuItemState === "whatWeOffer" ? "show" : ""
            }`}
            onClick={(event) => toggleMenuItem(event, "whatWeOffer")}
            ref={whatWeOfferRef}
          >
            <button
              name={t("open-what-we-offer-nav")}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
              type="button"
              aria-expanded={activeMenuItemState === "whatWeOffer"}
            >
              <span className="font-weight-500 font-secondary">
                {t("what-we-offer")}
              </span>
              <i aria-hidden={true} className="ti ti-chevron-down ml-1" />
            </button>
            <div className="dropdown-content position-absolute top-100 horizontal-center w-320 w-md-400 bg-white shadow-lg border-radius-lg mt-1">
              <div className="p-3">
                <ul className="list-style-none p-0 m-0">
                  <li>
                    <MenuItemDesktop
                      link="services"
                      title="services"
                      iconClasses="ti ti-book"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link={language === "nl" ? "producten" : "products"}
                      title="products"
                      iconClasses="ti ti-tag"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link={
                        language === "nl" ? "hoe-wij-helpen" : "how-we-help"
                      }
                      title="how-we-can-help-you"
                      iconClasses="ti ti-bulb"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link={language === "nl" ? "sectoren" : "industries"}
                      title="industries"
                      iconClasses="ti ti-building-factory"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link="trending"
                      title="trending"
                      iconClasses="ti ti-chart-arrows-vertical"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="dropdown d-none d-xl-block mx-1">
            <Link
              to={`/${language === "nl" ? "innovatie" : "innovation"}`}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
            >
              <span className="font-weight-500 font-secondary">
                {t("innovation")}
              </span>
            </Link>
          </div>

          <div
            className={`dropdown d-none d-xl-block mx-1 ${
              activeMenuItemState === "aboutAca" ? "show" : ""
            }`}
            onClick={(event) => toggleMenuItem(event, "aboutAca")}
            ref={aboutAcaRef}
          >
            <button
              name={t("open-about-aca-nav")}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
              type="button"
              aria-expanded={activeMenuItemState === "aboutAca"}
            >
              <span className="font-weight-500 font-secondary">
                {t("about-aca")}
              </span>
              <i aria-hidden={true} className="ti ti-chevron-down ml-1" />
            </button>
            <div className="dropdown-content position-absolute top-100 horizontal-center w-320 w-md-400 bg-white shadow-lg border-radius-lg mt-1">
              <div className="p-3">
                <ul className="list-style-none p-0 m-0">
                  <li>
                    <MenuItemDesktop
                      link="aca-as-a-company"
                      title={t("aca-as-a-company")}
                      iconClasses="ti ti-info-square"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link={`page/${
                        language === "nl" ? "methodologie" : "methodology"
                      }`}
                      title={t("our-methodology")}
                      iconClasses="ti ti-arrow-iteration"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link="partners"
                      title="partners"
                      iconClasses="ti ti-users"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`dropdown d-none d-xl-block mx-1 ${
              activeMenuItemState === "workAtAca" ? "show" : ""
            }`}
            onClick={(event) => toggleMenuItem(event, "workAtAca")}
            ref={workAtAcaRef}
          >
            <button
              name={t("open-work-at-aca-nav")}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
              type="button"
              aria-expanded={activeMenuItemState === "workAtAca"}
            >
              <span className="font-weight-500 font-secondary">
                {t("work-at-aca")}
              </span>
              <i aria-hidden={true} className="ti ti-chevron-down ml-1" />
            </button>
            <div className="dropdown-content position-absolute top-100 horizontal-center w-320 w-md-400 bg-white shadow-lg border-radius-lg mt-1">
              <div className="p-3">
                <ul className="list-style-none p-0 m-0">
                  <li>
                    <MenuItemDesktop
                      link={language === "nl" ? "jobs" : "careers"}
                      title="careers-header-title"
                      iconClasses="ti ti-stairs"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link={language === "nl" ? "stageplaatsen" : "internships"}
                      title={t("internships")}
                      iconClasses="ti ti-certificate"
                    />
                  </li>
                  <li>{academyMenuItemDesktop()}</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`dropdown d-none d-xl-block mx-1 ${
              activeMenuItemState === "acaInsights" ? "show" : ""
            }`}
            onClick={(event) => toggleMenuItem(event, "acaInsights")}
            ref={acaInsightsRef}
          >
            <button
              name={t("open-aca-insights-nav")}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
              type="button"
              aria-expanded={activeMenuItemState === "acaInsights"}
            >
              <span className="font-weight-500 font-secondary">
                {t("aca-insights")}
              </span>
              <i aria-hidden={true} className="ti ti-chevron-down ml-1" />
            </button>
            <div className="dropdown-content position-absolute top-100 horizontal-center w-320 w-md-400 bg-white shadow-lg border-radius-lg mt-1">
              <div className="p-3">
                <ul className="list-style-none p-0 m-0">
                  <li>
                    <MenuItemDesktop
                      link="blog"
                      title="blog"
                      iconClasses="ti ti-writing"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link="customer-success-stories"
                      title="customer-success-stories"
                      iconClasses="ti ti-trophy"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link={language === "nl" ? "nieuws" : "news"}
                      title="news"
                      iconClasses="ti ti-news"
                    />
                  </li>
                  <li>
                    <MenuItemDesktop
                      link="events"
                      title="events"
                      iconClasses="ti ti-calendar-event"
                    />
                  </li>
                  <MenuItemDesktop
                    link="podcast"
                    title="podcast"
                    iconClasses="ti ti-speakerphone"
                  />
                  <li>
                    <MenuItemDesktop
                      link="downloads"
                      title="downloads"
                      iconClasses="ti ti-download"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <HeaderSearchComponent />

          <Link
            to="/contact"
            className="d-none d-xl-flex mx-2 font-weight-500 font-secondary btn bg-secondary-500 hover-bg-secondary-600 focus-bg-primary-700 color-white border-radius-pill"
          >
            {t("contact")}
          </Link>

          <div
            className={`d-none d-xl-flex dropdown ${
              activeMenuItemState === "chooseLanguage" ? "show" : ""
            }`}
            onClick={(event) => toggleMenuItem(event, "chooseLanguage")}
            ref={chooseLanguageRef}
          >
            <button
              name={t("open-language-switcher-menu")}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
              type="button"
              aria-expanded={activeMenuItemState === "chooseLanguage"}
            >
              <span className="font-weight-500 font-secondary">{language}</span>
              <i aria-hidden={true} className="ti ti-chevron-down ml-1" />
            </button>
            <div className="dropdown-content position-absolute top-100 right-0 w-320 bg-white shadow-lg border-radius-lg mt-1">
              <div className="font-size-300 p-4 border-radius">
                <div aria-hidden={true} className="mb-3">
                  <strong>{t("language")}</strong>
                </div>
                <div className="d-flex">
                  <button
                    aria-current={language === "en"}
                    onClick={() => changeLanguage("en")}
                    className={`px-2 py-2 transition text-center
                      ${
                        language === "en"
                          ? "bg-primary-500 color-white"
                          : "bg-white hover-bg-silver-100 border border-silver cursor-pointer"
                      } border-radius-left w-full focus-within-primary `}
                  >
                    <strong>{t("english")}</strong>
                  </button>
                  <button
                    aria-current={language === "nl"}
                    onClick={() => changeLanguage("nl")}
                    className={`px-2 py-2 transition text-center ${
                      language === "nl"
                        ? "bg-primary-500 color-white"
                        : "bg-white hover-bg-silver-100 border border-silver cursor-pointer"
                    } border-radius-right w-full focus-within-primary`}
                  >
                    <strong>{t("dutch")}</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setMobileMenuOpenedState(!mobileMenuOpenedState)}
            className="d-flex d-xl-none color-black mobile-header"
            aria-label={
              mobileMenuOpenedState
                ? t("close-navigation-menu")
                : t("open-navigation-menu")
            }
          >
            <i
              className={`ti ${mobileMenuOpenedState ? "ti-x" : "ti-menu-2"}`}
            />
          </button>
          <div
            className={`mobile-menu shadow-lg ${
              mobileMenuOpenedState ? "show" : ""
            }`}
          >
            <div className="container">
              <HeaderSearchComponent isMobile={true} />
              <div
                className={`dropdown ${
                  activeMenuItemState === "whatWeOfferMobile" ? "show" : ""
                }`}
                onClick={(event) => toggleMenuItem(event, "whatWeOfferMobile")}
                ref={whatWeOfferMobileRef}
              >
                <button
                  name={t("open-what-we-offer-nav")}
                  className="py-2 d-flex w-full w-full"
                  type="button"
                  aria-expanded={activeMenuItemState === "whatWeOfferMobile"}
                >
                  <span className="font-weight-500 font-secondary  w-full d-flex w-full d-flex">
                    {t("what-we-offer")}
                  </span>
                  <i
                    aria-hidden={true}
                    className={`ti ml-1 color-gray-300 ${
                      activeMenuItemState === "whatWeOfferMobile"
                        ? "ti-chevron-up"
                        : "ti-chevron-down"
                    }`}
                  />
                </button>
                <div className="dropdown-content">
                  <ul className="list-style-none p-0 m-0">
                    <li>
                      <MenuItemMobile
                        link="services"
                        title="services"
                        iconClasses="ti ti-book"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link={language === "nl" ? "producten" : "products"}
                        title="products"
                        iconClasses="ti ti-tag"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link={
                          language === "nl" ? "hoe-wij-helpen" : "how-we-help"
                        }
                        title="how-we-can-help-you"
                        iconClasses="ti ti-bulb"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link={language === "nl" ? "sectoren" : "industries"}
                        title="industries"
                        iconClasses="ti ti-building-factory"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link="trending"
                        title="trending"
                        iconClasses="ti ti-chart-arrows-vertical"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdown">
                <Link
                  to={`/${language === "nl" ? "innovatie" : "innovation"}`}
                  className="my-2 color-black hover-color-quaternary-500 focus-color-quaternary-500"
                >
                  <span className="font-weight-500 font-secondary">
                    {t("innovation")}
                  </span>
                </Link>
              </div>
              <div
                className={`dropdown ${
                  activeMenuItemState === "aboutAcaMobile" ? "show" : ""
                }`}
                onClick={(event) => toggleMenuItem(event, "aboutAcaMobile")}
                ref={aboutAcaMobileRef}
              >
                <button
                  name={t("open-about-aca-nav")}
                  className="py-2 d-flex w-full"
                  type="button"
                  aria-expanded={activeMenuItemState === "aboutAcaMobile"}
                >
                  <span className="font-weight-500 font-secondary  w-full d-flex">
                    {t("about-aca")}
                  </span>
                  <i
                    aria-hidden={true}
                    className={`ti ml-1 color-gray-300 ${
                      activeMenuItemState === "aboutAcaMobile"
                        ? "ti-chevron-up"
                        : "ti-chevron-down"
                    }`}
                  />
                </button>
                <div className="dropdown-content">
                  <ul className="list-style-none p-0 m-0">
                    <li>
                      <MenuItemMobile
                        link="aca-as-a-company"
                        title={t("aca-as-a-company")}
                        iconClasses="ti ti-info-square"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link={`page/${
                          language === "nl" ? "methodologie" : "methodology"
                        }`}
                        title={t("our-methodology")}
                        iconClasses="ti ti-arrow-iteration"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link="partners"
                        title="partners"
                        iconClasses="ti ti-users"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`dropdown ${
                  activeMenuItemState === "workAtAcaMobile" ? "show" : ""
                }`}
                onClick={(event) => toggleMenuItem(event, "workAtAcaMobile")}
                ref={workAtAcaMobileRef}
              >
                <button
                  name={t("open-work-at-aca-nav")}
                  className="py-2 d-flex w-full"
                  type="button"
                  aria-expanded={activeMenuItemState === "workAtAcaMobile"}
                >
                  <span className="font-weight-500 font-secondary  w-full d-flex">
                    {t("work-at-aca")}
                  </span>
                  <i
                    aria-hidden={true}
                    className={`ti ml-1 color-gray-300 ${
                      activeMenuItemState === "workAtAcaMobile"
                        ? "ti-chevron-up"
                        : "ti-chevron-down"
                    }`}
                  />
                </button>
                <div className="dropdown-content">
                  <ul className="list-style-none p-0 m-0">
                    <li>
                      <MenuItemMobile
                        link={language === "nl" ? "jobs" : "careers"}
                        title="careers-header-title"
                        iconClasses="ti ti-stairs"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link={
                          language === "nl" ? "stageplaatsen" : "internships"
                        }
                        title={t("internships")}
                        iconClasses="ti ti-certificate"
                      />
                    </li>
                    <li>{academyMenuItemMobile()}</li>
                  </ul>
                </div>
              </div>
              <div
                className={`dropdown ${
                  activeMenuItemState === "acaInsightsMobile" ? "show" : ""
                }`}
                onClick={(event) => toggleMenuItem(event, "acaInsightsMobile")}
                ref={acaInsightsMobileRef}
              >
                <button
                  name={t("open-aca-insights-nav")}
                  className="py-2 d-flex w-full"
                  type="button"
                  aria-expanded={activeMenuItemState === "acaInsightsMobile"}
                >
                  <span className="font-weight-500 font-secondary  w-full d-flex">
                    {t("aca-insights")}
                  </span>
                  <i
                    aria-hidden={true}
                    className={`ti ml-1 color-gray-300 ${
                      activeMenuItemState === "acaInsightsMobile"
                        ? "ti-chevron-up"
                        : "ti-chevron-down"
                    }`}
                  />
                </button>
                <div className="dropdown-content">
                  <ul className="list-style-none p-0 m-0">
                    <li>
                      <MenuItemMobile
                        link="blog"
                        title="blog"
                        iconClasses="ti ti-writing"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link="customer-success-stories"
                        title="customer-success-stories"
                        iconClasses="ti ti-trophy"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link={language === "nl" ? "nieuws" : "news"}
                        title="news"
                        iconClasses="ti ti-news"
                      />
                    </li>
                    <li>
                      <MenuItemMobile
                        link="events"
                        title="events"
                        iconClasses="ti ti-calendar-event"
                      />
                    </li>
                    <MenuItemMobile
                      link="podcast"
                      title="podcast"
                      iconClasses="ti ti-speakerphone"
                    />
                    <li>
                      <MenuItemMobile
                        link="downloads"
                        title="downloads"
                        iconClasses="ti ti-download"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdown">
                <div className="font-size-300 border-radius my-3">
                  <div className="mb-3 color-gray-300">
                    <strong>{t("language")}</strong>
                  </div>
                  <div className="d-flex">
                    <button
                      aria-current={language === "en"}
                      onClick={() => changeLanguage("en")}
                      className={`px-2 py-2 transition bg-gray-100 border-radius-left w-full ${
                        language === "en"
                          ? "bg-gray-100"
                          : "bg-white border cursor-pointer"
                      }`}
                    >
                      <strong>{t("english")}</strong>
                    </button>
                    <button
                      aria-current={language === "nl"}
                      onClick={() => changeLanguage("nl")}
                      className={`px-2 py-2 transition bg-gray-100 border-radius-right w-full ${
                        language === "nl"
                          ? "bg-gray-100"
                          : "bg-white border cursor-pointer"
                      }`}
                    >
                      <strong>{t("dutch")}</strong>
                    </button>
                  </div>
                </div>
              </div>
              <Link
                to="/contact"
                className="my-3 font-weight-500 font-secondary w-full btn bg-secondary-500 hover-bg-secondary-600 focus-bg-primary-700 color-white border-radius-pill"
              >
                {t("contact")}
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div id="mainContent"></div>
    </header>
  );

  function academyMenuItemMobile() {
    return (
      <Link
        to={`/academy`}
        className="d-flex hover-bg-silver-100 hover-color-quaternary-500 border-radius focus-within-primary line-height-normal my-3 align-items-center"
        hrefLang={language}
      >
        <StaticImage
          aria-hidden={true}
          src="../../images/academy.png"
          alt=""
          className="mr-3"
          width={28}
          height={24}
        />
        <span className="d-flex flex-column overflow-hidden">
          <span className="color-black font-size-400 font-weight-600">
            {t("academy")}
          </span>
        </span>
      </Link>
    );
  }

  function academyMenuItemDesktop() {
    return (
      <Link
        to={`/academy`}
        className="d-flex hover-bg-silver-100 hover-color-quaternary-500 border-radius focus-within-primary line-height-normal align-items-center p-3"
      >
        <StaticImage
          aria-hidden={true}
          src="../../images/academy.png"
          alt=""
          className="mr-3"
          width={28}
          height={24}
        />
        <span className="d-flex flex-column overflow-hidden">
          <span className="color-black font-size-400 font-weight-600">
            {t("academy")}
          </span>
        </span>
      </Link>
    );
  }
}
