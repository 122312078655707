import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const LoadMoreContext = React.createContext({});

export default function LoadMoreButtonComponent() {
  const { t } = useTranslation();
  const loadMoreContext = useContext(LoadMoreContext);

  if (
    !loadMoreContext.amountOfEntriesShown ||
    !loadMoreContext.length ||
    loadMoreContext.amountOfEntriesShown >= loadMoreContext.length
  ) {
    return "";
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <button
        data-testid="showMoreButton"
        onClick={() => {
          loadMoreContext.setPreviousAmountOfEntriesShown(
            loadMoreContext.amountOfEntriesShown
          );
          loadMoreContext.setAmountOfEntriesShown(
            loadMoreContext.amountOfEntriesShown +
              (loadMoreContext.renderAmount || 15)
          );
        }}
        className="btn font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
      >
        {t("show-more")}
      </button>
    </div>
  );
}
