import React, { useContext } from "react";
import DefaultHitComponent from "./default-hit-component";
import DownloadHitComponent from "./download-hit-component";
import PodCastHitComponent from "./podcast-hit-component";
import PageHitComponent from "./page-hit-component";
import { SearchNavigationContext } from "../../pages/search";

export default function SearchResultComponent({ hit, fromEmptyResult }) {
  const searchNavigationContext = useContext(SearchNavigationContext);

  switch (hit.apiKey) {
    case "download":
      return <DownloadHitComponent hit={hit} />;
    case "podcast":
      return (
        <PodCastHitComponent hit={hit} fromEmptyResult={fromEmptyResult} />
      );
    case "page":
      return <PageHitComponent hit={hit} />;
    default:
      return (
        <DefaultHitComponent
          hit={hit}
          searchNavigationHandler={
            searchNavigationContext.searchNavigationHandler
          }
        />
      );
  }
}
