import React from "react";
import { graphql } from "gatsby";
import VideoComponent from "../components/video/video-component";

const imageUtil = require("../utils/image-util.js");

export default function TitleBlock({ data }) {
  return data.heroImage ? (
    <section role="region" className="hero hero--fit">
      <div className="picture d-flex">
        {data.heroBannerImage && data.mediaType !== "video"
          ? imageUtil.getImageElements(data.heroBannerImage, "", true)
          : ""}
      </div>
      <div className="hero__content color-white title-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 py-1">
              <div className={getContentClasses(data)}>
                <div
                  className="rich-text mb-3"
                  data-testid="headTitle"
                  dangerouslySetInnerHTML={{ __html: data.headTitle }}
                />
                {getSubtitle(data)}
                {getDescription(data)}
              </div>
              {getButtonsDiv(data)}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div
      className="py-2 title-block"
      {...(data.blockId ? { id: data.blockId } : {})}
    >
      <div className="container">
        <div className="row align-items-center">
          {data.showImageMobile
            ? getMediaDisplay(data, data.showImageMobile, "d-lg-none")
            : ""}
          {data.alignMediaLeft
            ? getMediaDisplay(data, true, "d-none d-lg-block")
            : ""}
          <div className="col-lg-6 py-1">
            <div className={getContentClasses(data)}>
              <div
                className="rich-text mb-3"
                data-testid="headTitle"
                dangerouslySetInnerHTML={{ __html: data.headTitle }}
              />
              {getSubtitle(data)}
              {getDescription(data)}
            </div>
            {getButtonsDiv(data)}
          </div>
          {!data.alignMediaLeft
            ? getMediaDisplay(data, true, "d-none d-lg-block")
            : ""}
        </div>
      </div>
    </div>
  );
}

function getContentClasses(data) {
  if (data.textBlack) {
    return "my-3 color-black";
  }

  return "my-3";
}

function getMediaDisplay(data, visible, customClasses) {
  let classes = "col-lg-6 py-3 " + customClasses;

  if (visible) {
    switch (data.mediaType) {
      case "image":
        if (data.imageLinkUrl) {
          return (
            <div className={classes} data-testid="image">
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={data.imageLinkUrl}
                target={data.openImageLinkInNewTab ? "_blank" : "_self"}
                rel={data.openImageLinkInNewTab && "noreferrer noopener"}
              >
                <div className="w-full">
                  {imageUtil.getImageElements(data.image)}
                </div>
              </a>
            </div>
          );
        }

        return (
          <div className={classes} data-testid="image">
            <div className="w-full">
              {imageUtil.getImageElements(data.image)}
            </div>
          </div>
        );
      case "video":
        return (
          <div className={classes} data-testid="image">
            <div className="w-full">
              <VideoComponent
                url={data.image.video.mp4Url}
                thumbnailUrl={data.image.video.thumbnailUrl}
                alt={data.image.alt}
              />
            </div>
          </div>
        );
    }
  }
}

function getSubtitle(data) {
  if (data.subTitle) {
    return (
      <div
        className="rich-text mb-3 h2-styled"
        dangerouslySetInnerHTML={{ __html: data.subTitle }}
      />
    );
  }
}

function getDescription(data) {
  if (data.description) {
    return (
      <div
        className="rich-text"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    );
  }
}

function getButtonsDiv(data) {
  if (
    (data.callToActionButtonUrl && data.callToActionButtonText) ||
    (data.linkUrl && data.linkText)
  ) {
    return (
      <div className="my-3" data-testid="buttonsDiv">
        {getCTAButton(data)}
        {getOptionalLink(data)}
      </div>
    );
  }
}

function getCTAButton(data) {
  if (data.callToActionButtonUrl && data.callToActionButtonText) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        data-testid="callToActionButton"
        className="btn color-white hover-color-white bg-primary-500 hover-bg-primary-700 transition border-radius-pill mr-3"
        href={data.callToActionButtonUrl}
        target={data.callToActionButtonOpenInNewTab ? "_blank" : "_self"}
        rel={data.callToActionButtonOpenInNewTab && "noreferrer noopener"}
      >
        {data.callToActionButtonText}
      </a>
    );
  }
}

function getOptionalLink(data) {
  if (data.linkUrl && data.linkText) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        data-testid="link"
        className="align-items-center"
        href={data.linkUrl}
        target={data.linkOpenInNewTab ? "_blank" : "_self"}
        rel={data.linkOpenInNewTab && "noreferrer noopener"}
      >
        {data.linkText}
        <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
      </a>
    );
  }
}

export const titleBlockFragment = graphql`
  fragment titleBlock on DatoCmsTitleBlock {
    id
    model {
      apiKey
    }
    greyBackground
    headTitle
    subTitle
    heroImage
    description
    showImageMobile
    linkUrl
    linkText
    linkOpenInNewTab
    heroBannerImage: image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "3.8", crop: "focalpoint" }
      )
      format
      url
      alt
    }
    image {
      gatsbyImageData
      url
      format
      alt
      video {
        mp4Url
        thumbnailUrl
      }
    }
    openImageLinkInNewTab
    imageLinkUrl
    mediaType
    blockId
    callToActionButtonOpenInNewTab
    callToActionButtonText
    callToActionButtonUrl
    alignMediaLeft
    textBlack
  }
`;
