import { graphql, navigate as doNav } from "gatsby";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import JobFormLayout from "../components/layout/job-form-layout";
import { renderToString } from "react-dom/server";
import SEO from "../seo/seo";

let escape = require("escape-html");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsJobAlertPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function JobAlertPage({ data, location }) {
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();
  const [noCategoriesSelected, setNoCategoriesSelected] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  let selectedJobTypeCategories = location?.state?.selectedJobTypeCategories
    ? location.state.selectedJobTypeCategories
    : [];
  let selectedJobPreferenceCategories = location?.state
    ?.selectedJobPreferenceCategories
    ? location.state.selectedJobPreferenceCategories
    : [];
  let selectedJobProficiencyCategories = location?.state
    ?.selectedJobProficiencyCategories
    ? location.state.selectedJobProficiencyCategories
    : [];

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    selectedJobTypeCategories.forEach((categoryName) => {
      document.getElementById(categoryName).checked = checkIfPreSelected(
        categoryName,
        "type"
      );
    });
    selectedJobPreferenceCategories.forEach((categoryName) => {
      document.getElementById(categoryName).checked = checkIfPreSelected(
        categoryName,
        "preference"
      );
    });
    selectedJobProficiencyCategories.forEach((categoryName) => {
      document.getElementById(categoryName).checked = checkIfPreSelected(
        categoryName,
        "proficiency"
      );
    });
  }, []);

  return (
    <JobFormLayout navigationState={location?.state}>
      <main role="main" className="pt-6">
        <div className="py-6">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Link
                  to={`/${language === "nl" ? "jobs" : "careers"}`}
                  className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
                >
                  <i aria-hidden={true} className="ti ti-chevron-left mr-2" />{" "}
                  {t("back-to-overview")}
                </Link>
                <h1 className="my-4">{t("job-alert-title")}</h1>
                <p>{t("choose-a-job-type-category-proficiency")}</p>
              </div>
            </div>
            <form role="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-4">
                  <div className="mb-4">
                    <h2 className="mt-0 mb-3">{t("type")}</h2>
                    {data.jobTypeCategories.nodes.map((category) => {
                      return getCategoryEntry(category.categoryName, "type");
                    })}
                    <h2 className="my-3">{t("category")}</h2>
                    {data.jobPreferenceCategories.nodes.map((category) => {
                      return getCategoryEntry(
                        category.categoryName,
                        "preference"
                      );
                    })}
                    <h2 className="my-3">{t("proficiency")}</h2>
                    {data.jobProficiencyCategories.nodes.map((category) => {
                      return getCategoryEntry(
                        category.categoryName,
                        "proficiency"
                      );
                    })}
                  </div>
                  <div className="form-group">
                    <label className="d-flex flex-column mb-5" htmlFor="email">
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("email-address")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="email"
                          aria-required="true"
                          aria-invalid={formState.errors.email}
                          className={getTextInputClasses(
                            formState.errors.email
                          )}
                          type="email"
                          id="email"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {formState.errors.email
                          ? getErrorMessage(
                              t("please-fill-in-a-valid-email-address")
                            )
                          : ""}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex flex-column mb-5">
                    <label
                      className="form-check-label font-size-600"
                      htmlFor="privacyPolicy"
                    >
                      <div className="form-check d-flex align-items-center">
                        <input
                          aria-required="true"
                          aria-invalid={formState.errors.privacyPolicy}
                          className="form-check-input mr-2"
                          type="checkbox"
                          value=""
                          id="privacyPolicy"
                          {...register("privacyPolicy", { required: true })}
                        />
                        {getPrivacyPolicyLabel()}
                      </div>
                      <div className="position-relative my-4 my-md-0">
                        {formState.errors.privacyPolicy
                          ? getErrorMessage(
                              t(
                                "please-accept-the-privacy-policy-before-continuing"
                              )
                            )
                          : ""}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="position-relative">
                  {submittingForm
                    ? getFormStatusMessage(
                        t("please-wait-we-are-processing-your-jobalert")
                      )
                    : ""}
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submittingForm}
                    className="col-8 col-md-4 col-lg-2 btn px-4 font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
                  >
                    {t("set-up-job-alert")}
                  </button>
                </div>
                <div className="position-relative my-4">
                  {noCategoriesSelected
                    ? getErrorMessage(t("whoops-no-job-types-selected"))
                    : ""}
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </JobFormLayout>
  );

  async function onSubmit(applicationFormData) {
    if (
      selectedJobTypeCategories.length === 0 &&
      selectedJobPreferenceCategories.length === 0 &&
      selectedJobProficiencyCategories.length === 0
    ) {
      setNoCategoriesSelected(true);
      return;
    }
    setSubmittingForm(true);

    var formdata = new FormData();
    formdata.set("title", t("someone-set-a-jobalert"));
    formdata.set(
      "content",
      renderToString(getEmailTemplate(applicationFormData))
    );

    const response = await window
      .fetch(`/api/job-alert-email`, {
        method: `POST`,
        body: formdata,
      })
      .then((res) => res.json());

    doNav(
      location.state?.from
        ? `/${language}${location.state.from}`
        : `/${language}/${language === "nl" ? "jobs" : "careers"}`,
      {
        state: {
          response: response.successful ? "ok" : "error",
          type: "jobalert",
        },
      }
    );
  }

  function getEmailTemplate(applicationFormData) {
    return (
      <div>
        <h1>{escape(applicationFormData.email)}</h1>
        <h2 className="mt-0 mb-3">{t("type")}</h2>
        <ul>
          {data.jobTypeCategories.nodes
            .filter((category) =>
              selectedJobTypeCategories.includes(category.categoryName)
            )
            .map((category) => {
              return (
                <li key={escape(category.categoryName)}>
                  {escape(category.categoryName)}
                </li>
              );
            })}
        </ul>
        <h2 className="mt-0 mb-3">{t("category")}</h2>
        <ul>
          {data.jobPreferenceCategories.nodes
            .filter((category) =>
              selectedJobPreferenceCategories.includes(category.categoryName)
            )
            .map((category) => {
              return (
                <li key={escape(category.categoryName)}>
                  {escape(category.categoryName)}
                </li>
              );
            })}
        </ul>
        <h2 className="mt-0 mb-3">{t("proficiency")}</h2>
        <ul>
          {data.jobProficiencyCategories.nodes
            .filter((category) =>
              selectedJobProficiencyCategories.includes(category.categoryName)
            )
            .map((category) => {
              return (
                <li key={escape(category.categoryName)}>
                  {escape(category.categoryName)}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  function checkIfPreSelected(categoryName, type) {
    switch (type) {
      case "type":
        return selectedJobTypeCategories.includes(categoryName);
      case "preference":
        return selectedJobPreferenceCategories.includes(categoryName);
      case "proficiency":
        return selectedJobProficiencyCategories.includes(categoryName);
    }
  }

  function getCategoryEntry(categoryName, type) {
    return (
      <div className="form-check d-flex align-items-center">
        <label>
          <input
            name={type}
            className="form-check-input mr-2"
            type="checkbox"
            value=""
            id={categoryName}
            {...register(categoryName, {
              onChange: (e) => {
                const target = e.target;
                const isChecked = target.checked;

                setNoCategoriesSelected(false);
                switch (type) {
                  case "type":
                    isChecked
                      ? selectedJobTypeCategories.push(categoryName)
                      : selectedJobTypeCategories.splice(
                          selectedJobTypeCategories.indexOf(categoryName),
                          1
                        );
                    break;
                  case "preference":
                    isChecked
                      ? selectedJobPreferenceCategories.push(categoryName)
                      : selectedJobPreferenceCategories.splice(
                          selectedJobPreferenceCategories.indexOf(categoryName),
                          1
                        );
                    break;
                  case "proficiency":
                    isChecked
                      ? selectedJobProficiencyCategories.push(categoryName)
                      : selectedJobProficiencyCategories.splice(
                          selectedJobProficiencyCategories.indexOf(
                            categoryName
                          ),
                          1
                        );
                    break;
                }
              },
            })}
          />
          {categoryName}
        </label>
      </div>
    );
  }

  function getTextInputClasses(hasError) {
    let classes =
      "input h-44 px-3 pb-1 w-full bg-white border-radius-pill border-2 font-size-600 ";

    if (hasError) {
      classes +=
        "hover-border-tertiary-500 focus-border-tertiary-500 border border-tertiary-500";
    }

    return classes;
  }

  function getErrorMessage(error) {
    if (error) {
      return (
        <span className="error-message font-weight-600 font-size-600 color-tertiary-500 w-full">
          {error}
        </span>
      );
    }
  }

  function getFormStatusMessage(message) {
    return (
      <span className="font-weight-600 font-size-600 w-full">{message}</span>
    );
  }

  function getPrivacyPolicyLabel() {
    if (language.toLowerCase() === "en") {
      return (
        <div>
          {t("privacy-policy-checkbox-label")}{" "}
          <a href="/en/privacy-policy">{t("privacy-policy").toLowerCase()}</a>.
        </div>
      );
    }

    return (
      <div>
        {t("i-have-the")}{" "}
        <a href="/nl/privacy-policy" aria-label={t("privacy-policy")}>
          {t("privacy-policy").toLowerCase()}
        </a>{" "}
        {t("read-and-understood").toLowerCase()}.
      </div>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsJobAlertPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
    }
    jobTypeCategories: allDatoCmsJobTypeCategory(
      locale: $language
      sort: { categoryName: ASC }
    ) {
      nodes {
        categoryName
      }
    }
    jobPreferenceCategories: allDatoCmsJobPreferenceCategory(
      locale: $language
      sort: { categoryName: ASC }
    ) {
      nodes {
        categoryName
      }
    }
    jobProficiencyCategories: allDatoCmsJobProficiencyCategory(
      locale: $language
      sort: { categoryName: ASC }
    ) {
      nodes {
        categoryName
      }
    }
  }
`;
