import React from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useModelSlugPrefix } from "../hooks/link-util.js";

const imageUtil = require("../utils/image-util.js");

export default function LinkToService({ data }) {
  const { t } = useTranslation();

  return (
    <div
      className="py-2 blocks-3-icons"
      {...(data.blockId ? { id: data.blockId } : {})}
    >
      <div className="container">
        <ul className="row list-style-none">
          {getBlockEntry(
            data.showNewServicesProducts,
            data.item1,
            data.iconImage1,
            data.iconSize1,
            data.title1,
            data.description1
          )}
          {getBlockEntry(
            data.showNewServicesProducts,
            data.item2,
            data.iconImage2,
            data.iconSize2,
            data.title2,
            data.description2
          )}
          {getBlockEntry(
            data.showNewServicesProducts,
            data.item3,
            data.iconImage3,
            data.iconSize3,
            data.title3,
            data.description3
          )}
        </ul>
      </div>
    </div>
  );

  function getBlockEntry(
    displayNewTags,
    items,
    icon,
    iconSize,
    title,
    description
  ) {
    if (items.length > 0) {
      return (
        <li className="col-md-6 col-lg-4 py-3" key={title}>
          <div className="py-4 border-radius-lg h-full">
            {getIcon(icon, iconSize)}
            <h3 className="font-size-700 line-height-sm font-weight-700 mb-3">
              {title}
            </h3>
            <div
              className="rich-text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <ul className="px-0 list-style-none">
              {items.map((item) => (
                <li key={item.id}>
                  <Link
                    to={useModelSlugPrefix(item.model?.apiKey) + item.pageSlug}
                    className="d-flex"
                  >
                    {item.shortTitle}
                    {getNewTag(displayNewTags, item.markedAsNew)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </li>
      );
    }
  }

  function getNewTag(displayNewTags, itemMarkedAsNew) {
    if (displayNewTags && itemMarkedAsNew) {
      return (
        <React.Fragment>
          <span className="accessibility-visually-hidden">{t("new")}</span>
          <i
            aria-hidden={true}
            className="new-label ml-2 p-02 font-size-100 font-weight-800 border border-primary-500 hover-border-quaternary-500"
          />
        </React.Fragment>
      );
    }
  }

  function getIcon(iconImageData, iconSizeDate) {
    if (iconImageData) {
      return (
        <div className="mb-3">
          {getBlockIconImage(iconImageData, iconSizeDate)}
        </div>
      );
    }
  }

  function getBlockIconImage(blockIcon, blockIconSize) {
    const blockIconClasses = getIconSizeClass(blockIconSize);

    return imageUtil.getImageElements(blockIcon, blockIconClasses);
  }

  function getIconSizeClass(iconSize) {
    switch (iconSize) {
      case "32px":
        return "w-32";
      case "36px":
        return "w-36";
      case "40px":
        return "w-40";
      case "full width":
        return "w-max-full";
      default:
        return "w-32";
    }
  }
}

export const linkToServiceFragment = graphql`
  fragment linkToService on DatoCmsLinkToService {
    id
    showNewServicesProducts
    greyBackground
    item1 {
      ...serviceLink
      ...sectorLink
      ...productLink
    }
    iconImage1 {
      format
      gatsbyImageData
      url
    }
    iconSize1
    title1
    description1
    item2 {
      ...serviceLink
      ...sectorLink
      ...productLink
    }
    iconImage2 {
      format
      gatsbyImageData
      url
    }
    iconSize2
    title2
    description2
    item3 {
      ...serviceLink
      ...sectorLink
      ...productLink
    }
    iconImage3 {
      format
      gatsbyImageData
      url
    }
    iconSize3
    title3
    description3
    model {
      apiKey
    }
    blockId
  }
`;
