import { graphql, navigate } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import JobFormLayout from "../components/layout/job-form-layout";
import { renderToString } from "react-dom/server";
import MailSocialmediaFooter from "../components/mail/mail-socialmedia-footer";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsJobReferralFormPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function JobReferralFormPage({ data, location }) {
  const language = React.useContext(I18nextContext).language;
  const { t } = useTranslation();
  const [submittingForm, setSubmittingForm] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  async function onSubmit(applicationFormData) {
    await setSubmittingForm(true);

    var formdata = new FormData();
    formdata.append("firstName", applicationFormData.firstName);
    formdata.append("lastName", applicationFormData.lastName);
    formdata.append(
      "candidateFirstNameLastName",
      applicationFormData.candidateFirstNameLastName
    );
    formdata.append("candidateRole", applicationFormData.candidateRole);
    formdata.append("emailAddress", applicationFormData.emailAddress);
    formdata.append(
      "candidateEmailAddress",
      applicationFormData.candidateEmailAddress
    );
    formdata.append(
      "candidateLinkedInProfileUrl",
      applicationFormData.candidateLinkedInProfileUrl
        ? applicationFormData.candidateLinkedInProfileUrl
        : "Er is geen LinkedIn profiel meegedeeld."
    );
    formdata.append(
      "extraInfo",
      applicationFormData.extraInfo
        ? applicationFormData.extraInfo
        : "Er is geen extra info meegedeeld."
    );

    const response = await window
      .fetch(`/api/job-referral-form`, {
        method: `POST`,
        body: formdata,
      })
      .then((res) => res.json());

    if (response === "ok") {
      sendConfirmationEmail(applicationFormData);
    }

    await navigate(`/${language}/${language === "nl" ? "jobs" : "careers"}`, {
      state: {
        response: response,
        type: "referral",
      },
    });
  }

  async function sendConfirmationEmail(userData) {
    var formData = new FormData();
    formData.append("userEmail", userData.emailAddress);
    formData.append("title", t("referral-title-mail"));
    formData.append(
      "content",
      renderToString(getContentTemplate(userData.firstName))
    );

    await window
      .fetch(`/api/sollicitation-confirmation-mail`, {
        method: `POST`,
        body: formData,
      })
      .then((res) => res.json());
  }

  function getContentTemplate(firstName) {
    return (
      <div>
        <div>
          <p>
            {t("greeting-application-mail").replace("{firstName}", firstName)}
          </p>
          <p>{t("referral-content-mail-1")}</p>
          <p>{t("referral-content-mail-2")}</p>
          <p>{t("best-wishes")}</p>
          <p>{t("the-aca-hiring-team")}</p>
        </div>
        <MailSocialmediaFooter
          twitter_icon={data.twitter_icon}
          instagram_icon={data.instagram_icon}
          facebook_icon={data.facebook_icon}
          linkedin_icon={data.linkedin_icon}
          youtube_icon={data.youtube_icon}
        />
      </div>
    );
  }

  function getPrivacyPolicyLabel() {
    if (language.toLowerCase() === "en") {
      return (
        <div>
          {t("privacy-policy-checkbox-label")}{" "}
          <a href="/en/privacy-policy" aria-label={t("privacy-policy")}>
            {t("privacy-policy").toLowerCase()}
          </a>
          .
        </div>
      );
    }

    return (
      <div>
        {t("i-have-the")}{" "}
        <a href="/nl/privacy-policy" aria-label={t("privacy-policy")}>
          {t("privacy-policy").toLowerCase()}
        </a>{" "}
        {t("read-and-understood").toLowerCase()}.
      </div>
    );
  }

  return (
    <JobFormLayout navigationState={location?.state}>
      <main role="main" className="pt-6">
        <div className="py-6">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Link
                  to={`/${language === "nl" ? "jobs" : "careers"}`}
                  className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
                >
                  <i aria-hidden={true} className="ti ti-chevron-left mr-2" />{" "}
                  {t("back-to-overview")}
                </Link>
                <h1 className="my-4">{t("your-referral")}</h1>
              </div>
            </div>
            <form role="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-4">
                  <h2 className="mt-0 mb-3">{t("personal-details")}</h2>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="firstName"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("first-name")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="given-name"
                          aria-required="true"
                          aria-invalid={formState.errors.firstName}
                          className={getTextInputClasses(
                            formState.errors.firstName
                          )}
                          type="text"
                          id="firstName"
                          {...register("firstName", { required: true })}
                        />
                        {formState.errors.firstName
                          ? getErrorMessage(t("please-fill-in-your-first-name"))
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="lastName"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("last-name")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="family-name"
                          aria-required="true"
                          aria-invalid={formState.errors.lastName}
                          className={getTextInputClasses(
                            formState.errors.lastName
                          )}
                          type="text"
                          id="lastName"
                          {...register("lastName", { required: true })}
                        />
                        {formState.errors.lastName
                          ? getErrorMessage(t("please-fill-in-your-last-name"))
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="candidateFirstNameLastName"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("first-and-last-name-of-your-candidate")}
                      </span>
                      <div className="position-relative">
                        <input
                          aria-required="true"
                          aria-invalid={
                            formState.errors.candidateFirstNameLastName
                          }
                          className={getTextInputClasses(
                            formState.errors.candidateFirstNameLastName
                          )}
                          type="text"
                          id="candidateFirstNameLastName"
                          {...register("candidateFirstNameLastName", {
                            required: true,
                          })}
                        />
                        {formState.errors.candidateFirstNameLastName
                          ? getErrorMessage(
                              t(
                                "please-fill-in-your-candidate-first-and-last-name"
                              )
                            )
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="candidateRole"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("role-your-candidate-is-applying-for")}
                      </span>
                      <div className="position-relative">
                        <input
                          aria-required="true"
                          aria-invalid={formState.errors.candidateRole}
                          className={getTextInputClasses(
                            formState.errors.candidateRole
                          )}
                          type="text"
                          id="candidateRole"
                          {...register("candidateRole", { required: true })}
                        />
                        {formState.errors.candidateRole
                          ? getErrorMessage(
                              t(
                                "please-fill-in-the-role-your-candidate-is-applying-for"
                              )
                            )
                          : ""}
                      </div>
                    </label>
                  </div>

                  <h2 className="mt-4 mb-3">{t("contact-details")}</h2>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="emailAddress"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("your-email-address")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="email"
                          aria-required="true"
                          aria-invalid={formState.errors.emailAddress}
                          className={getTextInputClasses(
                            formState.errors.emailAddress
                          )}
                          type="email"
                          id="emailAddress"
                          {...register("emailAddress", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {formState.errors.emailAddress
                          ? getErrorMessage(
                              t("please-fill-in-a-valid-email-address")
                            )
                          : ""}
                      </div>
                    </label>
                  </div>
                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="candidateEmailAddress"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("email-address-of-your-candidate")}
                      </span>
                      <div className="position-relative">
                        <input
                          aria-required="true"
                          aria-invalid={formState.errors.candidateEmailAddress}
                          className={getTextInputClasses(
                            formState.errors.candidateEmailAddress
                          )}
                          type="email"
                          id="candidateEmailAddress"
                          {...register("candidateEmailAddress", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {formState.errors.candidateEmailAddress
                          ? getErrorMessage(
                              t("please-fill-in-a-valid-email-address")
                            )
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="candidateLinkedInProfileUrl"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {`${t(
                          "url-of-the-linkedin-profile-of-your-candidate"
                        )} (${t("optional").toLowerCase()})`}
                      </span>
                      <div className="position-relative">
                        <input
                          aria-invalid={
                            formState.errors.candidateLinkedInProfileUrl
                          }
                          className={getTextInputClasses(
                            formState.errors.candidateLinkedInProfileUrl
                          )}
                          type="text"
                          id="candidateLinkedInProfileUrl"
                          {...register("candidateLinkedInProfileUrl")}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-6">
                    <div className="form-group">
                      <label
                        className="d-flex flex-column my-2"
                        htmlFor="extraInfo"
                      >
                        <span className="mb-2 font-weight-600 font-size-600">
                          {`${t("extra-info-you-would-like-to-tell-us")} (${t(
                            "optional"
                          ).toLowerCase()})`}
                        </span>
                        <div className="position-relative">
                          <textarea
                            id="extraInfo"
                            rows="10"
                            {...register("extraInfo")}
                            className="form-select input p-3 w-full bg-white border-radius-xxl hover-border-primary-700 focus-border-primary-700 border border-sand-800 font-size-600"
                          />
                        </div>
                      </label>
                    </div>
                    <div className="form-group">
                      <div className="d-flex flex-column mb-5">
                        <label
                          className="form-check-label font-size-600"
                          htmlFor="privacyPolicy"
                        >
                          <div className="form-check d-flex align-items-center">
                            <input
                              aria-required="true"
                              aria-invalid={formState.errors.privacyPolicy}
                              className="form-check-input mr-2"
                              type="checkbox"
                              value=""
                              id="privacyPolicy"
                              {...register("privacyPolicy", { required: true })}
                            />
                            {getPrivacyPolicyLabel()}
                          </div>
                          <div className="position-relative my-4 my-md-0">
                            {formState.errors.privacyPolicy
                              ? getErrorMessage(
                                  t(
                                    "please-accept-the-privacy-policy-before-continuing"
                                  )
                                )
                              : ""}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="position-relative">
                      {submittingForm
                        ? getFormStatusMessage(
                            t("please-wait-we-are-processing-your-referral")
                          )
                        : ""}
                    </div>
                    <button
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      disabled={submittingForm}
                      className="btn px-4 font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
                    >
                      {t("send-referral")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </JobFormLayout>
  );
}

function getFormStatusMessage(message) {
  return (
    <span className="font-weight-600 font-size-600 w-full">{message}</span>
  );
}

function getErrorMessage(error) {
  if (error) {
    return (
      <span className="error-message font-weight-600 font-size-600 color-tertiary-500 w-full">
        {error}
      </span>
    );
  }
}

function getTextInputClasses(hasError) {
  let classes =
    "input h-44 px-3 pb-1 w-full bg-white border-radius-pill border-2 font-size-600 ";

  if (hasError) {
    classes +=
      "hover-border-tertiary-500 focus-border-tertiary-500 border border-tertiary-500";
  }

  return classes;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsJobReferralFormPage {
      seoMetaTags(locale: $language) {
        tags
      }
    }
    twitter_icon: datoCmsAsset(filename: { eq: "twitter.png" }) {
      url
    }
    instagram_icon: datoCmsAsset(filename: { eq: "instagram.png" }) {
      url
    }
    facebook_icon: datoCmsAsset(filename: { eq: "facebook.png" }) {
      url
    }
    linkedin_icon: datoCmsAsset(filename: { eq: "linkedin.png" }) {
      url
    }
    youtube_icon: datoCmsAsset(filename: { eq: "youtube.png" }) {
      url
    }
  }
`;
