import React from "react";

export default function MailSocialmediaFooter({
  twitter_icon,
  instagram_icon,
  facebook_icon,
  linkedin_icon,
  youtube_icon,
}) {
  return (
    <div style={{ display: "flex", marginTop: "24px" }}>
      <a
        href="https://twitter.com/acagroup_be"
        target="_blank"
        rel="noreferrer noopener"
        style={{ marginRight: "10px" }}
      >
        <img src={twitter_icon.url} alt="" style={{ width: "24px" }} />
      </a>
      <a
        href="https://www.instagram.com/theacagroup"
        target="_blank"
        rel="noreferrer noopener"
        style={{ marginRight: "10px" }}
      >
        <img src={instagram_icon.url} alt="" style={{ width: "24px" }} />
      </a>
      <a
        href="https://www.facebook.com/ACAGROUP.BE"
        target="_blank"
        rel="noreferrer noopener"
        style={{ marginRight: "10px" }}
      >
        <img src={facebook_icon.url} alt="" style={{ width: "24px" }} />
      </a>
      <a
        href="https://www.linkedin.com/company/the-aca-group"
        target="_blank"
        rel="noreferrer noopener"
        style={{ marginRight: "10px" }}
      >
        <img src={linkedin_icon.url} alt="" style={{ width: "24px" }} />
      </a>
      <a
        href="https://www.youtube.com/c/acaitsolutions/featured"
        target="_blank"
        rel="noreferrer noopener"
        style={{ marginRight: "10px" }}
      >
        <img src={youtube_icon.url} alt="" style={{ width: "24px" }} />
      </a>
    </div>
  );
}
