import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

export default function PageHitComponent({ hit }) {
  const { t } = useTranslation();
  const url = getUrl();

  return (
    <div className="event-item" tabIndex="0">
      <div className="container">
        <div className="row">
          <div className="col-12 pb-4">
            <div className="font-size-700 line-height-sm font-weight-700 pb-4 border-bottom">
              <div className="d-flex flex-column flex-md-row">
                <div className="flex-grow-1">
                  <Link
                    to={
                      pageNeedsBackQuery()
                        ? `${url.toLowerCase()}${window.location.search}`
                        : `${url.toLowerCase()}`
                    }
                    state={{
                      previous: window.location.pathname
                        .replace(/\/$/, "")
                        .substring(
                          window.location.pathname
                            .replace(/\/$/, "")
                            .lastIndexOf("/")
                        ),
                    }}
                    className="my-1"
                  >
                    <div className="rich-text">{hit.title}</div>
                  </Link>
                  <div className="my-1 color-tertiary-500 font-weight-500 font-size-400">
                    {t(`search-result-type-${hit.apiKey}`)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function pageNeedsBackQuery() {
    return !(hit.title === "Jobs" || hit.title === "Careers");
  }

  function getUrl() {
    if (hit.objectID.toString().includes("/author/")) {
      return "/author/" + hit.title.replaceAll(" ", "-");
    }
    if (hit.objectID.toString().includes("/auteur/")) {
      return "/auteur/" + hit.title.replaceAll(" ", "-");
    }
    if (hit.title === "Homepage" || hit.title === "Hoofdpagina") {
      return "/";
    }
    return "/" + hit.title.replaceAll(" ", "-");
  }
}
