import React, { useEffect, useMemo, useState } from "react";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import {
  createURL,
  searchStateToUrl,
  urlToSearchState,
} from "../../utils/search-util";

export const AlgoliaSearchContext = React.createContext({});

export default function AlgoliaSearchContextComponent({
  children,
  replicaName,
}) {
  const isBrowser = typeof window !== "undefined";
  const indexName =
    process.env.GATSBY_ALGOLIA_INDEX_NAME +
    (replicaName ? `__${replicaName}` : "");
  const DEBOUNCE_TIME = 400;
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  const [searchState, setSearchState] = useState(
    urlToSearchState(isBrowser ? window.location : {})
  );
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  const [podcastEntryCollapseId, setPodcastEntryCollapseId] = useState("");

  const playButtonClickHandler = (id) => {
    if (podcastEntryCollapseId === id) {
      setPodcastEntryCollapseId("");
      return;
    }
    setPodcastEntryCollapseId(id);
  };

  const onSearchStateChange = (updatedSearchState) => {
    setPodcastEntryCollapseId("");
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        if (isBrowser) {
          window.history.pushState(
            "",
            "",
            searchStateToUrl(window, updatedSearchState)
          );
        }
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  useEffect(() => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("smooth");

    window.addEventListener("popstate", function () {
      if (window.location.href && window.location.href.includes("#")) {
        root.classList.add("smooth");
      }
    });
  }, []);

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      stalledSearchDelay={300}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <AlgoliaSearchContext.Provider
        value={{
          podcastEntryCollapseId,
          setPodcastEntryCollapseId,
          playButtonClickHandler,
        }}
      >
        {children}
      </AlgoliaSearchContext.Provider>
    </InstantSearch>
  );
}
