import * as React from "react";
import SearchResultComponent from "./search/search-result-component";

export default function EmptyResultInfiniteHits({ hits }) {
  return (
    <div>
      {hits.map((hit) => (
        <SearchResultComponent
          hit={hit}
          fromEmptyResult={true}
          key={hit.objectID}
        />
      ))}
    </div>
  );
}
