import React from "react";
import { graphql } from "gatsby";
import { getImageElements } from "../utils/image-util";

export default function TileBlock({ data }) {
  const imageData = data.image;
  imageData.alt = "";

  return (
    <div className="col-12 col-sm-6 col-md-6 col-xl-4 my-2 my-md-3 my-sm-2 my-xl-4">
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={data.href}
        aria-label={data.alt}
        target={data.newTab ? "_blank" : "_self"}
        rel={data.newTab && "noreferrer noopener"}
        className="w-full border border-2 border-transparent hover-border-quaternary-500"
      >
        {getImageElements(imageData, "w-full d-block")}
      </a>
    </div>
  );
}

export const tileFragment = graphql`
  fragment tile on DatoCmsTile {
    id
    model {
      apiKey
    }
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "1", crop: "focalpoint" }
      )
      url
      format
    }
    href
    alt
    newTab
  }
`;
