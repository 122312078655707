import * as React from "react";
import { useContext } from "react";
import { graphql, Link } from "gatsby";
import WorkAtAcaLayout from "../components/layout/work-at-aca-layout";
import InternshipsTitleBlock from "../components/internships/internships-title-component";
import InternshipsMotivationBlock from "../components/internships/internships-motivation-component";
import InternshipsQuotes from "../components/internships/internships-quotes-component";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import ContactPersonBlock from "../building-blocks/contact-person-block";
import VisualSummaryBlock from "../building-blocks/visual-summary-block";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsInternshipsPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function InternshipsPage({ data }) {
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();

  data.datoCmsInternshipsPage.title = `<h3 class="h2-styled">${t(
    "first-aid-for"
  )}</h3>`;

  return (
    <WorkAtAcaLayout>
      <main role="main" className="pt-6">
        <InternshipsTitleBlock
          email={data.datoCmsInternshipsPage.person.email}
        />
        <InternshipsMotivationBlock />
        <VisualSummaryBlock data={getArguments()} />
        <InternshipsQuotes
          blogSlug={data.datoCmsInternshipsPage.blog.pageSlug}
        />
        <div className="container justify-content-center d-flex mb-5">
          <Link
            className="btn color-white hover-color-white bg-primary-500 hover-bg-primary-700 transition border-radius-pill mr-3"
            to={
              language === "nl"
                ? "/nl/jobs?refinementList%5BjobTypeCategories%5D%5B0%5D=Stage"
                : "/en/careers?refinementList%5BjobTypeCategories%5D%5B0%5D=Internship"
            }
          >
            {t("view-internships-positions")}
          </Link>
        </div>
        <div className="container">
          <h2>{t("questions")}?</h2>
          <p className="text-center font-size-800 font-size-sm-500 font-size-md-600 pt-4 mb-0">
            {t("do-you-have-questions")}
            {data.datoCmsInternshipsPage.person.name}
            {t("do-you-have-questions2")}
          </p>
          <ContactPersonBlock data={data.datoCmsInternshipsPage} />
        </div>
      </main>
    </WorkAtAcaLayout>
  );

  function getArguments() {
    return {
      arguments: [
        createArgument(1, t("the-right-tools"), t("you-will-get-everything")),
        createArgument(2, t("a-personalized-experience"), t("we-listen-to")),
        createArgument(3, t("vast-knowledge"), t("pairing-up")),
        createArgument(4, t("valuable-experience"), t("get-ready-for")),
        createArgument(5, t("a-broader-horizon"), t("a-look-behind")),
      ],
    };
  }

  function createArgument(id, title, text) {
    return {
      id: id,
      title: title,
      text: text,
    };
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsInternshipsPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      blog {
        pageSlug
      }
      person: contactperson {
        ...person
      }
    }
  }
`;
