import React, { useEffect } from "react";
import { graphql } from "gatsby";

export default function HubspotFormBlock({ data }) {
  useEffect(() => {
    waitForElementToDisplay(
      "#hubspot-js",
      function () {
        if (
          !document.getElementById(data.id)?.innerHTML?.includes("hbspt-form")
        ) {
          hbspt.forms.create({
            region: data.region,
            portalId: data.portalId,
            formId: data.formId,
            target: `#${data.id}`,
            onFormSubmit: function () {
              if (data.redirectUrl) {
                window.open(data.redirectUrl, "_blank", "noopener,noreferrer");
              }
            },
          });
        }
      },
      100,
      9000
    );
  }, []);

  return (
    <div className="py-2">
      <div
        id={data.id}
        className="container"
        dangerouslySetInnerHTML={{ __html: data.formScript }}
      />
    </div>
  );

  function waitForElementToDisplay(
    selector,
    callback,
    checkFrequencyInMs,
    timeoutInMs
  ) {
    const startTimeInMs = Date.now();
    (function loopSearch() {
      if (typeof hbspt !== "undefined") {
        callback();
      } else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }
}

export const executableHtmlFragment = graphql`
  fragment hubspotForm on DatoCmsHubspotForm {
    id
    blockId
    greyBackground
    region
    portalId
    formId
    formScript
    redirectUrl
    model {
      apiKey
    }
  }
`;
