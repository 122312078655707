import React from "react";
import { graphql } from "gatsby";

export default function Button1LinkBlock({ data }) {
  return (
    <div className="py-2">
      <div className="container">
        <div className="row">
          <div className={getColumnAlignment(data.columnAlignment)}>
            <div
              className={`d-flex align-items-center ${getAlignment(
                data.alignment
              )} ${data.extraBottomSpacing ? "mb-5" : ""}`}
            >
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={data.ctaLink}
                target={data.newTab ? "_blank" : "_self"}
                rel={data.newTab ? "noreferrer noopener" : ""}
                className={`${getClasses(
                  data
                )} d-flex flex-shrink-1 white-space-wrap`}
              >
                {data.ctaText}
                {getIcon(data)}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getColumnAlignment(columnAlignment) {
  switch (columnAlignment) {
    case "Full":
      return "col-xl-12 offset-xl-0";
    case "Left":
      return "col-xl-6 offset-xl-0";
    case "Right":
      return "col-xl-6 offset-xl-6";
    default:
      return "col-xl-6 offset-xl-3";
  }
}

function getAlignment(alignment) {
  switch (alignment) {
    case "left":
      return "justify-content-start";
    case "center":
      return "justify-content-center";
    case "right":
      return "justify-content-end";
  }
}

function getClasses(data) {
  if (data.ctaType === "button") {
    if (data.buttonType === "primary") {
      return "btn font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill";
    }

    return "btn font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500";
  }
}

function getIcon(data) {
  if (data.ctaType === "button") {
    switch (data.buttonAction) {
      case "scroll down":
        return <i aria-hidden={true} className="ti ti-arrow-down ml-1" />;
      case "external link":
        return <i aria-hidden={true} className="ti ti-external-link ml-1" />;
      case "download":
        return <i aria-hidden={true} className="ti ti-download ml-1" />;
    }
  } else {
    if (data.linkType === "internal") {
      return <i aria-hidden={true} className="ti ti-chevron-right ml-1" />;
    }

    return <i aria-hidden={true} className="ti ti-external-link ml-1" />;
  }
}

export const button1LinkFragment = graphql`
  fragment button1Link on DatoCmsButton1Link {
    id
    greyBackground
    extraBottomSpacing
    ctaType
    ctaLink
    ctaText
    newTab
    columnAlignment
    alignment
    buttonType
    buttonAction
    linkType
    model {
      apiKey
    }
  }
`;
