import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import AudioComponent from "../audio/audio-component";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import { PodcastContext } from "./podcast-context";

const dateUtil = require("../../hooks/date-util");

export default function PodcastEntryComponent({ podcast, shouldFocus }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const { podcastEntryCollapseId, playButtonClickHandler } =
    useContext(PodcastContext);

  let podcastRef = null;

  useEffect(() => {
    if (shouldFocus) {
      podcastRef?.focus();
    }
  }, []);

  return (
    <div
      key={podcast.id}
      className="py-2 event-item"
      ref={(podcast) => {
        podcastRef = podcast;
      }}
    >
      <div className="row">
        <div className="col-12 py-3">
          <div className="font-size-700 line-height-sm font-weight-700 py-3 border-bottom">
            <div className="d-flex">
              <div className="event-item__date mr-4">
                <span>{dateUtil.useDateFormat(podcast.date, "DD")}</span>
                {dateUtil
                  .useDateFormat(podcast.date, "MMM")
                  .toUpperCase()
                  .replace(".", "")}
              </div>
              <div className="podcast-item_title-description-wrapper">
                <Link to={`/${language}/podcast/${podcast.pageSlug}`}>
                  <div className="rich-text">{podcast.title}</div>
                </Link>
                {getPodcastDuration(true)}
                <div className="my-1 color-gray-500 font-weight-300 font-size-500 podcast-item__excerpt">
                  {podcast.excerpt}
                </div>
              </div>
              <div className="d-none d-md-flex flex-grow-1 align-items-center px-3 justify-content-center">
                {getPlayButton()}
              </div>
              {getPodcastDuration(false)}
            </div>
            <div
              className={`align-items-center w-full align-items-md-center justify-content-center mt-3 
                         ${
                           podcastEntryCollapseId === podcast.id
                             ? "d-none"
                             : "d-flex d-md-none"
                         }`}
            >
              {getPlayButton()}
            </div>
            <div
              className={`mt-3 
                         ${
                           podcastEntryCollapseId === podcast.id
                             ? "d-block"
                             : "d-none"
                         }`}
            >
              {podcastEntryCollapseId === podcast.id ? (
                <AudioComponent audioUrl={podcast.mp3.url} autoPlay={true} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function getPlayButton() {
    return (
      <button
        aria-label={`${t("play")} ${podcast.title}`}
        className="audio-player-button"
        type="button"
        onClick={() => playButtonClickHandler(podcast.id)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          focusable="false"
          width="1em"
          height="1em"
          style={{ transform: "rotate(360deg)" }}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
            fill="currentColor"
          />
        </svg>
      </button>
    );
  }

  function getPodcastDuration(mobileView) {
    return (
      <div
        className={`my-1 color-gray-300 align-items-end font-weight-700 font-size-500 white-space-nowrap ${
          mobileView ? "d-flex d-md-none" : "d-none d-md-flex"
        }`}
      >
        {podcast.duration} min
      </div>
    );
  }
}
