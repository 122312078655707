import React from "react";
import { graphql } from "gatsby";

export default function Button2LinkBlock({ data }) {
  return (
    <div className="py-2">
      <div className="container">
        <div className="row">
          <div className={getColumnAlignment(data.columnAlignment)}>
            <div
              className={`d-flex align-items-center ${getAlignment(
                data.alignment
              )} ${data.extraBottomSpacing ? "mb-5" : ""}`}
            >
              <div
                className={`d-flex flex-wrap ${
                  data.stackVertically ? "flex-column" : ""
                }`}
              >
                {getButtonLink(
                  data.ctaType1,
                  data.ctaLink1,
                  data.ctaText1,
                  data.newTab1,
                  data.buttonType1,
                  data.buttonAction1,
                  data.linkType1,
                  false
                )}
                {getButtonLink(
                  data.ctaType2,
                  data.ctaLink2,
                  data.ctaText2,
                  data.newTab2,
                  data.buttonType2,
                  data.buttonAction2,
                  data.linkType2,
                  true
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getColumnAlignment(columnAlignment) {
  switch (columnAlignment) {
    case "Full":
      return "col-xl-12 offset-xl-0";
    case "Left":
      return "col-xl-6 offset-xl-0";
    case "Right":
      return "col-xl-6 offset-xl-6";
    default:
      return "col-xl-6 offset-xl-3";
  }
}

function getAlignment(alignment) {
  switch (alignment) {
    case "left":
      return "justify-content-start";
    case "center":
      return "justify-content-center";
    case "right":
      return "justify-content-end";
  }
}

function getButtonLink(
  type,
  link,
  text,
  newTab,
  buttonType,
  buttonAction,
  linkType,
  addMargin
) {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      target={newTab ? "_blank" : "_self"}
      rel={newTab && "noreferrer noopener"}
      className={`${getClasses(
        type,
        buttonType,
        addMargin
      )} d-flex flex-shrink-1 white-space-wrap`}
    >
      {text}
      {getIcon(type, buttonAction, linkType)}
    </a>
  );
}

function getClasses(type, buttonType, addMargin) {
  let classes = "";

  if (type === "button") {
    if (buttonType === "primary") {
      classes =
        "btn font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill";
    } else {
      classes =
        "btn font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500";
    }
  }

  return addMargin ? classes + " ml-3" : classes;
}

function getIcon(type, buttonAction, linkType) {
  if (type === "button") {
    switch (buttonAction) {
      case "scroll down":
        return <i aria-hidden={true} className="ti ti-arrow-down ml-1" />;
      case "external link":
        return <i aria-hidden={true} className="ti ti-external-link ml-1" />;
      case "download":
        return <i aria-hidden={true} className="ti ti-download ml-1" />;
    }
  } else {
    if (linkType === "internal") {
      return <i aria-hidden={true} className="ti ti-chevron-right ml-1" />;
    }

    return <i aria-hidden={true} className="ti ti-external-link ml-1" />;
  }
}

export const button2LinkFragment = graphql`
  fragment button2Link on DatoCmsButton2Link {
    id
    greyBackground
    extraBottomSpacing
    ctaType1
    ctaLink1
    ctaText1
    newTab1
    buttonType1
    buttonAction1
    linkType1
    ctaType2
    ctaLink2
    ctaText2
    newTab2
    buttonType2
    buttonAction2
    linkType2
    columnAlignment
    alignment
    stackVertically
    model {
      apiKey
    }
  }
`;
