import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import { navigate } from "gatsby";

export default function JobApplicationHeaderComponent({ navigationState }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const chooseLanguageRef = useRef(null);

  const [activeMenuItemState, setActiveMenuItemState] = useState("");

  const toggleMenuItem = (event, menuItem) => {
    event.stopPropagation();
    if (activeMenuItemState !== menuItem) {
      setActiveMenuItemState(menuItem);
    } else {
      setActiveMenuItemState("");
    }
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        activeMenuItemState === "chooseLanguage" &&
        chooseLanguageRef.current !== null &&
        !chooseLanguageRef.current.contains(e.target)
      ) {
        setActiveMenuItemState("");
      }
    };

    if (activeMenuItemState !== "") {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [activeMenuItemState]);

  function changeLanguage(lang) {
    if (language === lang) return;

    let urlParts = window.location.pathname.split("/");
    let prefix = translatePrefix(urlParts[urlParts.length - 1]);

    if (lang === "nl") {
      navigate(`/nl/${prefix}`, {
        state: navigationState,
      });
    } else {
      navigate(`/en/${prefix}`, {
        state: navigationState,
      });
    }
  }

  function translatePrefix(prefix) {
    switch (prefix) {
      case "job-applicatie-formulier":
        return "job-application-form";
      case "job-application-form":
        return "job-applicatie-formulier";
      case "doorverwijzing":
        return "job-referral-form";
      case "job-referral-form":
        return "doorverwijzing";
      default:
        return prefix;
    }
  }

  return (
    <header
      role="banner"
      className="w-min-320 py-08 d-flex flex-column align-items-center position-fixed w-full bg-white shadow-sm"
      id="navigation"
    >
      <div className="container line-height-1 d-flex align-items-center justify-content-between">
        <Link to="/" aria-label={t("home")}>
          <img
            src="/images/ACA-Group.svg"
            alt="Logo ACA Group"
            className="h-32 line-height-1 d-inline-block"
          />
        </Link>
        <nav
          role="navigation"
          className="d-flex align-items-center line-height-normal"
        >
          <div
            className={`dropdown ${
              activeMenuItemState === "chooseLanguage" ? "show" : ""
            }`}
            onClick={(event) => toggleMenuItem(event, "chooseLanguage")}
            ref={chooseLanguageRef}
          >
            <button
              name={t("open-language-switcher-menu")}
              className="btn hover-bg-silver-100 color-black hover-color-quaternary-500 focus-color-quaternary-500 border-radius-pill"
              type="button"
              aria-expanded={activeMenuItemState === "chooseLanguage"}
            >
              <span className="font-weight-500 font-secondary">{language}</span>
              <i aria-hidden={true} className="ti ti-chevron-down ml-1" />
            </button>
            <div className="dropdown-content position-absolute top-100 right-0 w-320 bg-white shadow-lg border-radius-lg mt-1">
              <div className="font-size-300 p-4 border-radius">
                <div aria-hidden={true} className="mb-3">
                  <strong>{t("language")}</strong>
                </div>
                <div className="d-flex">
                  <button
                    aria-current={language === "en"}
                    onClick={() => changeLanguage("en")}
                    className={`px-2 py-2 transition text-center
                      ${
                        language === "en"
                          ? "bg-primary-500 color-white"
                          : "bg-white hover-bg-silver-100 border border-silver"
                      } border-radius-left w-full`}
                  >
                    <strong>{t("english")}</strong>
                  </button>
                  <button
                    aria-current={language === "nl"}
                    onClick={() => changeLanguage("nl")}
                    className={`px-2 py-2 transition text-center ${
                      language === "nl"
                        ? "bg-primary-500 color-white"
                        : "bg-white hover-bg-silver-100 border border-silver"
                    } border-radius-right w-full`}
                  >
                    <strong>{t("dutch")}</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div id="mainContent"></div>
    </header>
  );
}
