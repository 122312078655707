import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Layout from "../components/layout/layout";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsPrivacyPolicyPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function PrivacyPolicyPage({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="container">
          <div className="mt-5 pt-4">
            <h1 className="my-0">{t("privacy-policy")}</h1>
          </div>
        </div>
        <div className="container my-6">
          {getPrivacyPolicyEntry(
            t("privacy-policy"),
            "#privacyPolicy",
            data.datoCmsPrivacyPolicyPage.privacyPolicyPdf.url
          )}
          {getPrivacyPolicyEntry(
            `${t("privacy-policy-for-applicants")} (${t("aca-it-full-name")})`,
            undefined,
            data.datoCmsPrivacyPolicyPage.privacyPolicyAcaItSolutionsNvPdf.url
          )}
          {getPrivacyPolicyEntry(
            `${t("privacy-policy-for-applicants")} (${t(
              "it-match-full-name"
            )})`,
            undefined,
            data.datoCmsPrivacyPolicyPage.privacyPolicyItMatchBvbaPdf.url
          )}
        </div>
        <div className="container">
          <div
            className="pt-4 col-xl-6 offset-xl-3"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsPrivacyPolicyPage.content,
            }}
          />
        </div>
      </main>
    </Layout>
  );
}

function getPrivacyPolicyEntry(title, sectionHref, downloadUrl) {
  const { t } = useTranslation();

  return (
    <div className="row border-bottom py-3">
      <div className="col-md-6 justify-content-center">
        <div className="font-size-600 font-weight-700 d-flex justify-content-center justify-content-md-start">
          {title}
        </div>
      </div>
      <div className="col-md-6 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-end">
        {sectionHref && (
          <a
            href={sectionHref}
            className="btn mt-2 mt-md-0 font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
            onClick={clickPrivacyPolicy}
          >
            {t("jump-to-section")}
            <i aria-hidden={true} className="ti ti-arrow-down ml-1" />
          </a>
        )}

        <a
          href={downloadUrl}
          download
          className="btn mt-2 mt-md-0 font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
        >
          Download PDF <i aria-hidden={true} className="ti ti-download ml-1" />
        </a>
      </div>
    </div>
  );
}

function clickPrivacyPolicy(e) {
  e.preventDefault();

  const headerHeight = document.getElementById("navigation").offsetHeight;
  const id = stripIdFromHrefOfElement(e.target.getAttribute("href"));
  const offset = document.getElementById(id).offsetTop - headerHeight;

  window.scroll({ top: offset, left: 0, behavior: "smooth" });
}

function stripIdFromHrefOfElement(targetElement) {
  return targetElement.substring(1);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsPrivacyPolicyPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      id
      content
      privacyPolicyPdf {
        url
      }
      privacyPolicyAcaItSolutionsNvPdf {
        url
      }
      privacyPolicyItMatchBvbaPdf {
        url
      }
    }
  }
`;
