import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { useModelSlugPrefix } from "../hooks/link-util.js";

const imageUtil = require("../utils/image-util.js");

export default function LinkToModel3Block({ data }) {
  const { t } = useTranslation();

  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <ul className="row list-style-none">
          {data.items.map((item) => getBlockContent(item))}
        </ul>
      </div>
    </div>
  );

  function getBlockContent(item) {
    return (
      <li className="col-md-6 col-lg-4 py-3" key={item.id}>
        <div className="py-4 border-radius-lg w-full h-full">
          {getLinkItem(item)}
          <div className="mb-3">
            <div
              className="rich-text"
              dangerouslySetInnerHTML={{ __html: item.excerpt }}
            />
          </div>
          {getAuthorContent(item.author)}
        </div>
      </li>
    );
  }

  function getLinkItem(item) {
    if (item.model.apiKey === "event" && item.externalEventUrl) {
      return (
        <a
          className="flex-column w-full "
          href={item.externalEventUrl}
          target="_blank"
          rel="noreferrer noopener"
          aria-label={item.headTitle}
        >
          {getLinkContent(item)}
        </a>
      );
    }

    return (
      <Link
        className="flex-column w-full "
        to={useModelSlugPrefix(item.model.apiKey) + item.pageSlug}
        aria-label={item.headTitle}
      >
        {getLinkContent(item)}
      </Link>
    );
  }

  function getLinkContent(item) {
    return (
      <React.Fragment>
        <div className="mb-3 w-full" aria-hidden={true}>
          <div className="picture position-relative justify-content-center">
            {imageUtil.getImageElements(
              ["blog", "news", "case"].includes(item.model.apiKey)
                ? item.coverImage
                : item.image,
              "w-full w-max-full"
            )}
            <div className="picture__cover">
              <i className="ti ti-arrow-narrow-right font-size-900 color-white" />
            </div>
            {getPictureTag(
              item.model.apiKey === "job"
                ? item.jobPreferenceCategories[0].categoryName
                : item.primaryCategory?.categoryName
            )}
          </div>
        </div>
        <div className="w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
          <div
            className="rich-text"
            dangerouslySetInnerHTML={{
              __html:
                item.model.apiKey === "job" ? item.jobName : item.headTitle,
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  function getPictureTag(tag) {
    if (tag) {
      return (
        <span className="picture__tag bg-white color-tertiary-500 font-size-300 font-weight-700 p-1 px-2 m-3">
          {tag}
        </span>
      );
    }
  }

  function getAuthorContent(author) {
    if (author) {
      return (
        <div className="d-flex flex-row flex-wrap">
          {getAuthorImage(author.photo)}
          <Link
            to={`/${t("author").toLowerCase()}/${author.pageSlug}`}
            aria-label={`${t("go-to-author-page")} ${author.fullName}`}
          >
            <div className="flex-grow-1">
              <div className="m-0 font-size-500 font-weight-700">
                {author.fullName}
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  function getAuthorImage(photo) {
    if (photo) {
      return (
        <div className="line-height-1 mr-3">
          {imageUtil.getImageElements(
            photo,
            "m-0 w-36 h-36 overflow-hidden border-radius-circle"
          )}
        </div>
      );
    }

    return (
      <div className="line-height-1 mr-3">
        <StaticImage
          src="../images/placeholder-avatar.jpg"
          alt="Avatar placeholder"
          className="m-0 w-36 h-36 overflow-hidden border-radius-circle"
        />
      </div>
    );
  }
}

export const linkToModel3Fragment = graphql`
  fragment linkToModel3 on DatoCmsLinkToModel3 {
    id
    greyBackground
    items {
      ...blogShort
      ...caseShort
      ...newsShort
      ...eventShort
      ...sectorShort
      ...serviceShort
      ...jobShort
    }
    model {
      apiKey
    }
    blockId
  }
`;
