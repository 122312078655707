import algoliasearch from "algoliasearch/lite";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch-core";
import HeaderComponent from "../header/header-component";
import FooterComponent from "../footer/footer-component";
import { urlToSearchState } from "../../utils/search-util";
import { Link } from "gatsby";

export default function Layout({
  location,
  children,
  pageSlugs,
  showHeader = true,
  showFooter = true,
}) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const isBrowser = typeof window !== "undefined";

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  const [searchState, setSearchState] = useState(
    urlToSearchState(isBrowser ? window.location : {})
  );

  const onSearchStateChange = (updatedSearchState) =>
    setSearchState(updatedSearchState);

  useEffect(() => {
    document.querySelectorAll("#gatsby-announcer").forEach((el) => el.remove());

    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("smooth");

    window.addEventListener("popstate", function () {
      if (window.location.href && window.location.href.includes("#")) {
        root.classList.add("smooth");
      }
    });
  });

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      stalledSearchDelay={300}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
    >
      <Configure filters={`locale:${language}`} />
      <Link
        to="#mainContent"
        className="accessibility-skip-link"
        state={location?.state}
      >
        {t("skip-to-main-content")}
      </Link>
      <a aria-hidden="true" href="#" id="top" className="position-absolute" />
      <div>
        <HeaderComponent pageSlugs={pageSlugs} showHeader={showHeader} />
        {children}
        <FooterComponent location={location} showFooter={showFooter} />
      </div>
    </InstantSearch>
  );
}
