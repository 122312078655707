import useSiteMetadata from "../hooks/site-metadata";

export function getDefaultPageSlugs(pageSlug) {
  return {
    en: pageSlug,
    nl: pageSlug,
  };
}

export function getPageSlugs(data) {
  return {
    en: getPageSlug(data, "en"),
    nl: getPageSlug(data, "nl"),
  };
}

export function getAlternatePageSlugFromUrl(language, pageSlug = "") {
  if (typeof window === "undefined") {
    return "";
  }
  const { siteUrl } = useSiteMetadata();
  const url = `${siteUrl}${location.pathname}`;
  const cleanUrl = url.split("?")[0];
  const slug = cleanUrl.split(`/${language}/`)[1];
  let prefix = "";

  Object.keys(getPrefixMap()).forEach((element) => {
    if (getPrefixMap()[element][language] === slug) {
      prefix =
        language === "nl"
          ? getPrefixMap()[element]["en"]
          : getPrefixMap()[element]["nl"];
    }
  });

  return language === "en"
    ? `${cleanUrl.replace(`/${slug}`, `/${prefix}`)}${pageSlug}`.replace(
        "/en/",
        "/nl/"
      )
    : `${cleanUrl.replace(`/${slug}`, `/${prefix}`)}${pageSlug}`.replace(
        "/nl/",
        "/en/"
      );
}

export function getPageSlugFromApiKeyAndLanguage(apiKey, language) {
  const prefixMap = getPrefixMap();
  return prefixMap[apiKey][language];
}

function getPrefixMap() {
  return {
    blog: {
      nl: "blog",
      en: "blog",
    },
    case: {
      nl: "customer-success-stories",
      en: "customer-success-stories",
    },
    news: {
      nl: "nieuws",
      en: "news",
    },
    event: {
      nl: "events",
      en: "events",
    },
    job: {
      nl: "jobs",
      en: "careers",
    },
    podcast: {
      nl: "podcast",
      en: "podcast",
    },
    product: {
      nl: "products",
      en: "products",
    },
    service: {
      nl: "services",
      en: "services",
    },
    sector: {
      nl: "sectoren",
      en: "industries",
    },
    landing_page: {
      nl: "page",
      en: "page",
    },
    we_help: {
      nl: "hoe-wij-helpen",
      en: "how-we-help",
    },
    trending: {
      nl: "trending",
      en: "trending",
    },
    innovation: {
      nl: "innovatie",
      en: "innovation",
    },
    aca_as_company: {
      nl: "aca-as-a-company",
      en: "aca-as-a-company",
    },
    partners: {
      nl: "partners",
      en: "partners",
    },
    academy: {
      nl: "academy",
      en: "academy",
    },
    downloads: {
      nl: "downloads",
      en: "downloads",
    },
    contact: {
      nl: "contact",
      en: "contact",
    },
  };
}

function getPageSlug(data, language) {
  return data.pageSlugs._allPageSlugLocales.filter(
    (entry) => entry.locale === language
  )[0].value;
}
