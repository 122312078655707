import React from "react";
import { useEffect } from "react";
import Prism from "prismjs";
import { graphql } from "gatsby";

export default function CodeBlock({ data }) {
  useEffect(() => {
    Prism.highlightAll();
  });

  const language = "language-" + data.programmingLanguage;

  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <div className="row">
          <div className={data.onBlogPage ? "" : "col-xl-6 offset-xl-0"}>
            <div className="code-container">
              <pre className="line-numbers">
                <code className={language}>{data.code}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const codeFragment = graphql`
  fragment code on DatoCmsCode {
    id
    code
    greyBackground
    programmingLanguage
    model {
      apiKey
    }
    blockId
  }
`;
