import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const imageUtil = require("../utils/image-util.js");

export default function Blocks2WithIcons({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <ul className="row list-style-none">
          {getBlockEntry(
            data.block1Icon,
            data.block1IconSize,
            data.block1Title,
            data.block1Image,
            data.block1Link,
            data.openLink1InNewTab,
            data.block1Content,
            data.image1LinkUrl,
            data.openImage1LinkInNewTab
          )}
          {getBlockEntry(
            data.block2Icon,
            data.block2IconSize,
            data.block2Title,
            data.block2Image,
            data.block2Link,
            data.openLink2InNewTab,
            data.block2Content,
            data.image2LinkUrl,
            data.openImage2LinkInNewTab
          )}
        </ul>
      </div>
    </div>
  );
}

function getBlockEntry(
  blockIcon,
  blockIconSize,
  blockTitle,
  blockImage,
  blockLink,
  blockOpenNewTab,
  blockContent,
  imageLink,
  openImageLinkInNewTab
) {
  return (
    <li className="col-md-6 col-lg-6 py-3" key={blockTitle}>
      <div className="py-4 border-radius-lg h-full">
        <div className="mb-3">{getIcon(blockIcon, blockIconSize)}</div>
        <div className="w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
          {getBlockTitle(blockTitle, blockLink, blockOpenNewTab)}
        </div>
        {getBlockImage(blockImage, imageLink, openImageLinkInNewTab)}
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: blockContent }}
        />
      </div>
    </li>
  );
}

function getBlockImage(blockImage, imageLink, openImageLinkInNewTab) {
  if (blockImage && imageLink) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={imageLink}
        target={openImageLinkInNewTab ? "_blank" : "_self"}
        rel={openImageLinkInNewTab && "noreferrer noopener"}
      >
        <GatsbyImage image={blockImage.gatsbyImageData} alt="" />
      </a>
    );
  }
  if (blockImage) {
    return <GatsbyImage image={blockImage.gatsbyImageData} alt="" />;
  }
}

function getBlockTitle(title, link, openInNewTab) {
  if (link) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={link}
        target={openInNewTab ? "_blank" : "_self"}
        rel={openInNewTab && "noreferrer noopener"}
      >
        {title}
      </a>
    );
  }

  return title;
}

const getIcon = (iconImageData, iconSizeDate) => {
  if (iconImageData) {
    return (
      <div className="mb-3">
        {getBlockIconImage(iconImageData, iconSizeDate)}
      </div>
    );
  }
};

const getBlockIconImage = (blockIcon, blockIconSize) => {
  const blockIconClasses = getIconSizeClass(blockIconSize);

  return imageUtil.getImageElements(blockIcon, blockIconClasses);
};

const getIconSizeClass = (iconSize) => {
  switch (iconSize) {
    case "32px":
      return "w-32";
    case "36px":
      return "w-36";
    case "40px":
      return "w-40";
    case "full width":
      return "w-max-full";
    default:
      return "w-32";
  }
};

export const blocksWithIconsTwoFragment = graphql`
  fragment blocksWithIconsTwo on DatoCmsBlocksWithIconsTwo {
    id
    greyBackground
    model {
      apiKey
    }
    block1Content
    block1Title
    block1Image {
      gatsbyImageData
      format
      url
    }
    block1Link
    openLink1InNewTab
    block1Icon {
      gatsbyImageData
      format
      url
    }
    block1IconSize
    openImage1LinkInNewTab
    image1LinkUrl
    block2Content
    block2Title
    block2Image {
      gatsbyImageData
      format
      url
    }
    block2Link
    openLink2InNewTab
    block2Icon {
      gatsbyImageData
      format
      url
    }
    block2IconSize
    openImage2LinkInNewTab
    image2LinkUrl
    blockId
  }
`;
