import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import QuoteBlock from "../building-blocks/quote-block";
import Layout from "../components/layout/layout";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsLegalInformationPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function LegalInformationPage({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="mt-5 container">
          <div className="pt-4">
            <h1 className="my-0">{t("legal-information")}</h1>
          </div>
        </div>
        <div className="container my-6">
          {getLegalInformationEntry(
            t("terms-and-conditions"),
            "terms-and-conditions",
            data.datoCmsLegalInformationPage.termsAndConditionsPdf.url
          )}
          {getLegalInformationEntry(
            t("privacy-policy"),
            "privacy-policy",
            data.datoCmsLegalInformationPage.privacyPolicyPdf.url
          )}
          {getLegalInformationEntry(t("cookie-policy"), "cookie-policy")}
        </div>
        <div className="container">
          <p>
            {t("legal-information-paragraph")}
            <a href="mailto:hello@acagroup.be">
              <span className="visibility-hidden">{t("email")}</span>
              hello@acagroup.be.
            </a>
          </p>
        </div>
        <QuoteBlock data={data.datoCmsLegalInformationPage.quote[0]} />
      </main>
    </Layout>
  );

  function getLegalInformationEntry(title, pageSlug, downloadUrl) {
    return (
      <div className="row border-bottom py-3">
        <div className="col-md-6 justify-content-center">
          <div className="font-size-600 font-weight-700 d-flex justify-content-center justify-content-md-start">
            <Link to={`/${pageSlug}`}>{title}</Link>
          </div>
        </div>
        {downloadUrl ? (
          <div className="col-md-6 d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-end">
            <a
              href={downloadUrl}
              download
              className="btn mt-2 mt-md-0 font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
            >
              Download PDF{" "}
              <i aria-hidden={true} className="ti ti-download ml-1" />
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsLegalInformationPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      quote {
        ...quote
      }
      termsAndConditionsPdf {
        url
      }
      privacyPolicyPdf {
        url
      }
    }
  }
`;
