import algoliasearch from "algoliasearch/lite";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Hits } from "react-instantsearch-dom";
import { Configure, InstantSearch } from "react-instantsearch-core";
import { I18nextContext } from "gatsby-plugin-react-i18next";
import SearchResultComponent from "./search-result-component";
import { createFallbackableCache } from "@algolia/cache-common";
import { version } from "@algolia/client-common";
import { createInMemoryCache } from "@algolia/cache-in-memory";
import { createBrowserLocalStorageCache } from "@algolia/cache-browser-local-storage";

export default function DownloadEmptyMessageComponent({ searchResults }) {
  const hasResults = !searchResults || searchResults.nbHits !== 0;
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY,
        {
          hostsCache: createFallbackableCache({
            caches: [
              createBrowserLocalStorageCache({
                key: `${version}-${process.env.GATSBY_ALGOLIA_APP_ID}`,
              }),
              createInMemoryCache(),
            ],
          }),
        }
      ),
    []
  );

  return (
    <div hidden={hasResults}>
      <span className="font-size-700">{t("search-results-empty")}</span>
      <InstantSearch
        searchClient={searchClient}
        indexName={`${process.env.GATSBY_ALGOLIA_INDEX_NAME}__most-recent`}
      >
        <Configure
          filters={`type:Download AND locale:${language}`}
          hitsPerPage="5"
        />
        <h2 className="mt-6">{t("recently-added")}</h2>
        <Hits hitComponent={SearchResultComponent}></Hits>
      </InstantSearch>
    </div>
  );
}
