import React, { useEffect } from "react";
import FooterComponent from "../footer/footer-component";
import JobApplicationHeaderComponent from "../header/job-form-header-component";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function JobFormLayout({ children, navigationState }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelectorAll("#gatsby-announcer").forEach((el) => el.remove());

    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("smooth");

    window.addEventListener("popstate", function () {
      if (window.location.href && window.location.href.includes("#")) {
        root.classList.add("smooth");
      }
    });
  });

  return (
    <div>
      <a href="#mainContent" className="accessibility-skip-link">
        {t("skip-to-main-content")}
      </a>
      <a id="top" href="#" className="position-absolute" aria-hidden="true" />
      <JobApplicationHeaderComponent navigationState={navigationState} />
      {children}
      <FooterComponent workAtAca={true} />
    </div>
  );
}
