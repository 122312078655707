import React from "react";
import TitleBlock from "../building-blocks/title-block";
import Blocks4WithIcons from "../building-blocks/4-blocks-with-icons";
import ImageBlock from "../building-blocks/image-block";
import VisualSummaryBlock from "../building-blocks/visual-summary-block";
import Blocks3WithIcons from "../building-blocks/3-blocks-with-icons";
import Blocks3WithImages from "../building-blocks/3-blocks-with-images";
import ContactPersonBlock from "../building-blocks/contact-person-block";
import QuoteBlock from "../building-blocks/quote-block";
import BlockWithIconImage from "../building-blocks/block-with-icon-image";
import RichTextBlock from "../building-blocks/rich-text-block";
import AuthorBlock from "../building-blocks/author-block";
import Blocks2WithIcons from "../building-blocks/2-blocks-with-icons";
import LogosBlock from "../building-blocks/logos-block";
import SeparatorBlock from "../building-blocks/separator-block";
import CodeBlock from "../building-blocks/code-block";
import Button1LinkBlock from "../building-blocks/1-button-link-block";
import Button2LinkBlock from "../building-blocks/2-button-link-block";
import Blocks2WithIconsBlockWithImage from "../building-blocks/2-blocks-with-icons-and-block-with-image";
import ProcessBlock from "../building-blocks/process-block";
import LinkToModel3Block from "../building-blocks/link-to-model-3-block";
import LinkToModel1Block from "../building-blocks/link-to-model-1-block";
import LinkToService from "../building-blocks/link-to-service";
import ExecutableHtmlBlock from "../building-blocks/executable-html-block";
import HubspotFormBlock from "../building-blocks/hubspot-form-block";
import Blocks3Recent from "../building-blocks/3-recent-blocks";

export function getBlock(block) {
  switch (block?.model?.apiKey) {
    case "title_block":
      return <TitleBlock data={block} key={block.id} />;
    case "blocks_with_icons_four":
      return <Blocks4WithIcons data={block} key={block.id} />;
    case "blocks_with_icons_three":
      return <Blocks3WithIcons data={block} key={block.id} />;
    case "blocks_with_icons_two":
      return <Blocks2WithIcons data={block} key={block.id} />;
    case "blocks_with_images_three":
      return <Blocks3WithImages data={block} key={block.id} />;
    case "image":
      return <ImageBlock data={block} key={block.id} />;
    case "visual_summary":
      return <VisualSummaryBlock data={block} key={block.id} />;
    case "contact_person":
      return <ContactPersonBlock data={block} key={block.id} />;
    case "quote":
      return <QuoteBlock data={block} key={block.id} />;
    case "block_with_icon_and_image":
      return <BlockWithIconImage data={block} key={block.id} />;
    case "rich_text":
      return <RichTextBlock data={block} key={block.id} />;
    case "separator":
      return <SeparatorBlock key={block.id} />;
    case "code":
      return <CodeBlock data={block} key={block.id} />;
    case "button1_link":
      return <Button1LinkBlock data={block} key={block.id} />;
    case "button2_link":
      return <Button2LinkBlock data={block} key={block.id} />;
    case "logos_block":
      return <LogosBlock data={block} key={block.id} />;
    case "author":
      return <AuthorBlock data={block} key={block.id} />;
    case "blocks_with_icons_two_and_image_block":
      return <Blocks2WithIconsBlockWithImage data={block} key={block.id} />;
    case "process_visualization":
      return <ProcessBlock data={block} key={block.id} />;
    case "link_to_model3":
      return <LinkToModel3Block data={block} key={block.id} />;
    case "link_to_model1":
      return <LinkToModel1Block data={block} key={block.id} />;
    case "link_to_service":
      return <LinkToService data={block} key={block.id} />;
    case "executable_html":
      return <ExecutableHtmlBlock data={block} key={block.id} />;
    case "hubspot_form":
      return <HubspotFormBlock data={block} key={block.id} />;
    case "blocks_recent_three":
      return <Blocks3Recent data={block} key={block.id} />;
    default:
      return "";
  }
}

export function getBlogBlock(index, block) {
  switch (block?.model?.apiKey) {
    case "image":
      block.alignment = "Full";
      block.blockAlignment = "Full";
      return <ImageBlock data={block} key={block.id} />;
    case "quote":
      block.blockAlignment = "Full";
      return <QuoteBlock data={block} key={block.id} />;
    case "rich_text":
      block.alignment = "Full";
      return <RichTextBlock data={block} key={block.id} />;
    case "separator":
      return <SeparatorBlock key={block.id} />;
    case "button1_link":
      block.columnAlignment = "Full";
      return <Button1LinkBlock data={block} key={block.id} />;
    case "button2_link":
      block.columnAlignment = "Full";
      return <Button2LinkBlock data={block} key={block.id} />;
    case "code":
      block.onBlogPage = true;
      return <CodeBlock data={block} key={block.id} />;
    case "author":
      block.onBlogPage = true;
      return <AuthorBlock data={block} key={block.id} />;
    case "executable_html":
      return <ExecutableHtmlBlock data={block} key={block.id} />;
    default:
      return "";
  }
}

export function getTitleBlock(block) {
  return <TitleBlock data={block} key={block.id} />;
}

export function getSectionClassesWithoutPaddingTop(blocks, index) {
  let classes = "";

  if (blocks[index]?.greyBackground) {
    classes += "background";

    if (
      !blocks[index - 1] ||
      !blocks[index + 1] ||
      blocks[index + 1]?.greyBackground === false
    ) {
      classes += " last";
    }
  }

  return classes;
}

export function getSectionClassesWithPaddingTop(blocks, index) {
  let classes = "";

  if (blocks[index]?.greyBackground) {
    classes += "background";

    if (
      !blocks[index - 1] ||
      !blocks[index + 1] ||
      blocks[index + 1]?.greyBackground === false
    ) {
      classes += " last";
    }
  } else if (index === 0) {
    classes += "py-6 py-xl-0";
  }

  return classes;
}

export function getFirstBlockIsHeroTitleBlock(contentBlocks) {
  return (
    contentBlocks[0]?.model?.apiKey === "title_block" &&
    contentBlocks[0]?.heroImage &&
    contentBlocks[0]?.heroBannerImage
  );
}
