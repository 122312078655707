import React, { useContext } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useModelSlugPrefix } from "../../hooks/link-util";
import AudioComponent from "../audio/audio-component";
import { AlgoliaSearchContext } from "./algolia-search-context-component";

export default function PodCastHitComponent({ hit, fromEmptyResult }) {
  const { podcastEntryCollapseId, playButtonClickHandler } =
    useContext(AlgoliaSearchContext);
  const { t } = useTranslation();

  return (
    <div className="py-2 event-item" tabIndex="0">
      <div className="container">
        <div className="row">
          <div className="col-12 py-3">
            <div className="font-size-700 line-height-sm font-weight-700 py-3 border-bottom">
              <div className="d-flex">
                <div className="podcast-item_title-description-wrapper">
                  <Link
                    to={`${useModelSlugPrefix(hit.apiKey)}${hit.slug}${
                      window.location.search
                    }`}
                    state={{
                      previous: window.location.pathname
                        .replace(/\/$/, "")
                        .substring(
                          window.location.pathname
                            .replace(/\/$/, "")
                            .lastIndexOf("/")
                        ),
                    }}
                    className="my-1"
                  >
                    <div className="rich-text">{hit.title}</div>
                  </Link>
                  <div
                    className="my-1 color-gray-500 font-weight-300 font-size-500 podcast-item__excerpt"
                    dangerouslySetInnerHTML={{ __html: hit.description }}
                  ></div>
                  <div className="my-1 color-tertiary-500 font-weight-500 font-size-400">
                    {t(`search-result-type-${hit.apiKey}`)}
                  </div>
                </div>
                <div className="d-none d-md-flex flex-grow-1 align-items-center px-3 justify-content-center">
                  <button
                    aria-label={`${t("play")} ${hit.title}`}
                    className="audio-player-button"
                    type="button"
                    onClick={() =>
                      playButtonClickHandler(
                        `${hit.objectID} ${fromEmptyResult}`
                      )
                    }
                  >
                    <svg
                      aria-hidden="true"
                      role="img"
                      focusable="false"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      style={{ transform: "rotate(360deg)" }}
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                className={`align-items-center w-full align-items-md-center justify-content-center mt-3 
                         ${
                           podcastEntryCollapseId ===
                           `${hit.objectID} ${fromEmptyResult}`
                             ? "d-none"
                             : "d-flex d-md-none"
                         }`}
              >
                <button
                  aria-label={`${t("play")} ${hit.title}`}
                  className="audio-player-button"
                  type="button"
                  onClick={() =>
                    playButtonClickHandler(`${hit.objectID} ${fromEmptyResult}`)
                  }
                >
                  <svg
                    aria-hidden="true"
                    role="img"
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    style={{ transform: "rotate(360deg)" }}
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`mt-3 
                         ${
                           podcastEntryCollapseId ===
                           `${hit.objectID} ${fromEmptyResult}`
                             ? "d-block"
                             : "d-none"
                         }`}
              >
                {podcastEntryCollapseId ===
                `${hit.objectID} ${fromEmptyResult}` ? (
                  <AudioComponent autoPlay={true} audioUrl={hit.mp3} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
