import React from "react";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function LogosBlock({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <ul className="list-style-none m-0 p-0 row d-flex justify-content-center logo-block mb-6">
          {data.logos.map((logo) => getLogoDisplay(logo))}
        </ul>
      </div>
    </div>
  );
}

function getLogoDisplay(logo) {
  return (
    <li
      className="col-lg-4 py-3 d-flex align-items-center justify-content-center"
      key={logo.id}
    >
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={logo.linkHref}
        target={logo.openInNewTab ? "_blank" : "_self"}
        rel={logo.openInNewTab && "noreferrer noopener"}
      >
        {imageUtil.getImageElements(logo.image, "w-full d-block")}
      </a>
    </li>
  );
}

export const logosBlockFragment = graphql`
  fragment logosBlock on DatoCmsLogosBlock {
    id
    greyBackground
    logos {
      id
      name
      image {
        gatsbyImageData
        format
        url
        alt
      }
      openInNewTab
      linkHref
    }
    model {
      apiKey
    }
    blockId
  }
`;
