import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { useModelSlugPrefix } from "../hooks/link-util.js";

const imageUtil = require("../utils/image-util.js");

export default function LinkToModel1Block({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <div className="row">
          {getImageDisplay(data.showImageMobile, "d-lg-none")}
          {getImageDisplay(data.imageLeft, "d-none d-lg-block")}
          <div className="col-md-6 py-3">
            <div className="py-4 border-radius-lg h-full">
              <div className="font-size-700 line-height-sm font-weight-700 mb-3">
                <div className="rich-text">
                  <h2>
                    {data.customTitle ? data.customTitle : data.item.headTitle}
                  </h2>
                </div>
              </div>
              {getContent()}
              {getLink()}
            </div>
          </div>
          {getImageDisplay(!data.imageLeft, "d-none d-lg-block")}
        </div>
      </div>
    </div>
  );

  function getContent() {
    if (data.customDescription) {
      return (
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: data.customDescription }}
        />
      );
    }

    return (
      <div
        className="rich-text"
        dangerouslySetInnerHTML={{ __html: data.item.excerpt }}
      />
    );
  }

  function getLink() {
    return (
      <div className="py-4">
        <Link
          to={useModelSlugPrefix(data.item.model?.apiKey) + data.item.pageSlug}
        >
          {data.linkText}
          <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
        </Link>
      </div>
    );
  }

  function getImageDisplay(visible, customClasses) {
    let classes = "col-md-6 py-3 " + customClasses;

    if (visible) {
      if (data.linkUrl) {
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={data.linkUrl}
            target={data.linkOpenInNewTab ? "_blank" : "_self"}
            rel={data.linkOpenInNewTab && "noreferrer noopener"}
          >
            <div className={classes} data-testid="image">
              {imageUtil.getImageElements(
                data.customImage ? data.customImage : data.item.coverImage,
                "w-full d-block"
              )}
            </div>
          </a>
        );
      }

      return (
        <div className={classes} data-testid="image">
          {imageUtil.getImageElements(
            data.customImage ? data.customImage : data.item.coverImage,
            "w-full d-block"
          )}
        </div>
      );
    }
  }
}

export const linkToModel1Fragment = graphql`
  fragment linkToModel1 on DatoCmsLinkToModel1 {
    id
    greyBackground
    item {
      ...blogShort
      ...caseShort
      ...newsShort
    }
    imageLeft
    showImageMobile
    linkText
    customTitle
    customDescription
    model {
      apiKey
    }
    blockId
    customImage {
      format
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "1.8", crop: "focalpoint" }
      )
      url
      alt
    }
    linkOpenInNewTab
    linkUrl
  }
`;
