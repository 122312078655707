import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useModelSlugPrefix } from "../hooks/link-util.js";

const imageUtil = require("../utils/image-util.js");

export default function RelatedBlogsBlock({ data }) {
  const { t } = useTranslation();

  return (
    <div className="py-4">
      <div className="container">
        <div className="row">
          <h2 className="related-blogs__title col-md-12 h3-styled">
            {t("read-more-tips-and-experiences")}
          </h2>
          {data.items.map((item) => getBlockContent(item))}
        </div>
      </div>
    </div>
  );

  function getBlockContent(item) {
    return (
      <div className="col-md-12" key={item.id}>
        <div className="py-3 border-radius-lg h-full">
          <Link
            className="related-blog-link flex-row d-flex align-items-start"
            to={useModelSlugPrefix(item.model?.apiKey) + item.pageSlug}
          >
            <div>
              <div aria-hidden={true} className="picture position-relative">
                {imageUtil.getImageElements(
                  item.coverImage,
                  "w-max-full related-blogs-image"
                )}
                <div className="picture__cover">
                  <i className="ti ti-arrow-narrow-right font-size-900 color-white" />
                </div>
              </div>
              <div className="font-size-500 line-height-sm font-weight-700 mt-2">
                <div
                  className="rich-text"
                  dangerouslySetInnerHTML={{ __html: item.headTitle }}
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
