import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout/layout";
import {
  getBlock,
  getSectionClassesWithoutPaddingTop,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsLandingPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function LandingsPageModel({ data }) {
  const landingPage = data.datoCmsLandingPage;
  const recentBlogs = data.recentBlogs.nodes;
  const recentEvents = data.recentEvents.nodes;
  const recentNews = data.recentNews.nodes;

  return (
    <Layout
      pageSlugs={getPageSlugs(data)}
      showHeader={landingPage.showHeader}
      showFooter={landingPage.showFooter}
    >
      <main
        role="main"
        className={`${landingPage.showHeader ? "pt-6" : ""} ${
          landingPage.showFooter ? "pb-6" : ""
        }`}
      >
        {landingPage.content.blocks.map((block, index) => {
          if (block?.model?.apiKey === "blocks_recent_three") {
            block.items = getRecentBlocksItems(block);
          }

          return block.heroImage ? (
            getBlock(block)
          ) : (
            <section
              role="region"
              className={getSectionClassesWithoutPaddingTop(
                landingPage.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );

  function getRecentBlocksItems(block) {
    const items = [];

    const getRecentBlock = (category, tagsObject) => {
      const tagsArray = tagsObject.map((tag) => tag.categoryName);

      switch (category) {
        case "Blogs":
          return recentBlogs.find(({ primaryCategory }) =>
            tagsArray.includes(primaryCategory.categoryName)
          );
        case "Events":
          return recentEvents.find(({ primaryCategory }) =>
            tagsArray.includes(primaryCategory.categoryName)
          );
        case "News":
          return recentNews.find(({ primaryCategory }) =>
            tagsArray.includes(primaryCategory.categoryName)
          );
      }
    };

    items.push(getRecentBlock(block.block1Category, block.block1Tags));
    items.push(getRecentBlock(block.block2Category, block.block2Tags));
    items.push(getRecentBlock(block.block3Category, block.block3Tags));

    return items;
  }
}

export const query = graphql`
  query (
    $language: String!
    $originalId: String!
    $blogsTags: [String]!
    $eventsTags: [String]!
    $newsTags: [String]!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsLandingPage(originalId: { eq: $originalId }, locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      id
      title
      pageSlug
      showHeader
      showFooter
      content {
        blocks {
          ...titleBlock
          ...blocksWithIconsFour
          ...richText
          ...blocksWithIconsThree
          ...blocksWithImagesThree
          ...blocksWithIconsTwo
          ...quote
          ...author
          ...image
          ...contactPerson
          ...blockWithIconAndImage
          ...logosBlock
          ...separator
          ...visualSummary
          ...code
          ...button1Link
          ...button2Link
          ...blocksWithIconsTwoAndImageBlock
          ...processVisualization
          ...linkToService
          ...linkToModel3
          ...linkToModel1
          ...executableHtml
          ...hubspotForm
          ...blocks3Recent
        }
      }
      meta {
        createdAt
      }
      seoMetaTags(locale: $language) {
        tags
      }
    }
    recentBlogs: allDatoCmsBlog(
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: {
        originalId: { ne: $originalId }
        primaryCategory: { id: { in: $blogsTags } }
      }
    ) {
      nodes {
        ...blogShort
      }
    }
    recentEvents: allDatoCmsEvent(
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: {
        originalId: { ne: $originalId }
        primaryCategory: { id: { in: $eventsTags } }
      }
    ) {
      nodes {
        ...eventShort
      }
    }
    recentNews: allDatoCmsNews(
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: {
        originalId: { ne: $originalId }
        primaryCategory: { id: { in: $newsTags } }
      }
    ) {
      nodes {
        ...newsShort
      }
    }
    pageSlugs: datoCmsLandingPage(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
