import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

export default function OfficeComponent({ office }) {
  const { t } = useTranslation();

  return (
    <a
      target="_blank"
      href={office.mapUrl}
      rel="noreferrer noopener"
      aria-label={`${t("open-google-maps-for")} ${t(office.key)}`}
    >
      <div className="font-size-500 font-weight-700">{t(office.key)}</div>
      <div className="color-black white-space-nowrap">{t(office.address)}</div>
      <div className="color-black white-space-nowrap">{office.city}</div>
    </a>
  );
}
