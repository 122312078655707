import { graphql } from "gatsby";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useContext, useState } from "react";
import Layout from "../components/layout/layout";
import BlogEntryComponent from "../components/blog-entry-component";
import LoadMoreButtonComponent, {
  LoadMoreContext,
} from "../components/load-more-button-component";
import FollowOnSocialMediaComponent from "../components/follow-on-social-media-component";
import SEO from "../seo/seo";

const imageUtil = require("../utils/image-util.js");
const pageSlugUtil = require("../utils/page-slug-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsPerson.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function AuthorModel({ data }) {
  const blogs = data.threeMostRecentAuthorBlogs.nodes;
  const person = data.datoCmsPerson;
  const [previousAmountOfEntriesShown, setPreviousAmountOfEntriesShown] =
    useState(-1);
  const [amountOfEntriesShown, setAmountOfEntriesShown] = useState(15);
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <Layout pageSlugs={pageSlugUtil.getDefaultPageSlugs(person.pageSlug)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-8 py-3">
              <div>
                <h1>{person.fullName}</h1>
                <div
                  className="html-content"
                  dangerouslySetInnerHTML={{ __html: person.bio }}
                />
                <FollowOnSocialMediaComponent
                  name={person.name}
                  twitter={person.twitter}
                  instagram={person.instagram}
                  facebook={person.facebook}
                  linkedin={person.linkedin}
                  youtube={person.youtube}
                  website={person.website}
                />
              </div>
            </div>
            {getAuthorImageDiv(person.photo)}
          </div>
          <h2 className="h1-styled">{`${t("blog-posts-of")} ${
            person.name
          }`}</h2>
        </div>
        <div className="py-2">
          <div className="container">
            <ul className="row list-style-none">{getBlogEntries()}</ul>
          </div>
        </div>
        <LoadMoreContext.Provider
          value={{
            amountOfEntriesShown,
            setAmountOfEntriesShown,
            setPreviousAmountOfEntriesShown,
            length: blogs.length,
          }}
        >
          <LoadMoreButtonComponent></LoadMoreButtonComponent>
        </LoadMoreContext.Provider>
      </main>
    </Layout>
  );

  function getAuthorImageDiv(photo) {
    if (photo) {
      return (
        <div className="col-md-4 col-lg-4 pt-6">
          <div className="my-3">
            {imageUtil.getImageElements(
              person.photo,
              "m-0 w-full h-full overflow-hidden border-radius-circle border border-1 border-black"
            )}
          </div>
        </div>
      );
    }
  }

  function getBlogEntries() {
    let blogEntries = [];

    for (let i = 0; i < amountOfEntriesShown && i < blogs.length; i++) {
      blogEntries.push(
        <BlogEntryComponent
          key={blogs[i].id}
          blog={blogs[i]}
          shouldFocus={i === previousAmountOfEntriesShown}
        ></BlogEntryComponent>
      );
    }

    return blogEntries;
  }

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/${
      language === "en" ? "author" : "auteur"
    }"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${person.fullName}",
        "item": "https://www.acagroup.be/${language}/${
      language === "en" ? "author" : "auteur"
    }/${person.pageSlug}"
      }]
    }`;
  }
}

export const query = graphql`
  query ($language: String!, $authorId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsPerson(id: { eq: $authorId }, locale: $language) {
      ...personAuthor
    }
    threeMostRecentAuthorBlogs: allDatoCmsBlog(
      sort: { meta: { createdAt: DESC } }
      filter: { author: { id: { eq: $authorId } } }
    ) {
      nodes {
        id
        headTitle
        pageSlug
        excerpt
        primaryCategory {
          categoryName
        }
        model {
          apiKey
        }
        coverImage {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { fit: "crop", ar: "1.8", crop: "focalpoint" }
          )
          format
          url
          alt
        }
        author {
          id
          pageSlug
          fullName
          photo {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
