import SeoMetaDataFactory from "./SeoMetaDataFactory";
import React from "react";
import SeoMetaTag from "./SeoMetaTag";
import { getAlternatePageSlugFromUrl } from "../utils/page-slug-util";
import { graphql, useStaticQuery } from "gatsby";

export default function SEO({ seo, language, pageSlug }) {
  const metaDataObj = SeoMetaDataFactory.createMetaDataTags(seo);
  const alternateLanguageUrl = getAlternatePageSlugFromUrl(language, pageSlug);
  const siteFaviconMetaTags = useStaticQuery(graphql`
    query {
      datoCmsSite {
        faviconMetaTags {
          tags
        }
      }
    }
  `);

  return (
    <>
      <html lang={language} />
      <title>{metaDataObj?.title}</title>
      <meta name="description" content={metaDataObj?.description} />
      <link
        rel="alternate"
        href={alternateLanguageUrl}
        lang={language === "en" ? "nl" : "en"}
      />
      {siteFaviconMetaTags.datoCmsSite.faviconMetaTags.tags.map((tag) => (
        <link
          key={`${tag.attributes.rel}-${tag.attributes.sizes}`}
          rel={tag.attributes.rel}
          sizes={tag.attributes.sizes}
          href={tag.attributes.href}
        />
      ))}
      {metaDataObj.metaDataTags.map((tag) => (
        <SeoMetaTag
          metaTag={tag}
          key={`${tag.tagName}-${
            tag.attributes?.name || tag.attributes?.property || "unknown"
          }`}
        />
      ))}
    </>
  );
}
