import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import { useContext } from "react";

export default function SiteMapPage({ data }) {
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main">
        <div className="py-6">
          <div className="container sitemap">
            <h1>ACA Group sitemap</h1>
            <nav role="navigation">
              <header role="banner">Page Contents</header>
              <ul>
                <li>
                  <a href="#whatWeOffer">{t("what-we-offer")}</a>
                </li>
                <li>
                  <a href="#innovation">{t("innovation")}</a>
                </li>
                <li>
                  <a href="#aboutAca">{t("about-aca")}</a>
                </li>
                <li>
                  <a href="#careers">{t("careers")}</a>
                </li>
                <li>
                  <a href="#acaInsights">{t("aca-insights")}</a>
                </li>
                <li>
                  <a href="#contact">{t("contact")}</a>
                </li>
                <li>
                  <a href="#searchAcaGroup">{t("search-aca-group")}</a>
                </li>
                <li>
                  <a href="#legalLinks">{t("legal-links")}</a>
                </li>
                <li>
                  <a href="#blogAuthors">{t("blog-authors")}</a>
                </li>
                <li>
                  <a href="#otherPages">{t("other-pages")}</a>
                </li>
              </ul>
            </nav>
            <h2 id="whatWeOffer">{t("what-we-offer")}</h2>
            <ul>
              <li>
                <Link to="/services">{t("services")}</Link>
                <ul>
                  {data.allDatoCmsService.nodes.map((service) => {
                    return getLink(
                      "/services/",
                      service.pageSlug,
                      service.headTitle
                    );
                  })}
                </ul>
              </li>
              <li>
                <Link to={language === "en" ? "/products/" : "/producten/"}>
                  {t("products")}
                </Link>
                <ul>
                  {data.allDatoCmsProduct.nodes.map((product) => {
                    return getLink(
                      language === "en" ? "/products/" : "/producten/",
                      product.pageSlug,
                      product.headTitle
                    );
                  })}
                </ul>
              </li>
              <Link
                to={language === "en" ? "/how-we-help/" : "/hoe-wij-helpen/"}
              >
                {t("how-can-we-help-you")}
              </Link>
              <li>
                <Link to={language === "en" ? "/industries/" : "/sectoren/"}>
                  {t("industries")}
                </Link>
                <ul>
                  {data.allDatoCmsSector.nodes.map((sector) => {
                    return getLink(
                      language === "en" ? "/industries/" : "/sectoren/",
                      sector.pageSlug,
                      sector.headTitle
                    );
                  })}
                </ul>
              </li>
              <li>
                <Link to="/trending">{t("trending")}</Link>
              </li>
            </ul>
            <h2 id="innovation">{t("innovation")}</h2>
            <ul>
              <li>
                <Link to="/innovation">{t("innovation")}</Link>
              </li>
            </ul>
            <h2 id="aboutAca">{t("about-aca")}</h2>
            <ul>
              <li>
                <Link to="/aca-as-a-company">{t("aca-as-a-company")}</Link>
              </li>
              <li>
                <Link to="/partners">{t("partners")}</Link>
              </li>
            </ul>
            <h2 id="careers">{t("careers")}</h2>
            <ul>
              <li>
                <Link to={language === "en" ? "/careers/" : "/jobs/"}>
                  {t("careers-header-title")}
                </Link>
                <ul>
                  {data.allDatoCmsJob.nodes.map((job) => {
                    return getLink(
                      language === "en" ? "/careers/" : "/jobs/",
                      job.pageSlug,
                      job.jobName
                    );
                  })}
                </ul>
              </li>
              <li>
                <Link to="/job-application-form">
                  {t("fill-in-spontaneous-application")}
                </Link>
              </li>
              <li>
                <Link to="/job-referral-form">{t("refer-a-friend")}</Link>
              </li>
              <li>
                <Link
                  to={language === "en" ? "/internships" : "/stageplaatsen"}
                >
                  {t("internships")}
                </Link>
              </li>
              <li>
                <Link to="/academy">{t("academy")}</Link>
              </li>
            </ul>
            <h2 id="acaInsights">{t("aca-insights")}</h2>
            <ul>
              <li>
                <Link to="/blog">{t("blog")}</Link>
                <ul>
                  {data.allDatoCmsBlog.nodes.map((blog) => {
                    return getLink("/blog/", blog.pageSlug, blog.headTitle);
                  })}
                </ul>
              </li>
              <li>
                <Link to="/customer-success-stories">
                  {t("customer-success-stories")}
                </Link>
                <ul>
                  {data.allDatoCmsCase.nodes.map((customerSuccessStory) => {
                    return getLink(
                      "/customer-success-stories/",
                      customerSuccessStory.pageSlug,
                      customerSuccessStory.headTitle
                    );
                  })}
                </ul>
              </li>
              <li>
                <Link to={language === "en" ? "/news/" : "/nieuws/"}>
                  {t("news")}
                </Link>
                <ul>
                  {data.allDatoCmsNews.nodes.map((news) => {
                    return getLink(
                      language === "en" ? "/news/" : "/nieuws/",
                      news.pageSlug,
                      news.headTitle
                    );
                  })}
                </ul>
              </li>
              <li>
                <Link to="/events/">{t("events")}</Link>
                <ul>
                  {data.allDatoCmsEvent.nodes.map((event) => {
                    if (event.externalEventUrl) {
                      return "";
                    }

                    return getLink("/events/", event.pageSlug, event.headTitle);
                  })}
                </ul>
              </li>
              <li>
                <Link to="/podcast/">{t("podcast")}</Link>
                <ul>
                  {data.allDatoCmsPodcast.nodes.map((podcast) => {
                    return getLink(
                      "/podcast/",
                      podcast.pageSlug,
                      podcast.title
                    );
                  })}
                </ul>
              </li>
              <li>
                <Link to="/downloads/">{t("downloads")}</Link>
              </li>
            </ul>
            <h2 id="contact">{t("contact")}</h2>
            <Link to="/contact">{t("contact")}</Link>
            <h2 id="searchAcaGroup">{t("search-aca-group")}</h2>
            <Link to="/search">{t("search-page")}</Link>
            <h2 id="legalLinks">{t("legal-links")}</h2>
            <ul>
              <li>
                <Link
                  to={
                    language === "en"
                      ? "/legal-information/"
                      : "/juridische-info/"
                  }
                >
                  {t("legal-information")}
                </Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">
                  {t("terms-and-conditions")}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">{t("privacy-policy")}</Link>
              </li>
              <li>
                <Link to="/cookie-policy">{t("cookie-policy")}</Link>
              </li>
            </ul>
            <h2 id="blogAuthors">{t("blog-authors")}</h2>
            <ul>
              {data.authors.group.map((group) => {
                return getLink(
                  language === "en" ? "/author/" : "/auteur/",
                  group.nodes[0].author.pageSlug,
                  group.nodes[0].author.fullName
                );
              })}
            </ul>
            <h2 id="otherPages">{t("other-pages")}</h2>
            <ul>
              {data.allDatoCmsLandingPage.nodes.map((page) => {
                return getLink("/page/", page.pageSlug, page.title);
              })}
              <li>
                <Link to="/bio-links">{t("social-media-bio-links")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </Layout>
  );

  function getLink(slugPrefix, pageSlug, title) {
    return (
      <li key={`${slugPrefix}${pageSlug}`}>
        <Link to={`${slugPrefix}${pageSlug}`}>{title}</Link>
      </li>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allDatoCmsService(locale: $language) {
      nodes {
        ...serviceShortLink
      }
    }
    allDatoCmsSector(locale: $language) {
      nodes {
        ...sectorShortLink
      }
    }
    allDatoCmsJob(locale: $language) {
      nodes {
        ...jobShortLink
      }
    }
    allDatoCmsBlog(locale: $language) {
      nodes {
        ...blogShortLink
      }
    }
    allDatoCmsCase(locale: $language) {
      nodes {
        ...caseShortLink
      }
    }
    allDatoCmsNews(locale: $language) {
      nodes {
        ...newsShortLink
      }
    }
    allDatoCmsEvent(locale: $language) {
      nodes {
        ...eventShortLink
      }
    }
    allDatoCmsProduct(locale: $language) {
      nodes {
        ...productShortLink
      }
    }
    allDatoCmsPodcast(locale: $language) {
      nodes {
        ...podcastShortLink
      }
    }
    authors: allDatoCmsBlog(locale: $language) {
      group(field: { author: { id: SELECT } }, limit: 1) {
        nodes {
          ...blogAuthor
        }
      }
    }
    allDatoCmsLandingPage(locale: $language) {
      nodes {
        title
        pageSlug
      }
    }
  }
`;
