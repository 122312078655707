import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import FooterPhoneComponent from "./footer-phone-component";
import FooterWhatsappComponent from "./footer-whatsapp-component";

export default function FooterContactComponent({ workAtAca }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FooterPhoneComponent />
      <div className="mt-4">
        <div>{t("email")}</div>
        <a
          href={
            workAtAca ? "mailto:jobs@acagroup.be" : "mailto:hello@acagroup.be"
          }
          className="hover-color-quaternary-500 font-size-500 font-weight-700"
        >
          {workAtAca ? "jobs@acagroup.be" : "hello@acagroup.be"}
        </a>
      </div>
      <FooterWhatsappComponent />
    </React.Fragment>
  );
}
