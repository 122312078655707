import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import Layout from "../components/layout/layout";
import {
  getBlock,
  getFirstBlockIsHeroTitleBlock,
  getSectionClassesWithPaddingTop,
  getTitleBlock,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsService.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function ServiceModel({ data, location }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const service = data.datoCmsService;
  const contentBlocks = service.content.blocks;
  const bodyContentBlocks = [...contentBlocks];
  const firstBlockIsHeroTitleBlock =
    getFirstBlockIsHeroTitleBlock(contentBlocks);

  if (firstBlockIsHeroTitleBlock) {
    bodyContentBlocks.shift();
  }

  return (
    <Layout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        {firstBlockIsHeroTitleBlock && getTitleBlock(contentBlocks[0])}
        <section role="region" className="container pt-6">
          <div className="row">
            <div className="col-12">
              <Link
                to={
                  location?.state?.previous
                    ? `${location.state.previous}${window.location.search}`
                    : "/services"
                }
                className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                <i aria-hidden={true} className="ti ti-chevron-left mr-2" />
                {location?.state?.previous || location?.search
                  ? t("back-to-search")
                  : t("back-to-services-overview")}
              </Link>
            </div>
          </div>
        </section>
        {bodyContentBlocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                bodyContentBlocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/services"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${service.headTitle}",
        "item": "https://www.acagroup.be/${language}/services/${service.pageSlug}"
      }]
    }`;
  }
}

export const query = graphql`
  query ($language: String!, $originalId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsService(originalId: { eq: $originalId }, locale: $language) {
      ...serviceFull
    }
    pageSlugs: datoCmsService(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
