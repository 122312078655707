import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Layout from "../components/layout/layout";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsTermsConditionsPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function TermsAndConditionsPage({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="py-6">
          <div className="container">
            <div className="row py-3">
              <div className="col-md-6 justify-content-center">
                <h1 className="my-0">{t("terms-and-conditions")}</h1>
              </div>
              <div className="col-md-6 d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-end pt-6 pt-md-0">
                <a
                  href={
                    data.datoCmsTermsConditionsPage.termsAndConditionsPdf.url
                  }
                  download
                  className="btn mt-2 mt-md-0 font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
                >
                  Download PDF{" "}
                  <i aria-hidden={true} className="ti ti-download ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="pt-4 col-xl-6 offset-xl-3"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsTermsConditionsPage.richText[0].content,
            }}
          />
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsTermsConditionsPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      id
      richText {
        content
      }
      termsAndConditionsPdf {
        url
      }
    }
  }
`;
