import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function Blocks3WithImages({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <ul className="row list-style-none">
          {getBlockContent(
            data.linkHref1,
            data.image1,
            data.tag1,
            data.title1,
            data.content1,
            data.author1,
            data.openLink1InNewTab
          )}
          {getBlockContent(
            data.linkHref2,
            data.image2,
            data.tag2,
            data.title2,
            data.content2,
            data.author2,
            data.openLink2InNewTab
          )}
          {getBlockContent(
            data.linkHref3,
            data.image3,
            data.tag3,
            data.title3,
            data.content3,
            data.author3,
            data.openLink3InNewTab
          )}
        </ul>
      </div>
    </div>
  );
}

function getBlockContent(
  link,
  image,
  tag,
  title,
  content,
  author,
  openLinkNewTab
) {
  return (
    <li className="col-md-6 col-lg-4 py-3" key={title}>
      <div className="py-4 border-radius-lg w-full h-full">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          className="flex-column w-full"
          target={openLinkNewTab ? "_blank" : "_self"}
          rel={openLinkNewTab && "noreferrer noopener"}
          href={link}
        >
          <div className="mb-3 w-full">
            <div
              aria-hidden={true}
              className="picture position-relative justify-content-center"
            >
              {imageUtil.getImageElements(image, "w-full w-max-full")}
              {link ? (
                <div className="picture__cover">
                  <i className="ti ti-arrow-narrow-right font-size-900 color-white" />
                </div>
              ) : (
                ""
              )}
              {tag?.tagName ? (
                <span
                  className={`picture__tag bg-white color-tertiary-500 font-size-300 font-weight-700 p-1 px-2 m-3`}
                >
                  {tag.tagName}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
            <div
              className="rich-text"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </a>
        <div className="mb-3">
          <div
            className="rich-text"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        {getAuthorContent(author)}
      </div>
    </li>
  );
}

function getAuthorContent(author) {
  if (author) {
    return (
      <div className="d-flex flex-row flex-wrap">
        {getAuthorImage(author.photo)}
        <div className="flex-grow-1">
          <div className="m-0 font-size-500 font-weight-700">
            {author.fullName}
          </div>
        </div>
      </div>
    );
  }
}

function getAuthorImage(photo) {
  if (photo) {
    return (
      <div className="line-height-1 mr-3">
        {imageUtil.getImageElements(
          photo,
          "m-0 w-36 h-36 overflow-hidden border-radius-circle"
        )}
      </div>
    );
  }

  return (
    <div className="line-height-1 mr-3">
      <StaticImage
        src="../images/placeholder-avatar.jpg"
        alt="Avatar placeholder"
        className="m-0 w-36 h-36 overflow-hidden border-radius-circle"
      />
    </div>
  );
}

export const blocksWithImagesThreeFragment = graphql`
  fragment blocksWithImagesThree on DatoCmsBlocksWithImagesThree {
    id
    greyBackground
    model {
      apiKey
    }
    title1
    content1
    image1 {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "1.8", crop: "focalpoint" }
      )
      format
      url
    }
    tag1 {
      tagName
    }
    author1 {
      fullName
      photo {
        format
        gatsbyImageData
        url
        alt
      }
    }
    openLink1InNewTab
    linkHref1
    title2
    content2
    image2 {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "1.8", crop: "focalpoint" }
      )
      format
      url
    }
    tag2 {
      tagName
    }
    author2 {
      fullName
      photo {
        format
        gatsbyImageData
        url
        alt
      }
    }
    linkHref2
    openLink2InNewTab
    title3
    content3
    image3 {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "1.8", crop: "focalpoint" }
      )
      format
      url
    }
    tag3 {
      tagName
    }
    author3 {
      fullName
      photo {
        format
        gatsbyImageData
        url
        alt
      }
    }
    linkHref3
    openLink3InNewTab
    blockId
  }
`;
