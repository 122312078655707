import { graphql, navigate } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Configure,
  connectHits,
  connectStateResults,
} from "react-instantsearch-core";
import {
  ClearRefinements,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom";
import RichTextBlock from "../building-blocks/rich-text-block";
import WorkAtAcaLayout from "../components/layout/work-at-aca-layout";
import AlgoliaSearchContextComponent from "../components/search/algolia-search-context-component";
import JobEmptyMessageComponent from "../components/search/job-empty-message-component";
import CareersHits from "../components/search/careers/careers-hits";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../seo/seo";
import { CareersHitContext } from "../utils/contexts";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsJobOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function CareersPage({ data, location }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const [amountOfHits, setAmountOfHits] = useState("");

  const mobileFilterRef = useRef(null);
  const openMobileFilterRef = useRef(null);
  const [mobileFilterOpened, setMobileFilterOpened] = useState("");

  const tileNavigationHandler = async (event, url) => {
    const type = event.type;

    if (type === "click" || (type === "keydown" && event.keyCode === 13)) {
      await navigate(`/${language}${url}`, {
        state: {
          previous: `/${location.pathname.split("/")[2]}${
            window.location.search
          }`,
        },
      });
    }
  };

  const overviewContent = data.datoCmsJobOverviewPage;

  const ConnectedEmptyMessageComponent = connectStateResults(
    JobEmptyMessageComponent
  );

  const ConnectedCareersHitsComponent = connectHits(CareersHits);

  const getSelectedJobCategories = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let selectedJobTypeCategories = [];
    let selectedJobPreferenceCategories = [];
    let selectedJobProficiencyCategories = [];

    for (let key of urlSearchParams.keys()) {
      switch (true) {
        case key.includes("jobTypeCategories"):
          selectedJobTypeCategories.push(urlSearchParams.get(key));
          break;
        case key.includes("jobPreferenceCategories"):
          selectedJobPreferenceCategories.push(urlSearchParams.get(key));
          break;
        case key.includes("jobProficiencyCategories"):
          selectedJobProficiencyCategories.push(urlSearchParams.get(key));
          break;
      }
    }

    return {
      selectedJobTypeCategories: selectedJobTypeCategories,
      selectedJobPreferenceCategories: selectedJobPreferenceCategories,
      selectedJobProficiencyCategories: selectedJobProficiencyCategories,
    };
  };

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        mobileFilterOpened &&
        mobileFilterRef.current !== null &&
        !mobileFilterRef.current.contains(e.target) &&
        openMobileFilterRef.current !== null &&
        !openMobileFilterRef.current.contains(e.target)
      ) {
        setMobileFilterOpened(!mobileFilterOpened);
        document.body.classList = "";
      }
    };

    if (mobileFilterOpened) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [mobileFilterOpened]);

  return (
    <WorkAtAcaLayout>
      <main role="main" className="py-6">
        <AlgoliaSearchContextComponent replicaName="careers-only">
          {getResponseMessage(location?.state)}
          <Configure
            filters={`type:${
              language === "nl" ? "Vacature" : "Career"
            } AND locale:${language}`}
            hitsPerPage={data.jobsCount.totalCount}
          />
          {getMobileOverlay()}
          {getMobileFilter()}
          {getHeroBanner()}
          {getDiscoverMoreAboutWorkingAtAcaRichTextBlock(
            overviewContent.firstSection[0]
          )}
          <div className="container">
            <div className="row">
              <Link
                className="col-xl-6 offset-xl-3 mb-6"
                hrefLang={language}
                to={language === "nl" ? "/page/ons-team" : "/page/our-team"}
              >
                {t("discover-more-about-working")}
                <i aria-hidden="true" className="ti ti-chevron-right ml-1" />
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="my-0 py-3 py-xl-0">
              <div className="row">{getOpenMobileFilterButton()}</div>
            </div>
          </div>
          <div className={location?.state?.response ? "py-0" : "py-2"}>
            <div className="container">
              <div className="row">
                {getDesktopFilter()}
                <div className="col-xl-8 offset-xl-0 search-result-list">
                  <h2 className="mt-0 mb-4">
                    {`${amountOfHits} ${t("careers-search-title")}`}
                  </h2>
                  <CareersHitContext.Provider
                    value={{
                      amountOfHits,
                      setAmountOfHits,
                      getSelectedJobCategories,
                      tileNavigationHandler,
                    }}
                  >
                    <ConnectedCareersHitsComponent />
                  </CareersHitContext.Provider>
                  <ConnectedEmptyMessageComponent />
                </div>
              </div>
            </div>
          </div>
        </AlgoliaSearchContextComponent>
      </main>
    </WorkAtAcaLayout>
  );

  function getHeroBanner() {
    return (
      <section role="region" className="hero hero--fit">
        <div className="picture d-flex">
          <StaticImage
            src="../images/hero-homepage.png"
            alt=""
            imgClassName="w-max-full"
            layout="fullWidth"
            loading="eager"
          />
        </div>
        <div className="hero__content min-h-fit-content">
          <div className="container my-6">
            <h1 className="color-white mt-0 mb-3">{t("careers")}</h1>
            <div className="color-white font-weight-700 font-size-700 line-height-md">
              {t(
                "we-are-always-looking-for-passionate-people-to-join-our-team"
              )}
              <br />
              {t("could-this-be-you")}
            </div>
          </div>
        </div>
      </section>
    );
  }

  function getDiscoverMoreAboutWorkingAtAcaRichTextBlock(data) {
    return (
      <React.Fragment>
        <RichTextBlock data={data} />
      </React.Fragment>
    );
  }

  function getDesktopFilter() {
    return (
      <div className="col-xl-4 offset-xl-0 d-none d-xl-block">
        <div className="filter-background search-filters p-4 border border-primary-500">
          <div className="global-search mb-4 d-flex flex-row">
            <div
              className="w-full search-wrapper position-relative bg-white border-radius-pill border border-primary-500 hover-border-quaternary-500 focus-border-quaternary-500 font-size-400"
              role="search"
            >
              <SearchBox
                defaultRefinement={
                  location?.state?.searchQuery ? location.state.searchQuery : ""
                }
                autoFocus={true}
                translations={{
                  placeholder: t("search-here"),
                  submitTitle: t("submit-search-query"),
                }}
              />
            </div>
          </div>
          <h2 className="mt-0 mb-2 h3-styled">{t("type")}</h2>
          <RefinementList attribute="jobTypeCategories" />
          <h2 className="mb-2 h3-styled">{t("category")}</h2>
          <RefinementList attribute="jobPreferenceCategories" />
          <h2 className="mb-2 h3-styled">{t("proficiency")}</h2>
          <RefinementList attribute="jobProficiencyCategories" />

          <div className="mt-3 d-flex ">
            <ClearRefinements
              clearsQuery={true}
              translations={{
                reset: t("remove-all-filters"),
              }}
              className="btn w-full font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
            />
          </div>
        </div>
      </div>
    );
  }

  function getMobileFilter() {
    return (
      <div
        className={`${
          mobileFilterOpened ? "d-flex" : "d-none"
        } d-xl-none align-items-center line-height-normal`}
      >
        <div
          className="dropdown-content position-fixed top-0 pb-4 horizontal-center overflow-auto w-full shadow-lg mobile-filter filter-background"
          ref={mobileFilterRef}
        >
          <div className="px-6 py-1">
            <div className="row search-filters">
              <h2 className="mb-2 col-10">{t("search-term")}</h2>
              <a
                className="font-weight-700 font-size-600 color-black col-2 align-items-center justify-content-end"
                onClick={() => {
                  setMobileFilterOpened(!mobileFilterOpened);
                  document.body.classList = "";
                }}
              >
                <span className="accessibility-visually-hidden">
                  {t("close-filters")}
                </span>
                <i aria-hidden={true} className="ti ti-x font-size-700" />
              </a>
              <div className="global-search mb-4 -flex flex-row">
                <div
                  className="w-full search-wrapper position-relative bg-white border-radius-pill border border-primary-500 hover-border-quaternary-500 focus-border-quaternary-500 font-size-400"
                  role="search"
                >
                  <SearchBox
                    defaultRefinement={
                      location?.state?.searchQuery
                        ? location.state.searchQuery
                        : ""
                    }
                    autoFocus={true}
                  />
                </div>
              </div>
              <h2 className="mb-2 col-10 h3-styled">{t("type")}</h2>
              <RefinementList attribute="jobTypeCategories" />
              <h2 className="mt-4 mb-2 h3-styled">{t("category")}</h2>
              <RefinementList attribute="jobPreferenceCategories" />
              <h2 className="mt-4 mb-2 h3-styled">{t("proficiency")}</h2>
              <RefinementList attribute="jobProficiencyCategories" />
              <div className="mt-3 d-flex ">
                <ClearRefinements
                  // Optional parameters
                  clearsQuery={true}
                  translations={{
                    reset: t("remove-all-filters"),
                  }}
                  className="btn w-full font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getOpenMobileFilterButton() {
    return (
      <div className="col-xl-8 mobile-quick-filter-button d-flex justify-content-center align-items-center">
        <button
          ref={openMobileFilterRef}
          onClick={() => {
            setMobileFilterOpened(!mobileFilterOpened);
            document.body.className = "overflow-hidden";
          }}
          className="btn mobile-quick-filter-button font-weight-600 color-secondary-500 hover-color-white flex-wrap hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500 justify-content-center"
        >
          <i
            aria-hidden={true}
            className="ti ti-filter font-size-700 mr-3 ml-0"
          />
          {t("filter-careers")}
        </button>
      </div>
    );
  }

  function getMobileOverlay() {
    if (mobileFilterOpened) {
      return <div className="mobile-filter__overlay d-lg-none" />;
    }
  }

  function getResponseMessage(responseState) {
    if (responseState?.response === "ok") {
      return (
        <section
          role="region"
          className="bg-success-a-200 m-0 pb-2 pt-6 py-md-2 success-border"
        >
          <div className="container">
            <h2 className="my-2">
              {getResponseMessageSuccessTitle(responseState.type)}
            </h2>
            <p>{getResponseMessageSuccessDescription(responseState.type)}</p>
          </div>
        </section>
      );
    } else if (responseState?.response === "error") {
      return (
        <section
          role="region"
          className="bg-danger-a-200 m-0 pb-2 pt-6 py-md-2 danger-border"
        >
          <div className="container">
            <h2 className="my-2">{t("oh-no-an-error-occurred")}</h2>
            <p>
              {getResponseMessageErrorDescription(responseState.type)}{" "}
              {responseState.type !== "jobalert" ? (
                <Link to={/contact/}>{t("this-page")}</Link>
              ) : (
                ""
              )}
              .
            </p>
          </div>
        </section>
      );
    }
  }

  function getResponseMessageSuccessTitle(responseType) {
    switch (true) {
      case responseType === "application":
        return t("application-submitted-success-title");
      case responseType === "referral":
        return t("referral-submitted-success-title");
      case responseType === "jobalert":
        return t("jobalert-submitted-success-title");
    }
  }

  function getResponseMessageSuccessDescription(responseType) {
    switch (true) {
      case responseType === "application":
        return t("application-submitted-success-description");
      case responseType === "referral":
        return t("referral-submitted-success-description");
      case responseType === "jobalert":
        return t("jobalert-submitted-success-description");
    }
  }

  function getResponseMessageErrorDescription(responseType) {
    switch (true) {
      case responseType === "application":
        return t("application-submitted-error-description");
      case responseType === "referral":
        return t("referral-submitted-error-description");
      case responseType === "jobalert":
        return t("jobalert-submitted-error-description");
    }
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsJobOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      firstSection {
        alignment
        content
      }
    }
    jobsCount: allDatoCmsJob(locale: $language) {
      totalCount
    }
  }
`;
