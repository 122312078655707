import React from "react";
import { useModelSlugPrefix } from "../../../../hooks/link-util";

export default function JobTile({ hit, tileNavigationHandler }) {
  const tileUrl = `${useModelSlugPrefix(hit.apiKey)}${hit.slug}`;

  return (
    <li className="ais-InfiniteHits-item single-block">
      <div className="event-item-wrapper w-full">
        <div className="event-item" data-testid="jobEntry">
          <h3
            role="link"
            onClick={(event) => tileNavigationHandler(event, tileUrl)}
            onKeyDown={(event) => tileNavigationHandler(event, tileUrl)}
            tabIndex="0"
          >
            {hit.title}
          </h3>
          <p
            className="color-gray-500 font-weight-500 font-size-500"
            dangerouslySetInnerHTML={{ __html: hit.description }}
          />
          <div className="my-1 color-tertiary-500 font-weight-500 font-size-400">
            {getJobCategoriesCombined(hit)}
          </div>
        </div>
      </div>
    </li>
  );

  function getJobCategoriesCombined(hit) {
    let jobCategories = [];

    hit.jobTypeCategories.map((typeCategory) => {
      jobCategories.push(typeCategory);
    });
    hit.jobPreferenceCategories.map((preferenceCategory) => {
      jobCategories.push(preferenceCategory);
    });

    hit.jobProficiencyCategories.map((proficiencyCategory) => {
      jobCategories.push(proficiencyCategory);
    });

    return jobCategories.join(" - ");
  }
}
