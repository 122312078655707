import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Layout from "../components/layout/layout";
import { getBlock, getSectionClassesWithPaddingTop } from "../utils/block-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsServiceOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function ServicesPage({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="py-6">
        {getHeroBanner()}
        {data.datoCmsServiceOverviewPage.content.blocks.map((block, index) => {
          return block.heroImage ? (
            getBlock(block)
          ) : (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                data.datoCmsServiceOverviewPage.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );

  function getHeroBanner() {
    return (
      <section role="region" className="hero hero--fit">
        <div className="picture d-flex">
          <StaticImage
            src="../images/hero-services.png"
            alt=""
            imgClassName="w-max-full"
            layout="fullWidth"
            loading="eager"
          />
        </div>
        <div className="hero__content">
          <div className="container">
            <h1 className="color-white">{t("services")}</h1>
            <div className="color-white font-weight-700 font-size-700 line-height-md">
              {t("services-overview-subtitle")}
            </div>
            <div className="my-6 d-flex flex-column bg-primary-500 p-3 color-white font-size-600 col-md-10 col-lg-8 col-xl-6">
              <div>{t("services-overview-content")}</div>
              <div className="mt-3">
                <strong>{t("services-overview-question")}</strong>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsServiceOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      content {
        blocks {
          ...titleBlock
          ...blocksWithIconsFour
          ...richText
          ...blocksWithIconsThree
          ...blocksWithImagesThree
          ...blocksWithIconsTwo
          ...quote
          ...author
          ...image
          ...contactPerson
          ...blockWithIconAndImage
          ...logosBlock
          ...separator
          ...visualSummary
          ...code
          ...button1Link
          ...button2Link
          ...blocksWithIconsTwoAndImageBlock
          ...processVisualization
          ...linkToService
          ...linkToModel3
          ...linkToModel1
          ...executableHtml
          ...hubspotForm
        }
      }
    }
  }
`;
