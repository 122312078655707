import React from "react";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function Blocks2WithIconsBlockWithImage({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <ul className="row list-style-none">
          {getImageBlock(data, data.imageLeft)}
          {getIconBlock(
            data.headTitle1,
            data.content1,
            data.iconImage1,
            data.iconSize1,
            data.linkHref1,
            data.openLink1InNewTab
          )}
          {getIconBlock(
            data.headTitle2,
            data.content2,
            data.iconImage2,
            data.iconSize2,
            data.linkHref2,
            data.openLink2InNewTab
          )}
          {getImageBlock(data, !data.imageLeft)}
        </ul>
      </div>
    </div>
  );
}

function getImageBlock(data, visible) {
  let classes = "col-md-6 col-lg-4 py-3 d-lg-block ";

  if (!data.showImageMobile) {
    classes += "d-none";
  }

  if (visible) {
    return (
      <li className={classes} key={data.url}>
        {getImage(data.image, "w-full h-full d-block")}
      </li>
    );
  }
}

function getImage(image, classes) {
  return imageUtil.getImageElements(image, classes);
}

function getIconBlock(title, content, icon, iconSize, linkHref, openInNewTab) {
  return (
    <li className="col-md-6 col-lg-4 py-3" key={title}>
      <div className="p-4 border-radius-lg h-full">
        {getIcon(icon, iconSize)}
        <div className="w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
          {getTitle(title, linkHref, openInNewTab)}
        </div>
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </li>
  );
}

function getIcon(iconImageData, iconSizeDate) {
  if (iconImageData) {
    return (
      <div className="mb-3">
        {getBlockIconImage(iconImageData, iconSizeDate)}
      </div>
    );
  }
}

function getBlockIconImage(blockIcon, blockIconSize) {
  const blockIconClasses = getIconSizeClass(blockIconSize);

  return imageUtil.getImageElements(blockIcon, blockIconClasses);
}

function getIconSizeClass(iconSize) {
  switch (iconSize) {
    case "32px":
      return "w-32";
    case "36px":
      return "w-36";
    case "40px":
      return "w-40";
    case "full width":
      return "w-max-full";
    default:
      return "w-32";
  }
}

function getTitle(title, linkHref, openInNewTab) {
  if (linkHref) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        target={openInNewTab ? "_blank" : "_self"}
        rel={openInNewTab && "noreferrer noopener"}
        href={linkHref}
      >
        {title}
      </a>
    );
  }

  return title;
}

export const blocksWithIconsTwoAndImageBlockFragment = graphql`
  fragment blocksWithIconsTwoAndImageBlock on DatoCmsBlocksWithIconsTwoAndImageBlock {
    id
    greyBackground
    headTitle1
    content1
    iconImage1 {
      gatsbyImageData
      format
      url
      alt
    }
    model {
      apiKey
    }
    iconSize1
    linkHref1
    openLink1InNewTab
    headTitle2
    content2
    iconImage2 {
      gatsbyImageData
      format
      url
      alt
    }
    iconSize2
    linkHref2
    openLink2InNewTab
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", ar: "1.4", crop: "focalpoint" }
      )
      format
      url
      alt
    }
    imageLeft
    showImageMobile
    blockId
  }
`;
