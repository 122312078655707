import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function QuoteBlock({ data }) {
  const { t } = useTranslation();

  return (
    <div
      className="py-2 quote-block"
      {...(data.blockId ? { id: data.blockId } : {})}
    >
      <div className="container">
        <div className="row">
          <div className={getBlockAlignment(data.blockAlignment)}>
            <div className="row">
              <div
                className={data.blockAlignment ? "" : "col-xl-6 offset-xl-3"}
              >
                <div className="color-primary-500">
                  <svg
                    aria-hidden="true"
                    role="img"
                    focusable="false"
                    height="60px"
                    width="60px"
                    fill="#1654C6"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                  >
                    <path d="M94.8,57.3c-1.3-8.7-8.7-14.9-17.1-15.3c2.1-7.8,7.2-10.9,13.4-12.7c0.6-0.2,0.5-0.8,0.5-0.8l-1-6.2c0,0-0.1-0.5-0.9-0.4  c-21.4,2.4-35.9,18.7-33.2,38.5C59,74.1,69.7,79.4,79.6,78C89.5,76.4,96.3,67.2,94.8,57.3L94.8,57.3z M26.5,42  c2-7.8,7.3-10.9,13.4-12.7c0.6-0.2,0.5-0.8,0.5-0.8l-0.9-6.2c0,0-0.1-0.5-0.9-0.4C17.2,24.2,2.8,40.5,5.3,60.3  C7.9,74.1,18.7,79.4,28.5,78c9.9-1.6,16.7-10.8,15.1-20.7C42.3,48.5,35,42.3,26.5,42z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={
                  data.blockAlignment
                    ? "d-flex flex-column flex-md-row"
                    : "d-flex flex-column flex-md-row col-xl-6 offset-xl-3"
                }
              >
                <blockquote className="m-0 pr-6 flex-grow-1">
                  <div className="font-size-700 font-size-sm-500 font-size-md-600 py-4">
                    <div
                      className="rich-text"
                      dangerouslySetInnerHTML={{ __html: data.quote }}
                    />
                  </div>
                  <div className="color-gray-500">
                    <span>—</span>{" "}
                    {data.quotee.name + " " + data.quotee.surname},{" "}
                    {data.quotee.jobTitle} {t("at")} {data.quotee.company}
                  </div>
                </blockquote>
                <div className="mt-4 pt-2">{getImage(data.quotee)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getBlockAlignment(alignment) {
  switch (alignment) {
    case "Full":
      return "col-xl-12 offset-xl-0";
    case "Left":
      return "col-xl-6 offset-xl-0";
    case "Right":
      return "col-xl-6 offset-xl-6";
    default:
      return "";
  }
}

function getImage(quotee) {
  if (quotee.photo) {
    quotee.photo.alt =
      quotee.name + " " + quotee.surname + " - " + quotee.jobTitle;

    return imageUtil.getImageElements(
      quotee.photo,
      "m-0 w-120 h-120 overflow-hidden border-radius-circle"
    );
  }

  return (
    <StaticImage
      src="../images/placeholder-avatar.jpg"
      alt=""
      className="m-0 w-120 h-120 overflow-hidden border-radius-circle"
    />
  );
}

export const quoteFragment = graphql`
  fragment quote on DatoCmsQuote {
    id
    greyBackground
    quotee {
      name
      surname
      jobTitle
      company
      photo {
        format
        gatsbyImageData(width: 200)
        url
        alt
      }
    }
    blockId
    quote
    model {
      apiKey
    }
  }
`;
