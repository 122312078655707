import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function ContactPersonBlock({ data }) {
  return (
    <div className="d-flex justify-content-center">
      <div
        className="py-2 bg-white drop-shadow px-6 contact-person"
        {...(data.blockId ? { id: data.blockId } : {})}
      >
        <div className="container d-flex flex-column align-items-start p-4">
          {getTitleDiv(data.title)}
          {getSubtitleDiv(data.subtitle)}
          <div className="d-flex flex-row align-items-center flex-wrap mt-3">
            <div className="line-height-1 mr-3">
              {getImageDisplay(
                data.person.photo,
                "m-0 w-60 h-60 overflow-hidden border-radius-circle"
              )}
            </div>
            <div className="flex-grow-1 mt-3">
              <div className="m-0 font-size-700">
                <strong>{data.person.name}</strong> {data.person.surname}
              </div>
              {getJobTitleDiv(data.person.jobTitle)}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            {getPhoneButton(data.person.name, data.person.phone)}
            {getEmailButton(data.person.name, data.person.email)}
          </div>
        </div>
      </div>
    </div>
  );
}

function getTitleDiv(title) {
  if (title) {
    return (
      <div className="rich-text" dangerouslySetInnerHTML={{ __html: title }} />
    );
  }
}

function getSubtitleDiv(subtitle) {
  if (subtitle) {
    return (
      <div
        className="rich-text mt-3"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    );
  }
}

function getJobTitleDiv(jobTitle) {
  if (jobTitle) {
    return <div className="mb-4 color-gray-500">{jobTitle}</div>;
  }
}

function getImageDisplay(photo, classes) {
  if (photo) {
    return imageUtil.getImageElements(photo, classes);
  }

  return (
    <StaticImage
      src="../images/placeholder-avatar.jpg"
      alt=""
      className={classes}
    />
  );
}

function getPhoneButton(name, phone) {
  const { t } = useTranslation();

  if (phone) {
    return (
      <div className="mr-6">
        <div className="m-0 font-size-600">{`${t("call")} ${name}`}</div>
        <a
          role="button"
          href={"tel:" + phone}
          className="font-size-600 font-weight-700"
        >
          <span className="accessibility-visually-hidden">{t("call")}</span>
          {phone}
        </a>
      </div>
    );
  }
}

function getEmailButton(name, email) {
  const { t } = useTranslation();

  if (email) {
    return (
      <div className="mr-0">
        <div className="m-0 font-size-600">{`${t("email")} ${name}`}</div>
        <a
          role="button"
          href={"mailto:" + email}
          className="font-size-600 font-weight-700"
        >
          <span className="accessibility-visually-hidden">{t("email")}</span>
          {email}
        </a>
      </div>
    );
  }
}

export const contactPersonFragment = graphql`
  fragment contactPerson on DatoCmsContactPerson {
    id
    title
    subtitle
    person {
      ...person
    }
    model {
      apiKey
    }
    blockId
  }
`;
