import React from "react";
import SocialMediaIconLinkComponent from "./social-media-icon-link-component";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function FollowOnSocialMediaComponent({
  name,
  twitter,
  instagram,
  facebook,
  linkedin,
  youtube,
  website,
}) {
  const { t } = useTranslation();

  if (
    !twitter &&
    !instagram &&
    !facebook &&
    !linkedin &&
    !youtube &&
    !website
  ) {
    return "";
  }

  return (
    <div>
      <div className="font-size-600 font-weight-800">
        {t("follow")} {name} {t("on")}
      </div>
      <ul className="list-unstyled m-0 px-0 py-3 d-flex align-items-start justify-content-start">
        <SocialMediaIconLinkComponent
          platform="twitter"
          iconClasses="ti ti-brand-twitter"
          url={twitter}
        />
        <SocialMediaIconLinkComponent
          platform="instagram"
          iconClasses="ti ti-brand-instagram"
          url={instagram}
        />
        <SocialMediaIconLinkComponent
          platform="facebook"
          iconClasses="ti ti-brand-facebook"
          url={facebook}
        />
        <SocialMediaIconLinkComponent
          platform="linkedin"
          iconClasses="ti ti-brand-linkedin"
          url={linkedin}
        />
        <SocialMediaIconLinkComponent
          platform="youtube"
          iconClasses="ti ti-brand-youtube"
          url={youtube}
        />
        <SocialMediaIconLinkComponent
          platform="world"
          iconClasses="ti ti-world"
          url={website}
        />
      </ul>
    </div>
  );
}
