import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function InternshipQuote({ imageUrl, fullName, children }) {
  const { t } = useTranslation();

  return (
    <div className="row mb-5">
      <div className="d-flex flex-column flex-md-row">
        <blockquote className="m-0 pr-6 flex-grow-1">
          <div className="font-size-700 font-size-sm-500 font-size-md-600 py-4">
            <div className="rich-text">{children}</div>
          </div>
          <div className="color-gray-500">
            <span>—</span> {fullName}, {t("computer-science-student")}
          </div>
        </blockquote>
        <div className="mt-4 pt-2">
          <img
            className="m-0 w-120 h-120 overflow-hidden border-radius-circle"
            src={imageUrl}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
