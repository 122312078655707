import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function FooterWhatsappComponent() {
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      <div>{t("whatsapp")}</div>
      <a
        href="https://wa.me/3211265015"
        rel="noreferrer noopener"
        target="_blank"
        className="hover-color-quaternary-500 mt-1 font-size-500 font-weight-700"
      >
        <span className="accessibility-visually-hidden">
          {t("contact-aca-through-whatsapp")}
        </span>
        <i
          aria-hidden="true"
          className="ti ti-brand-whatsapp ml-0 font-size-700 font-weight-700"
        />
      </a>
    </div>
  );
}
