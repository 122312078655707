import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function FooterPhoneComponent() {
  const { t } = useTranslation();

  return (
    <div>
      <div>{t("phone")}</div>
      <a
        href="tel:003211265010"
        className="hover-color-quaternary-500 font-size-500 font-weight-700"
      >
        +32(0)11 26.50.10
      </a>
    </div>
  );
}
