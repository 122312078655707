import React from "react";
const imageUtil = require("../utils/image-util.js");
import { graphql } from "gatsby";

export default function ProcessBlock({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12 py-3 d-flex process">
            <div className="process__bar process__bar--4"></div>
            {getProcessStep(data.iconImage1, data.content1)}
            {getProcessStep(data.iconImage2, data.content2)}
            {getProcessStep(data.iconImage3, data.content3)}
            {getProcessStep(data.iconImage4, data.content4)}
          </div>
        </div>
      </div>
    </div>
  );
}

function getProcessStep(icon, content) {
  return (
    <div className="d-flex m-3">
      <div className="process__step-image w-92 h-92 overflow-hidden border-radius-circle d-flex justify-content-center align-items-center mb-3 flex-shrink-0">
        {getIcon(icon)}
      </div>
      <div className="ml-3 ml-lg-0">
        <div
          className="rich-text w-full mt-3"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
}

const getIcon = (blockIcon) => {
  return imageUtil.getImageElements(blockIcon, "w-48");
};

export const processVisualizationFragment = graphql`
  fragment processVisualization on DatoCmsProcessVisualization {
    id
    greyBackground
    iconImage1 {
      format
      gatsbyImageData
      url
    }
    content1
    iconImage2 {
      format
      gatsbyImageData
      url
    }
    content2
    iconImage3 {
      format
      gatsbyImageData
      url
    }
    content3
    iconImage4 {
      format
      gatsbyImageData
      url
    }
    content4
    model {
      apiKey
    }
    blockId
  }
`;
