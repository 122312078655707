import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function QuoteTile({ quoteEntry }) {
  const { t } = useTranslation();

  return (
    <li className="ais-InfiniteHits-item photo-quote hero hero--fit">
      <div className="picture position-relative">
        <div className="hero__background quote-entry"></div>
        <img
          src={`/images/quotes/${quoteEntry?.imageName}`}
          alt="Placeholder"
          className="w-max-full"
        />
      </div>
      <div className="hero__content w-full">
        <div className="container">
          <h3 className="color-white h2-styled">“{t(quoteEntry?.quote)}”</h3>
          <div className="color-white font-weight-700 font-size-700 line-height-md mb-5">
            {t(quoteEntry?.author)}
          </div>
        </div>
      </div>
    </li>
  );
}
