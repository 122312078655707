import React from "react";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function AudioComponent({ audioUrl, autoPlay }) {
  const { t } = useTranslation();

  return (
    <div className="audio-player">
      <AudioPlayer
        src={audioUrl}
        progressJumpStep={15000}
        customIcons={{
          rewind: RewindIcon(),
          forward: ForwardIcon(),
        }}
        autoPlay={autoPlay}
        autoPlayAfterSrcChange={false}
        defaultDuration={<div className="rhap_time rhap_total-time">00:00</div>}
        i18nAriaLabels={{
          player: t("audio-player"),
          progressControl: t("progress-control"),
          rewind: t("rewind"),
          play: t("play"),
          forward: t("forward"),
          volume: t("mute"),
          volumeMute: t("unmute-button"),
          volumeControl: t("volume-control"),
          pause: t("pause"),
        }}
      />
    </div>
  );

  function RewindIcon() {
    return (
      <svg viewBox="-2 0 32 32" aria-hidden="true" role="img" focusable="false">
        <g>
          <path
            d="M14,3.67864945 L14,0.855249454 C14,0.145249454 13.18,-0.254750546 12.6173,0.181249454 L7.0453,4.48404945 C6.83612267,4.64521863 6.71358038,4.89433382 6.71358038,5.15839945 C6.71358038,5.42246509 6.83612267,5.67158027 7.0453,5.83274945 L12.6173,10.1360495 C13.18,10.5712495 14,10.1720495 14,9.46124945 L14,6.77524945 C20.0047,6.77524945 24.8887,11.6372495 24.8887,17.6139495 C24.8887,23.5912495 20.0047,28.4532495 14,28.4532495 C7.9953,28.4532495 3.1113,23.5912495 3.1113,17.6140495 L0,17.6140495 C0,25.2986495 6.28,31.5500495 14,31.5500495 C21.72,31.5500495 28,25.2987495 28,17.6140495 C28,9.93004945 21.72,3.67864945 14,3.67864945"
            id="Path"
          />
          <path
            d="M11.6933594,23 L11.6933594,14.4570312 L11.5117188,14.4570312 L7.97851562,15.7226562 L7.97851562,17.0996094 L10,16.4726562 L10,23 L11.6933594,23 Z M17.3886719,23.1171875 C17.9785156,23.1171875 18.4902344,22.9970703 18.9238281,22.7568359 C19.3574219,22.5166016 19.6904297,22.1787109 19.9228516,21.7431641 C20.1552734,21.3076172 20.2714844,20.8144531 20.2714844,20.2636719 C20.2714844,19.3574219 20.0410156,18.6523438 19.5800781,18.1484375 C19.1191406,17.6445312 18.4824219,17.3925781 17.6699219,17.3925781 C17.2167969,17.3925781 16.7890625,17.5 16.3867188,17.7148438 L16.3867188,17.7148438 L16.5976562,15.8808594 L19.9902344,15.8808594 L19.9902344,14.46875 L15.2089844,14.46875 L14.7167969,18.8046875 L16.0644531,19.1386719 L16.2285156,18.9863281 C16.4785156,18.7753906 16.8046875,18.6699219 17.2070312,18.6699219 C17.6445312,18.6699219 17.9824219,18.7988281 18.2207031,19.0566406 C18.4589844,19.3144531 18.578125,19.6855469 18.578125,20.1699219 C18.578125,20.6738281 18.4746094,21.0634766 18.2675781,21.3388672 C18.0605469,21.6142578 17.765625,21.7519531 17.3828125,21.7519531 C17.0390625,21.7519531 16.7607422,21.6533203 16.5478516,21.4560547 C16.3349609,21.2587891 16.2109375,20.9824219 16.1757812,20.6269531 L16.1757812,20.6269531 L14.5,20.6269531 C14.515625,21.1113281 14.6513672,21.5429688 14.9072266,21.921875 C15.1630859,22.3007812 15.5117188,22.5947266 15.953125,22.8037109 C16.3945312,23.0126953 16.8730469,23.1171875 17.3886719,23.1171875 Z"
            id="15"
          />
        </g>
      </svg>
    );
  }

  function ForwardIcon() {
    return (
      <svg viewBox="-2 0 32 32" aria-hidden="true" role="img" focusable="false">
        <g>
          <path
            d="M14,3.67864945 L14,0.855249454 C14,0.145249454 13.18,-0.254750546 12.6173,0.181249454 L7.0453,4.48404945 C6.83612267,4.64521863 6.71358038,4.89433382 6.71358038,5.15839945 C6.71358038,5.42246509 6.83612267,5.67158027 7.0453,5.83274945 L12.6173,10.1360495 C13.18,10.5712495 14,10.1720495 14,9.46124945 L14,6.77524945 C20.0047,6.77524945 24.8887,11.6372495 24.8887,17.6139495 C24.8887,23.5912495 20.0047,28.4532495 14,28.4532495 C7.9953,28.4532495 3.1113,23.5912495 3.1113,17.6140495 L0,17.6140495 C0,25.2986495 6.28,31.5500495 14,31.5500495 C21.72,31.5500495 28,25.2987495 28,17.6140495 C28,9.93004945 21.72,3.67864945 14,3.67864945"
            id="Path"
            transform="translate(14.000000, 15.775025) scale(-1, 1) translate(-14.000000, -15.775025) "
          />
          <path
            d="M11.6933594,23 L11.6933594,14.4570312 L11.5117188,14.4570312 L7.97851562,15.7226562 L7.97851562,17.0996094 L10,16.4726562 L10,23 L11.6933594,23 Z M17.3886719,23.1171875 C17.9785156,23.1171875 18.4902344,22.9970703 18.9238281,22.7568359 C19.3574219,22.5166016 19.6904297,22.1787109 19.9228516,21.7431641 C20.1552734,21.3076172 20.2714844,20.8144531 20.2714844,20.2636719 C20.2714844,19.3574219 20.0410156,18.6523438 19.5800781,18.1484375 C19.1191406,17.6445312 18.4824219,17.3925781 17.6699219,17.3925781 C17.2167969,17.3925781 16.7890625,17.5 16.3867188,17.7148438 L16.3867188,17.7148438 L16.5976562,15.8808594 L19.9902344,15.8808594 L19.9902344,14.46875 L15.2089844,14.46875 L14.7167969,18.8046875 L16.0644531,19.1386719 L16.2285156,18.9863281 C16.4785156,18.7753906 16.8046875,18.6699219 17.2070312,18.6699219 C17.6445312,18.6699219 17.9824219,18.7988281 18.2207031,19.0566406 C18.4589844,19.3144531 18.578125,19.6855469 18.578125,20.1699219 C18.578125,20.6738281 18.4746094,21.0634766 18.2675781,21.3388672 C18.0605469,21.6142578 17.765625,21.7519531 17.3828125,21.7519531 C17.0390625,21.7519531 16.7607422,21.6533203 16.5478516,21.4560547 C16.3349609,21.2587891 16.2109375,20.9824219 16.1757812,20.6269531 L16.1757812,20.6269531 L14.5,20.6269531 C14.515625,21.1113281 14.6513672,21.5429688 14.9072266,21.921875 C15.1630859,22.3007812 15.5117188,22.5947266 15.953125,22.8037109 C16.3945312,23.0126953 16.8730469,23.1171875 17.3886719,23.1171875 Z"
            id="15"
          />
        </g>
      </svg>
    );
  }
}
