import { MetaDataAttributeType, MetaDataType } from "./SeoMetaTag";

function createMetaDataMetaTagObject(tag) {
  let metaDataMetaTag;

  if (tag?.attributes?.name) {
    metaDataMetaTag = {
      tagName: tag.tagName,
      type: MetaDataAttributeType.NAME,
      attributes: tag.attributes,
    };
  } else {
    metaDataMetaTag = {
      tagName: tag.tagName,
      type: MetaDataAttributeType.PROPERTY,
      attributes: tag.attributes,
    };
  }

  return metaDataMetaTag;
}

class SeoMetaDataFactory {
  static createMetaDataTags(data) {
    const metaDataObject = {
      title: "",
      description: "",
      metaDataTags: [],
    };

    data.tags.forEach((tag) => {
      switch (tag.tagName) {
        case MetaDataType.TITLE:
          if (tag.content) {
            metaDataObject.title = tag.content;
          }
          break;
        case MetaDataType.META:
          if (tag?.attributes?.name && tag.attributes.name === "description") {
            metaDataObject.description = tag.attributes.content;
          }
          metaDataObject.metaDataTags.push(createMetaDataMetaTagObject(tag));
          break;
        default:
          break;
      }
    });

    return metaDataObject;
  }
}

export default SeoMetaDataFactory;
