import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import TileBlock from "../building-blocks/tile-block";
import Layout from "../components/layout/layout";
import SocialMediaIconLinkComponent from "../components/social-media-icon-link-component";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsBioLinksPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function BioLinksPage({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="container">
          <div className="pt-4">
            <h1>{t("social-media-bio-links")}</h1>
            <div className="font-size-600 font-weight-800">
              {t("follow-aca-on")}
            </div>
            <div className="py-3 d-flex align-items-start justify-content-start">
              <SocialMediaIconLinkComponent
                platform="Twitter"
                iconClasses="ti ti-brand-twitter"
                url="https://twitter.com/acagroup_be"
              />
              <SocialMediaIconLinkComponent
                platform="Instagram"
                iconClasses="ti ti-brand-instagram"
                url="http://www.instagram.com/theacagroup"
              />
              <SocialMediaIconLinkComponent
                platform="Facebook"
                iconClasses="ti ti-brand-facebook"
                url="https://www.facebook.com/ACAGROUP.BE"
              />
              <SocialMediaIconLinkComponent
                platform="LinkedIn"
                iconClasses="ti ti-brand-linkedin"
                url="https://www.linkedin.com/company/the-aca-group"
              />
              <SocialMediaIconLinkComponent
                platform="Youtube"
                iconClasses="ti ti-brand-youtube"
                url="https://www.youtube.com/c/acaitsolutions/featured"
              />
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            {data.datoCmsBioLinksPage.content.blocks.map((tileBlock) => (
              <TileBlock key={tileBlock.id} data={tileBlock} />
            ))}
          </div>
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsBioLinksPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      content {
        blocks {
          ...tile
        }
      }
    }
  }
`;
