import React, { useState } from "react";
import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import LoadMoreButtonComponent, {
  LoadMoreContext,
} from "../components/load-more-button-component";
import { StaticImage } from "gatsby-plugin-image";
import { PodcastContext } from "../components/podcast/podcast-context";
import * as imageUtil from "../utils/image-util";
import PodcastEntryComponent from "../components/podcast/PodcastEntryComponent";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsPodcastPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function PodcastsOverviewPage({ data }) {
  const { t } = useTranslation();
  const [previousAmountOfEntriesShown, setPreviousAmountOfEntriesShown] =
    useState(-1);
  const [amountOfEntriesShown, setAmountOfEntriesShown] = useState(10);
  const [podcastEntryCollapseId, setpodcastEntryCollapseId] = useState("");
  const podcastsPage = data.datoCmsPodcastPage;
  const allPodcasts = data.allDatoCmsPodcast.nodes;

  const playButtonClickHandler = (id) => {
    if (podcastEntryCollapseId === id) {
      setpodcastEntryCollapseId("");
      return;
    }
    setpodcastEntryCollapseId(id);
  };

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="container mt-4">
          <div className="row d-flex">
            <div className="col-lg-6 py-3 d-flex flex-column">
              <div className="my-3">
                <div className="rich-text">
                  <h1>{podcastsPage.headTitle}</h1>
                  <h2>{podcastsPage.subtitle}</h2>
                  <p className="mt-3">{podcastsPage.description}</p>
                </div>
              </div>
              <div className="my-3 d-flex flex-column justify-content-end flex-grow-1">
                <a
                  href={podcastsPage.spotifyLink}
                  hidden={!podcastsPage.spotifyLink}
                  className="my-1"
                >
                  <div aria-hidden={true} className="mr-2">
                    <StaticImage
                      className="d-block h-full"
                      src="../images/spotify.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </div>
                  {t("listen-on-spotify")}
                  <i aria-hidden={true} className="ti ti-external-link" />
                </a>
                <a
                  href={podcastsPage.pocketcastsLink}
                  hidden={!podcastsPage.pocketcastsLink}
                  className="my-1"
                >
                  <div aria-hidden={true} className="mr-2">
                    <StaticImage
                      className="d-block h-full"
                      src="../images/pocketcasts.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </div>
                  {t("listen-on-pocketcasts")}
                  <i aria-hidden={true} className="ti ti-external-link" />
                </a>
                <a
                  href={podcastsPage.appleMusicLink}
                  hidden={!podcastsPage.appleMusicLink}
                  className="my-1"
                >
                  <div aria-hidden={true} className="mr-2">
                    <StaticImage
                      className="d-block h-full"
                      src="../images/apple-music.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </div>
                  {t("listen-on-applemusic")}
                  <i aria-hidden={true} className="ti ti-external-link" />
                </a>
              </div>
            </div>
            <div
              className={`col-lg-6 py-3 ${
                !podcastsPage.showImageMobile ? "d-none d-lg-block" : ""
              }`}
            >
              {imageUtil.getImageElements(podcastsPage.image, "w-full h-full")}
            </div>
            <div className="my-28 event-item">
              {getPodcastEntries(allPodcasts)}
              <LoadMoreContext.Provider
                value={{
                  amountOfEntriesShown,
                  setAmountOfEntriesShown,
                  setPreviousAmountOfEntriesShown,
                  length: allPodcasts.length,
                  renderAmount: 10,
                }}
              >
                <LoadMoreButtonComponent />
              </LoadMoreContext.Provider>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );

  function getPodcastEntries(podcasts) {
    let podcastEntries = [];

    for (let i = 0; i < amountOfEntriesShown && i < podcasts.length; i++) {
      podcastEntries.push(
        <PodcastContext.Provider
          value={{ podcastEntryCollapseId, playButtonClickHandler }}
        >
          <PodcastEntryComponent
            podcast={podcasts[i]}
            shouldFocus={i === previousAmountOfEntriesShown}
          />
        </PodcastContext.Provider>
      );
    }
    return podcastEntries;
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsPodcastPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      headTitle
      subtitle
      description
      spotifyLink
      pocketcastsLink
      appleMusicLink
      image {
        gatsbyImageData
        format
        url
      }
      showImageMobile
    }
    allDatoCmsPodcast(locale: $language, sort: { meta: { createdAt: DESC } }) {
      nodes {
        ...podcastFull
      }
    }
  }
`;
