import React from "react";
import { graphql } from "gatsby";

export default function SeparatorBlock() {
  return <div className="container my-6 border-top" />;
}

export const separatorFragment = graphql`
  fragment separator on DatoCmsSeparator {
    id
    model {
      apiKey
    }
  }
`;
