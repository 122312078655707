import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import FooterCopyrightComponent from "./footer-copyright-component";
import FooterSocialMediaComponent from "./footer-social-media-component";

export default function FooterBottomRowComponent() {
  return (
    <div className="py-3 font-size-300 border-top">
      <div className="row align-items-center">
        <FooterCopyrightComponent />
        <FooterSocialMediaComponent />
        <div className="py-3 col-lg-4 d-flex align-items center justify-content-start justify-content-lg-end">
          <StaticImage
            src="../../images/certified-logo.webp"
            alt="Great place to work certified logo"
            className="w-80 h-auto"
          />
        </div>
      </div>
    </div>
  );
}
