import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import RelatedBlogsBlock from "../building-blocks/related-blogs-block";
import Layout from "../components/layout/layout";
import {
  getBlogBlock,
  getSectionClassesWithoutPaddingTop,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import SEO from "../seo/seo";

const imageUtil = require("../utils/image-util.js");
const dateUtil = require("../hooks/date-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsBlog.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function BlogModel({ data, location }) {
  const blog = data.datoCmsBlog;
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  const relatedBlogs = {
    items:
      data.threeRelatedBlogs.nodes.length > 0
        ? data.threeRelatedBlogs.nodes
        : data.threeMostRecentBlogs.nodes,
  };

  return (
    <Layout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredArticleData() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        <section role="region" className="hero">
          <div className="picture position-relative">
            {imageUtil.getImageElements(
              blog.image,
              "w-max-full w-full h-full",
              true
            )}
          </div>
        </section>
        <section role="region" className="container pt-6">
          <div className="row">
            <div className="col-12">
              <Link
                to={
                  location?.state?.previous
                    ? `${location.state.previous}${window.location.search}`
                    : "/blog"
                }
                state={{
                  appliedFilter: location?.state?.appliedFilter,
                }}
                className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                <i aria-hidden={true} className="ti ti-chevron-left mr-2" />{" "}
                {location?.state?.previous || location?.search
                  ? t("back-to-search")
                  : t("back-to-blogs-overview")}
              </Link>
            </div>
          </div>
        </section>
        <section role="region">
          <div className="pt-4">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 offset-xl-0">
                  {getCategoryEntry(blog.primaryCategory.categoryName)}
                  {blog.secondaryCategory.map((category) =>
                    getCategoryEntry(category.categoryName)
                  )}
                  <div className="font-size-700 font-weight-700 mt-3 color-black-a-500">
                    <span>
                      {`${dateUtil.useDateFormatWithDefaultFormat(
                        blog.meta.createdAt
                      )} • ${blog.author.fullName}`}
                    </span>
                  </div>
                  <h1 className="mt-0">{blog.headTitle}</h1>
                  <div
                    className="font-size-600 mt-3"
                    dangerouslySetInnerHTML={{ __html: blog.preface }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section role="region">
          <div className="container container--master">
            <div className="row">
              <div className="col-md-12 col-xl-6 col-xxl-6">
                {blog.content.blocks.map((block, index) => {
                  return (
                    <section
                      role="region"
                      className={getSectionClassesWithoutPaddingTop(
                        blog.content.blocks,
                        index
                      )}
                      key={block.id}
                    >
                      {getBlogBlock(index, block)}
                    </section>
                  );
                })}
              </div>
              <div className="col-md-8 col-xl-4 col-xxl-4">
                {getRelatedBlogs(relatedBlogs)}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );

  function getStructuredArticleData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${blog.headTitle}",
      "image": [
        "${blog.coverImage?.url}"
       ],
      "datePublished": "${blog.meta.createdAt}",
      "dateModified": "${blog.meta.updatedAt}",
      "author": [{
          "@type": "Person",
          "name": "${blog.author.fullName}",
          "url": "https://acagroup.be/${language}/${
      language === "en" ? "author" : "auteur"
    }/${blog.author.pageSlug}"
        }]
    }`;
  }

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/blog"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${blog.headTitle}",
        "item": "https://www.acagroup.be/${language}/blog/${blog.pageSlug}"
      }]
    }`;
  }
}

function getRelatedBlogs(relatedBlogs) {
  return (
    <section role="complementary">
      <RelatedBlogsBlock data={relatedBlogs} />
    </section>
  );
}

function getCategoryEntry(categoryName) {
  return (
    <Link
      to={`/blog/?filter=${categoryName
        .replace("&", "$amp")
        .split(" ")
        .join("+")}`}
      href=""
      key={categoryName}
      className="picture__tag hover-bg-tertiary-500 hover-color-white hover-text-decoration-none color-tertiary-500 font-size-300 font-weight-700 p-1 mr-2"
    >
      {categoryName.toUpperCase()}
    </Link>
  );
}

export const query = graphql`
  query (
    $language: String!
    $originalId: String!
    $primaryCategoryId: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsBlog(originalId: { eq: $originalId }, locale: $language) {
      ...blogFull
    }
    threeMostRecentBlogs: allDatoCmsBlog(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: { originalId: { ne: $originalId } }
    ) {
      nodes {
        ...relatedBlogLink
      }
    }
    threeRelatedBlogs: allDatoCmsBlog(
      limit: 3
      locale: $language
      sort: { meta: { createdAt: DESC } }
      filter: {
        originalId: { ne: $originalId }
        primaryCategory: { id: { eq: $primaryCategoryId } }
      }
    ) {
      nodes {
        ...relatedBlogLink
      }
    }
    pageSlugs: datoCmsBlog(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
