import React from "react";

import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function VideoComponent({ url, thumbnailUrl, alt, classes }) {
  const { t } = useTranslation();

  return typeof navigator !== `undefined` ? (
    <div className={classes}>
      <Video
        controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
        poster={thumbnailUrl}
        copy={{
          play: `${t("play")} ${alt}`,
          pause: `${t("pause")} ${alt}`,
          mute: `${t("mute")} ${alt}`,
          unmute: `${t("unmute")} ${alt}`,
          volume: `${t("volume")} ${alt}`,
          fullscreen: `${t("view")} ${alt} ${t("fullscreen")}`,
          seek: `${t("seek")} ${alt}`,
        }}
      >
        <source
          src={url}
          type={url.endsWith(".mp4") ? "video/mp4" : "video/quicktime"}
        />
      </Video>
    </div>
  ) : (
    ""
  );
}
