import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const getImageElements = (imageData, classes, isHero, width) => {
  if (!imageData || !imageData.format) {
    return "";
  }
  if (imageData.format !== "svg") {
    const gatsbyImage = getImage(imageData);

    return (
      <GatsbyImage
        className={classes}
        alt={imageData.alt || ""}
        width={width}
        image={gatsbyImage}
        layout={isHero ? "fullWidth" : undefined}
        loading={isHero ? "eager" : "lazy"}
      />
    );
  }
  return (
    <img
      className={`image-svg ${classes}`}
      src={imageData.url}
      alt={imageData.alt || ""}
    />
  );
};
