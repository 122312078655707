import React from "react";
import { graphql } from "gatsby";

export default function VisualSummaryBlock({ data }) {
  return (
    <div
      className="py-2 visual-summary"
      {...(data.blockId ? { id: data.blockId } : {})}
    >
      <div className="container">
        <div
          className={`py-3 col-xl-6 offset-xl-3 ${data.title ? "" : "d-none"}`}
        >
          <h2 className="font-size-800 font-weight-700 my-0">{data.title}</h2>
        </div>
        <ul className="py-4 col-xl-6 offset-xl-3 list-style-none">
          {data.arguments.map((argument) => {
            return (
              <li className="d-flex align-items-start" key={argument.id}>
                <div className="w-44 h-44 border-radius-circle border d-flex align-items-center justify-content-center flex-shrink-0">
                  <i aria-hidden={true} className="ti ti-check" />
                </div>
                <div className="px-4 py-2 pt-1">
                  <div className="font-size-700 font-weight-700 mb-3">
                    {argument.title}
                  </div>
                  <div
                    className="rich-text"
                    dangerouslySetInnerHTML={{ __html: argument.text }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export const visualSummaryFragment = graphql`
  fragment visualSummary on DatoCmsVisualSummary {
    id
    title
    greyBackground
    arguments {
      title
      text
      id
    }
    blockId
    model {
      apiKey
    }
  }
`;
