import React from "react";
import SocialMediaIconLinkComponent from "../social-media-icon-link-component";

export default function FooterSocialMediaComponent() {
  return (
    <ul className="list-unstyled m-0 py-3 col-lg-4 d-flex align-items center justify-content-start justify-content-lg-center">
      <SocialMediaIconLinkComponent
        platform="Twitter"
        iconClasses="ti ti-brand-twitter mx-0"
        url="https://twitter.com/acagroup_be"
      />

      <SocialMediaIconLinkComponent
        platform="Instagram"
        iconClasses="ti ti-brand-instagram mx-0"
        url="https://www.instagram.com/theacagroup"
      />

      <SocialMediaIconLinkComponent
        platform="Facebook"
        iconClasses="ti ti-brand-facebook mx-0"
        url="https://www.facebook.com/ACAGROUP.BE"
      />
      <SocialMediaIconLinkComponent
        platform="LinkedIn"
        iconClasses="ti ti-brand-linkedin mx-0"
        url="https://www.linkedin.com/company/the-aca-group"
      />
      <SocialMediaIconLinkComponent
        platform="Youtube"
        iconClasses="ti ti-brand-youtube mx-0"
        url="https://www.youtube.com/c/acaitsolutions/featured"
      />
    </ul>
  );
}
