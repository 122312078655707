import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import Layout from "../components/layout/layout";
import { getBlock, getSectionClassesWithPaddingTop } from "../utils/block-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsCookiePolicyPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function CookiePolicyPage({ data }) {
  const { t } = useTranslation();

  return (
    <Layout>
      <main role="main" className="py-6">
        <section role="region">
          <div className="py-6">
            <div className="container">
              <div className="row py-3">
                <div className="col-md-6 justify-content-center">
                  <h1 className="my-0">{t("cookie-policy")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {data.datoCmsCookiePolicyPage.content.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                data.datoCmsCookiePolicyPage.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsCookiePolicyPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      content {
        blocks {
          ...titleBlock
          ...blocksWithIconsFour
          ...richText
          ...blocksWithIconsThree
          ...blocksWithImagesThree
          ...blocksWithIconsTwo
          ...quote
          ...author
          ...image
          ...contactPerson
          ...blockWithIconAndImage
          ...logosBlock
          ...separator
          ...visualSummary
          ...code
          ...button1Link
          ...button2Link
          ...blocksWithIconsTwoAndImageBlock
          ...processVisualization
          ...linkToService
          ...linkToModel3
          ...linkToModel1
          ...executableHtml
          ...hubspotForm
        }
      }
    }
  }
`;
