import * as React from "react";
import { useContext } from "react";
import { I18nextContext } from "gatsby-plugin-react-i18next";
import HubspotFormBlock from "../../building-blocks/hubspot-form-block";

export default function ContactForm() {
  const language = useContext(I18nextContext).language;

  const englishData = {
    id: "bf38c965-2286-4097-b690-2d452bb975ad",
    region: "eu1",
    portalId: "25145356",
    formId: "bf38c965-2286-4097-b690-2d452bb975ad",
    formScript: `<script>
  hbspt.forms.create({
    region: "eu1",
    portalId: "25145356",
    formId: "bf38c965-2286-4097-b690-2d452bb975ad",
});
</script>`,
  };

  const dutchData = {
    id: "e1c010a4-b271-4b58-bc6b-c13647956eed",
    region: "eu1",
    portalId: "25145356",
    formId: "e1c010a4-b271-4b58-bc6b-c13647956eed",
    formScript: `<script>
  hbspt.forms.create({
    region: "eu1",
    portalId: "25145356",
    formId: "e1c010a4-b271-4b58-bc6b-c13647956eed"
});
</script>`,
  };

  return language === "en" ? (
    <HubspotFormBlock data={englishData} />
  ) : (
    <HubspotFormBlock data={dutchData} />
  );
}
