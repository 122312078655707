import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/layout";
import { getBlock, getSectionClassesWithPaddingTop } from "../utils/block-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsSectorOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function IndustriesPage({ data }) {
  return (
    <Layout>
      <main role="main" className="pt-6">
        {data.datoCmsSectorOverviewPage.content.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                data.datoCmsSectorOverviewPage.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsSectorOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      content {
        blocks {
          ...titleBlock
          ...blocksWithIconsFour
          ...richText
          ...blocksWithIconsThree
          ...blocksWithImagesThree
          ...blocksWithIconsTwo
          ...quote
          ...author
          ...image
          ...contactPerson
          ...blockWithIconAndImage
          ...logosBlock
          ...separator
          ...visualSummary
          ...code
          ...button1Link
          ...button2Link
          ...blocksWithIconsTwoAndImageBlock
          ...processVisualization
          ...linkToService
          ...linkToModel3
          ...linkToModel1
          ...executableHtml
          ...hubspotForm
        }
      }
    }
  }
`;
