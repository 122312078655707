import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import Layout from "../components/layout/layout";
import {
  getBlock,
  getSectionClassesWithoutPaddingTop,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import SEO from "../seo/seo";

const imageUtil = require("../utils/image-util.js");
const dateUtil = require("../hooks/date-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsNews.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function NewsModel({ data, location }) {
  const news = data.datoCmsNews;
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <Layout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredArticleData() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        <section role="region" className="hero">
          <div className="picture position-relative">
            {imageUtil.getImageElements(
              news.image,
              "w-max-full w-full h-full",
              true
            )}
          </div>
        </section>
        <section role="region" className="container pt-6">
          <div className="row">
            <div className="col-12">
              <Link
                to={
                  location?.state?.previous
                    ? `${location.state.previous}${window.location.search}`
                    : `/${language === "nl" ? "nieuws" : "news"}`
                }
                state={{
                  appliedFilter: location?.state?.appliedFilter,
                }}
                className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                <i aria-hidden={true} className="ti ti-chevron-left mr-2" />{" "}
                {location?.state?.previous || location?.search
                  ? t("back-to-search")
                  : t("back-to-news-overview")}
              </Link>
            </div>
          </div>
        </section>
        <section role="region">
          <div className="pt-4">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 offset-xl-3">
                  {getCategoryEntry(news.primaryCategory.categoryName)}
                  {news.secondaryCategory.map((category) =>
                    getCategoryEntry(category.categoryName)
                  )}
                  <div className="font-size-700 font-weight-700 mt-3 color-black-a-500">
                    <span>
                      {dateUtil.useDateFormatWithDefaultFormat(
                        news.meta.createdAt
                      )}
                    </span>
                  </div>
                  <h1 className="mt-0">{news.headTitle}</h1>
                  <div
                    className="font-size-600 mt-3"
                    dangerouslySetInnerHTML={{ __html: news.preface }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {news.content.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithoutPaddingTop(
                news.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/${
      language === "en" ? "news" : "nieuws"
    }"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${news.headTitle}",
        "item": "https://www.acagroup.be/${language}/${
      language === "en" ? "news" : "nieuws"
    }/${news.pageSlug}"
      }]
    }`;
  }

  function getStructuredArticleData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${news.headTitle}",
      "image": [
        "${news.image.url}"
       ],
      "datePublished": "${news.meta.createdAt}",
      "dateModified": "${news.meta.updatedAt}"
    }`;
  }
}

function getCategoryEntry(categoryName) {
  const language = useContext(I18nextContext).language;

  return (
    <Link
      to={`/${language === "nl" ? "nieuws" : "news"}/?filter=${categoryName
        .replace("&", "$amp")
        .split(" ")
        .join("+")}`}
      href=""
      key={categoryName}
      className="picture__tag hover-bg-tertiary-500 hover-color-white hover-text-decoration-none color-tertiary-500 font-size-300 font-weight-700 p-1 mr-2"
    >
      {categoryName.toUpperCase()}
    </Link>
  );
}

export const query = graphql`
  query ($language: String!, $originalId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsNews(originalId: { eq: $originalId }, locale: $language) {
      ...newsFull
    }
    pageSlugs: datoCmsNews(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
