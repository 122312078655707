export default class QuoteEntry {
  static quote_1 = new QuoteEntry("quote-1", "quote-1-author", "quote1.png");
  static quote_2 = new QuoteEntry("quote-2", "quote-2-author", "quote2.png");
  static quote_4 = new QuoteEntry("quote-4", "quote-4-author", "quote4.png");
  static quote_5 = new QuoteEntry("quote-5", "quote-5-author", "quote5.png");
  static quote_6 = new QuoteEntry("quote-6", "quote-6-author", "quote6.png");
  static quote_7 = new QuoteEntry("quote-7", "quote-7-author", "quote7.png");
  static quote_8 = new QuoteEntry("quote-8", "quote-8-author", "quote8.png");
  static quote_9 = new QuoteEntry("quote-9", "quote-9-author", "quote9.png");
  static quote_10 = new QuoteEntry(
    "quote-10",
    "quote-10-author",
    "quote10.png"
  );

  constructor(quote, author, imageName) {
    this.quote = quote;
    this.author = author;
    this.imageName = imageName;
  }

  static getEntry(index) {
    switch (index) {
      case 1:
        return this.quote_1;
      case 2:
        return this.quote_2;
      case 3:
        return this.quote_4;
      case 4:
        return this.quote_5;
      case 5:
        return this.quote_6;
      case 6:
        return this.quote_7;
      case 7:
        return this.quote_8;
      case 8:
        return this.quote_9;
      case 9:
        return this.quote_10;
    }
  }
}
