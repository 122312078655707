import { useWindowWidth } from "@react-hook/window-size";
import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/layout/layout";
import LoadMoreButtonComponent, {
  LoadMoreContext,
} from "../components/load-more-button-component";
import SEO from "../seo/seo";

const imageUtil = require("../utils/image-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsNewsOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function NewsPage({ data, location }) {
  const allNewsItems = data.allDatoCmsNews.nodes;
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();
  const filterRef = useRef(null);
  const openFilterButtonRef = useRef(null);
  const windowWidth = useWindowWidth();
  const [previousAmountOfEntriesShown, setPreviousAmountOfEntriesShown] =
    useState(-1);
  const [amountOfEntriesShown, setAmountOfEntriesShown] = useState(15);
  const [filteredNewsItems, setFilteredNewsItems] = useState(allNewsItems);
  const [filterOpened, setFilterOpened] = useState(false);
  const [filter, setFilter] = useState("");
  const newsCategories = getNewsItemsCategories(allNewsItems);

  let firstNewNewsItem = null;
  let news = filteredNewsItems.filter((news) => news.highlightItem === false);

  useEffect(() => {
    firstNewNewsItem?.focus();
  }, [previousAmountOfEntriesShown]);

  let highlightedNews = filteredNewsItems.filter(
    (news) => news.highlightItem === true
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentFilter = urlParams.get("filter");

    location?.state?.appliedFilter
      ? setFilter(location.state.appliedFilter)
      : "";
    window.location.hash
      ? setFilter(decodeURI(window.location.hash).replace("#", ""))
      : "";

    if (currentFilter) {
      let currentFilterParts = currentFilter.split(" ");
      let transformedFilterParts = [];

      currentFilterParts.forEach((part) => {
        if (part === "$amp") {
          part = "&";
        }
        transformedFilterParts.push(part);
      });
      setFilter(transformedFilterParts.join(" "));
    }
  }, []);

  useEffect(() => {
    setFilteredNewsItems(
      allNewsItems.filter(
        (newsItem) =>
          getNewsItemCategories(newsItem).includes(filter) || filter === ""
      )
    );

    if (filterOpened) {
      setFilterOpened(!filterOpened);
    }
  }, [filter]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        filterOpened &&
        filterRef.current !== null &&
        !filterRef.current.contains(e.target) &&
        openFilterButtonRef.current !== null &&
        !openFilterButtonRef.current.contains(e.target)
      ) {
        setFilterOpened(!filterOpened);
        document.body.classList = "";
      }
    };

    if (filterOpened) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [filterOpened]);

  return (
    <Layout>
      <main role="main" className="py-6">
        <div className="container">
          <div className="my-0 pb-0 py-xl-0 position-xl-relative">
            <div className="row">
              <h1 className="w-auto d-inline m-0 col-10">
                <span className="color-secondary-500">{filter} </span>
                {filter ? t("news").toLowerCase() : t("news")}
              </h1>
              {getOpenFilterButton()}
            </div>
            {getFilter()}
          </div>
        </div>
        <div className="py-2">
          <div className="container">
            {highlightedNews.map((highlightedNewsItem) =>
              getHighlightedNews(highlightedNewsItem)
            )}
            <ul className="row list-style-none">{getNewsEntries()}</ul>
            <LoadMoreContext.Provider
              value={{
                amountOfEntriesShown,
                setAmountOfEntriesShown,
                setPreviousAmountOfEntriesShown,
                length: news.length,
              }}
            >
              <LoadMoreButtonComponent></LoadMoreButtonComponent>
            </LoadMoreContext.Provider>
          </div>
        </div>
        {getMobileOverlay()}
      </main>
    </Layout>
  );

  function getOpenFilterButton() {
    return (
      <div
        className="col-12 quick-filter-button col-sm-2 d-flex justify-content-end align-items-center"
        ref={openFilterButtonRef}
      >
        <button
          onClick={(event) => {
            event.preventDefault();
            setFilterOpened(!filterOpened);
            if (windowWidth < 1200) {
              document.body.className = "overflow-hidden";
            }
          }}
          aria-expanded={filterOpened}
          className="btn quick-filter-button font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
        >
          <i aria-hidden={true} className="ti ti-filter font-size-700 mr-3" />
          Filter posts
        </button>
      </div>
    );
  }

  function getFilterOptions() {
    return newsCategories.map((category) => {
      return (
        <li key={category} className="my-1">
          <button
            onClick={(event) => {
              event.preventDefault();
              setFilter(category);
              document.body.classList = "";
              window.location.hash = `#${encodeURI(category)}`;
            }}
            className={`color-primary-500 w-auto d-block pb-1 ${
              filter && category === filter
                ? "font-weight-800"
                : "font-weight-400"
            }`}
          >
            {category}
          </button>
        </li>
      );
    });
  }

  function getDesktopFilterHeader() {
    if (windowWidth >= 1200) {
      return (
        <div className="py-3 border-bottom-dark">
          <button
            className="font-weight-400 color-primary-500"
            onClick={(event) => {
              event.preventDefault();
              setFilter("");
            }}
          >
            {t("all-news")}
          </button>
        </div>
      );
    }
  }

  function getMobileOverlay() {
    if (windowWidth < 1200 && filterOpened) {
      return <div className="mobile-filter__overlay" />;
    }
  }

  function getMobileFilterHeader() {
    if (windowWidth < 1200) {
      return (
        <div className="row py-3 border-bottom-dark">
          <button
            className="col-10 d-flex color-primary-500"
            onClick={(event) => {
              event.preventDefault();
              setFilter("");
              setFilterOpened(!filterOpened);
              document.body.classList = "";
            }}
          >
            {t("all-news")}
          </button>
          <button
            className="font-weight-700 font-size-600 color-black col-2 align-items-center justify-content-end"
            onClick={(event) => {
              event.preventDefault();
              setFilterOpened(!filterOpened);
              document.body.classList = "";
            }}
          >
            <span className="accessibility-visually-hidden">
              {t("close-filters")}
            </span>
            <i className="ti ti-x font-size-700" />
          </button>
        </div>
      );
    }
  }

  function getFilter() {
    if (filterOpened) {
      return (
        <div className="d-flex align-items-center line-height-normal position-xl-absolute w-auto right-xl-0">
          <div
            ref={filterRef}
            className="dropdown-content position-fixed position-xl-relative z-index-xl-2 mobile-filter top-0 horizontal-center w-full shadow-lg filter-background border border-2 border-secondary-500 border-radius-lg"
          >
            <div className="px-3 py-1 w-min-320">
              <div className="form-group">
                <form role="form" className="pb-2">
                  <div className="">
                    {getDesktopFilterHeader()}
                    {getMobileFilterHeader()}
                    <ul className="px-0 mb-0 mt-3 list-style-none">
                      {getFilterOptions()}
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function getNewsEntries() {
    let newsEntries = [];

    for (let i = 0; i < amountOfEntriesShown && i < news.length; i++) {
      newsEntries.push(
        <li
          data-testid="newsEntry"
          className="col-md-6 col-lg-4 py-3"
          key={news[i].id}
          tabIndex="0"
          ref={(newsItem) => {
            if (i === previousAmountOfEntriesShown) {
              firstNewNewsItem = newsItem;
            }
          }}
        >
          <div className="py-4 border-radius-lg h-full">
            <Link
              to={`/${language === "nl" ? "nieuws" : "news"}/${
                news[i].pageSlug
              }`}
              className="flex-column w-full"
            >
              <div className="mb-3 w-full" aria-hidden={true}>
                <div className="picture position-relative">
                  {imageUtil.getImageElements(news[i].coverImage, "w-full")}
                  <div className="picture__cover">
                    <i className="ti ti-arrow-narrow-right font-size-900 color-white" />
                  </div>
                  <span className="picture__tag bg-white color-tertiary-500 font-size-300 font-weight-700 p-1 px-2 m-3 ">
                    {news[i].primaryCategory.categoryName}
                  </span>
                </div>
              </div>
              <div className="w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
                {news[i].headTitle}
              </div>
            </Link>
            <div className="mb-3 w-full">
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{ __html: news[i].excerpt }}
              />
            </div>
          </div>
        </li>
      );
    }

    return newsEntries;
  }

  function getHighlightedNews(news) {
    return (
      <div className="row align-items-center" key={news.id}>
        <Link
          to={`/${language === "nl" ? "nieuws" : "news"}/${news.pageSlug}`}
          className="col-lg-6 py-3 d-lg-none"
          aria-label={news.headTitle}
        >
          {getHighlightedImageDisplay(news)}
        </Link>
        <div className="col-lg-6 py-3">
          <div className="my-3">
            <div
              onClick={(e) => {
                e.preventDefault();
                setFilter(news.primaryCategory.categoryName);
              }}
              className="mb-0 color-tertiary-500 font-weight-700"
            >
              <span className="mb-0 color-tertiary-500 font-weight-700 hover-bg-tertiary-500 hover-color-white hover-text-decoration-none p-1">
                {news.primaryCategory.categoryName.toUpperCase()}
              </span>
            </div>
            <Link
              to={`/${language === "nl" ? "nieuws" : "news"}/${news.pageSlug}`}
            >
              <h2 className="mb-0">{news.headTitle}</h2>
            </Link>
            <div
              className="rich-text mt-08 mb-48"
              dangerouslySetInnerHTML={{ __html: news.excerpt }}
            />
          </div>
        </div>
        <Link
          to={`/${language === "nl" ? "nieuws" : "news"}/${news.pageSlug}`}
          className="col-lg-6 py-3 d-none d-lg-block"
          aria-label={news.headTitle}
        >
          {getHighlightedImageDisplay(news)}
        </Link>
      </div>
    );
  }

  function getHighlightedImageDisplay(news) {
    return (
      <div className="picture position-relative" data-testid="image">
        <div className="w-full">
          {imageUtil.getImageElements(
            news.coverImage,
            "picture__image--highlighted d-flex"
          )}
        </div>
        <div className="picture__cover">
          <i
            aria-hidden={true}
            className="ti ti-arrow-narrow-right font-size-900 color-white"
          />
        </div>
      </div>
    );
  }

  function getNewsItemsCategories(newsItems) {
    const primaryCategories = [];

    newsItems.map((newsItem) => {
      primaryCategories.push(newsItem.primaryCategory.categoryName);
    });

    return [...new Set(primaryCategories)].sort();
  }

  function getNewsItemCategories(newsItem) {
    const categories = [];

    categories.push(newsItem.primaryCategory.categoryName);
    newsItem.secondaryCategory?.map((category) => {
      categories.push(category.categoryName);
    });

    return categories;
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsNewsOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
    }
    allDatoCmsNews(locale: $language, sort: { meta: { createdAt: DESC } }) {
      nodes {
        ...newsLong
      }
    }
    primaryCategories: allDatoCmsNews(locale: $language) {
      distinct(field: { primaryCategory: { categoryName: SELECT } })
    }
  }
`;
