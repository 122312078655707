import React, { useContext } from "react";
import { graphql } from "gatsby";
import { getPageSlugs } from "../utils/page-slug-util";
import Layout from "../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import * as imageUtil from "../utils/image-util";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import AudioComponent from "../components/audio/audio-component";
import { getBlock, getSectionClassesWithPaddingTop } from "../utils/block-util";
import SEO from "../seo/seo";

const dateUtil = require("../hooks/date-util");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsPodcast.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function PodcastPageModel({ data, location }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const podcast = data.datoCmsPodcast;

  return (
    <Layout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        <div className="container mt-4">
          <div className="row d-flex">
            <div className="col-12">
              <Link
                to={
                  location?.state?.previous
                    ? `${location.state.previous}${window.location.search}`
                    : "/podcast"
                }
                className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                <i aria-hidden={true} className="ti ti-chevron-left mr-2" />
                {location?.state?.previous || location?.search
                  ? t("back-to-search")
                  : t("back-to-podcast-overview")}
              </Link>
            </div>
            <div className="col-lg-6 py-3 d-flex flex-column">
              <div className="my-3">
                <div className="rich-text">
                  <h1>{podcast.title}</h1>
                  <span className="podcast__subtitle">
                    {`${t("aired-on")} ${dateUtil.useDateFormat(
                      podcast.date,
                      "DD MMMM YYYY"
                    )} - ${podcast.duration} min`}
                  </span>
                  <p className="mt-3">{podcast.description}</p>
                </div>
              </div>
              <div className="my-3 d-flex flex-column justify-content-end flex-grow-1">
                <a
                  href={podcast.spotifyLink}
                  hidden={!podcast.spotifyLink}
                  className="my-1"
                >
                  <div aria-hidden={true} className="mr-2">
                    <StaticImage
                      className="d-block h-full"
                      src="../images/spotify.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </div>
                  {t("listen-on-spotify")}
                  <i aria-hidden={true} className="ti ti-external-link" />
                </a>
                <a
                  href={podcast.pocketcastsLink}
                  hidden={!podcast.pocketcastsLink}
                  className="my-1"
                >
                  <div aria-hidden={true} className="mr-2">
                    <StaticImage
                      className="d-block h-full"
                      src="../images/pocketcasts.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </div>
                  {t("listen-on-pocketcasts")}
                  <i aria-hidden={true} className="ti ti-external-link" />
                </a>
                <a
                  href={podcast.appleMusicLink}
                  hidden={!podcast.appleMusicLink}
                  className="my-1"
                >
                  <div aria-hidden={true} className="mr-2">
                    <StaticImage
                      className="d-block h-full"
                      src="../images/apple-music.svg"
                      alt=""
                      height={20}
                      width={20}
                    />
                  </div>
                  {t("listen-on-applemusic")}
                  <i aria-hidden={true} className="ti ti-external-link" />
                </a>
              </div>
            </div>
            <div
              className={`col-lg-6 py-3 ${
                !podcast.showImageMobile ? "d-none d-lg-block" : ""
              }`}
            >
              {imageUtil.getImageElements(podcast.image, "w-full h-full")}
            </div>
          </div>
          <div className="mt-4">
            <AudioComponent audioUrl={podcast.mp3.url} />
          </div>
        </div>
        {podcast.content.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                podcast.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/podcast"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${podcast.title}",
        "item": "https://www.acagroup.be/${language}/podcast/${podcast.pageSlug}"
      }]
    }`;
  }
}

export const query = graphql`
  query ($language: String!, $originalId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsPodcast(originalId: { eq: $originalId }, locale: $language) {
      ...podcastFull
    }
    pageSlugs: datoCmsPodcast(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
