import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

export default function InternshipsTitleBlock({ email }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <div className="py-2 title-block" id="internships-title-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 py-1">
            <div className="my-3">
              <h1 className="mb-3 h2-styled">
                {t("internships-titleblock-title")}
              </h1>
              <h2 className="mb-3 h3-styled color-gray-400">
                {t("internships-titleblock-subtitle")}
              </h2>
              <p className="mb-3 ">
                {t("internships-titleblock-description-1")}
                <strong>{t("internships-titleblock-description-cat1")}</strong>
                {t("internships-titleblock-description-2")}
                <strong>{t("internships-titleblock-description-cat2")}</strong>
                {t("internships-titleblock-description-3")}
              </p>
            </div>
            <div className="my-3" data-testid="buttonsDiv">
              <Link
                className="btn color-white hover-color-white bg-primary-500 hover-bg-primary-700 transition border-radius-pill mr-3"
                to={
                  language === "nl"
                    ? "/nl/jobs?refinementList%5BjobTypeCategories%5D%5B0%5D=Stage"
                    : "/en/careers?refinementList%5BjobTypeCategories%5D%5B0%5D=Internship"
                }
              >
                {t("show-internships")}
              </Link>
              <a
                data-testid="link"
                className="align-items-center"
                href={`mailto:${email}`}
              >
                {t("send-email")}
                <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 py-3">
            <div className="w-full d-lg-block d-none">
              <StaticImage src="../../images/intern1.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
