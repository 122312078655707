import React from "react";

export let MetaDataType;
(function (MetaDataType) {
  MetaDataType["TITLE"] = "title";
  MetaDataType["META"] = "meta";
})(MetaDataType || (MetaDataType = {}));

export let MetaDataAttributeType;
(function (MetaDataAttributeType) {
  MetaDataAttributeType["PROPERTY"] = "property";
  MetaDataAttributeType["NAME"] = "name";
})(MetaDataAttributeType || (MetaDataAttributeType = {}));

function SeoMetaTag({ metaTag }) {
  return (
    <>
      {metaTag?.type === MetaDataAttributeType.PROPERTY && (
        <meta
          data-testid="meta-tag"
          property={metaTag?.attributes?.property}
          content={metaTag?.attributes?.content}
        />
      )}
      {metaTag?.type === MetaDataAttributeType.NAME && (
        <meta
          data-testid="meta-tag"
          name={metaTag?.attributes?.name}
          content={metaTag?.attributes?.content}
        />
      )}
    </>
  );
}

export default SeoMetaTag;
