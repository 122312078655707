import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useContext } from "react";
import LinkToModel3Block from "../building-blocks/link-to-model-3-block";
import Layout from "../components/layout/layout";
import { getBlock, getSectionClassesWithPaddingTop } from "../utils/block-util";
import { useDateIsTodayOrAfterToday } from "../hooks/date-util";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsHomepage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function IndexPage({ data }) {
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();

  const events = {
    items: getAllUpcomingEvents(data),
  };
  const news = {
    items: data.allDatoCmsNews.nodes,
  };
  const blogPosts = {
    items: data.allDatoCmsBlog.nodes,
  };

  return (
    <Layout>
      <main role="main" className="pt-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredSearchData() }}
        />
        {getHeroBanner()}
        {get3BlocksWithIcons()}
        {data.datoCmsHomepage.content.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithPaddingTop(
                data.datoCmsHomepage.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
        {events.items.length > 0 && (
          <section role="region" className="pb-56 background">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 offset-xl-0">
                  <div className="d-flex flex-column">
                    <div className="heading">
                      <h2 className="mb-0">{t("upcoming-events")}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LinkToModel3Block data={events} />
            <div className="d-flex justify-content-center pb-4">
              <Link
                to={`/events`}
                className="btn font-weight-600 hover-bg-transparent color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
              >
                {t("view-all-events")}
                <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
              </Link>
            </div>
          </section>
        )}
        <section
          role="region"
          className={"mt-56" + (events.items.length === 0 ? " background" : "")}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-6 offset-xl-0">
                <div className="d-flex flex-column">
                  <div className="heading">
                    <h2 className="mb-0">{t("latest-news")}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LinkToModel3Block data={news} />
          <div className="d-flex justify-content-center pb-4">
            <Link
              to={`/${language === "nl" ? "nieuws" : "news"}`}
              className="btn font-weight-600 hover-bg-transparent color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
            >
              {t("view-all-news")}
              <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
            </Link>
          </div>
        </section>
        <section
          role="region"
          className={
            "mt-56 last" + (events.items.length > 0 ? " background" : "")
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-6 offset-xl-0">
                <div className="d-flex flex-column">
                  <div className="heading">
                    <h2 className="mb-0">{t("latest-blogposts")}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LinkToModel3Block data={blogPosts} />
          <div className="d-flex justify-content-center font-size-600">
            <Link
              to="/blog"
              className="btn font-weight-600 hover-bg-transparent color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
            >
              {t("discover-all-our-blogposts")}
              <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  );

  function getStructuredSearchData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.acagroup.be/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://acagroup.be/${language}/search?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    }`;
  }

  function getHeroBanner() {
    return (
      <section role="region" className="hero hero--fit">
        <div className="picture d-flex">
          <StaticImage
            src="../images/hero-homepage.png"
            alt=""
            imgClassName="w-max-full"
            layout="fullWidth"
            loading="eager"
          />
        </div>
        <div className="hero__content">
          <div className="container">
            <h1 className="color-white">
              {`${t("aca-navigates-you")} `}
              <em>{t("to")} </em>&<em className="italic"> {t("in")} </em>
              {t("the-future")}
            </h1>
            <div className="color-white font-weight-700 font-size-700 line-height-md">
              {t("we-empower-people-worldwide-through")}
              <br />
              {t("innovation-technology-and-knowledge")}
            </div>
            <div className="my-60 pt-3">
              <Link
                to="/services"
                className="d-inline-flex justify-content-center font-weight-600 font-size-500 font-secondary btn border border-white bg-transparent hover-bg-white focus-bg-primary-700 color-white hover-color-quaternary-500 border-radius-pill"
              >
                {t("have-a-look-at-our-services")}
                <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function get3BlocksWithIcons() {
    const iconNotJustSustainable = (
      <img
        src="/images/junction.svg"
        alt=""
        className="h-32 w-32 line-height-1 d-inline-block"
        width="32"
        height="32"
      />
    );
    const titleNotJustSustainable = t("not-just-sustainable");
    const descriptionNotJustSustainable = (
      <p>
        {t("taking-care-of-our")}
        <span className="font-weight-700">
          {t("teams-talents-society-environment")}
        </span>
        {t("and-the")}
        <span className="font-weight-700">{t("economy")}</span>
        {t("we-are-commited-to-the-17-international")}{" "}
        <a
          href={
            language === "nl"
              ? "https://www.acagroup.be/nl/services/duurzaamheid-diensten-strategie"
              : "https://www.acagroup.be/en/services/sustainability-services-strategies"
          }
          target="_blank"
          rel="noreferrer noopener"
          className="d-inline"
        >
          {t("un-sustainable-development-goals")}.
        </a>
      </p>
    );
    const iconGreatPlaceToWork = (
      <i aria-hidden={true} className="ti ti-star font-size-800" />
    );
    const titleGreatPlaceToWork = t("great-place-to-work");
    const descriptionGreatPlaceToWork = (
      <p>
        {`${t("aca-has-been-officially-nominated-as-a")}`}{" "}
        <a
          href="https://www.greatplacetowork.be/en/"
          className="d-inline"
          target="_blank"
          rel="noreferrer noopener"
        >
          {`${t("great-place-to-work")}.`}
        </a>
        {t(
          "helping-people-access-the-full-potential-of-their-future-is-the-key-ingredient-of-their-future-is-the-key-ingredient-of-our-company-culture"
        )}{" "}
        <Link
          className="d-inline"
          to={`/${language === "nl" ? "jobs" : "careers"}`}
        >
          {t("want-to-help-us-write-the-next-chapter")}
        </Link>
      </p>
    );

    const iconCustomerCentricity = (
      <i aria-hidden={true} className="ti ti-heart font-size-800" />
    );
    const titleCustomerCentricity = t("customer-centricity");
    const descriptionCustomerCentricity = (
      <p>
        {language === "nl" ? t("our-slogan") : ""}We want to aim{" "}
        <a
          href={
            language === "nl"
              ? "https://www.acagroup.be/nl/aca-as-a-company"
              : "https://www.acagroup.be/en/aca-as-a-company"
          }
          className="d-inline"
          aria-label="above customer expectations"
        >
          <span className="font-weight-700">A</span>bove{" "}
          <span className="font-weight-700">C</span>ustomer Expect
          <span className="font-weight-700">A</span>tions
        </a>{" "}
        (ACA). {t("inspiring-and-accelerating")}
      </p>
    );

    return (
      <div className="py-2 mt-5">
        <div className="container">
          <ul className="row list-style-none">
            {getGreyBlock(
              iconNotJustSustainable,
              titleNotJustSustainable,
              descriptionNotJustSustainable
            )}
            {getGreyBlock(
              iconGreatPlaceToWork,
              titleGreatPlaceToWork,
              descriptionGreatPlaceToWork
            )}
            {getGreyBlock(
              iconCustomerCentricity,
              titleCustomerCentricity,
              descriptionCustomerCentricity
            )}
          </ul>
        </div>
      </div>
    );
  }

  function getGreyBlock(icon, title, description) {
    return (
      <li className="col-md-6 col-lg-4 py-3 px-3" key={title}>
        <div className="p-4 border-radius-lg h-full background">
          <div className="mb-3">{icon}</div>
          <div className="font-size-700 line-height-sm font-weight-700 mb-3">
            <div className="rich-text">
              <h2 className="h3-styled">{title}</h2>
            </div>
          </div>
          <div className="mb-3">
            <div className="rich-text">{description}</div>
          </div>
        </div>
      </li>
    );
  }
}

function getAllUpcomingEvents(data) {
  return data.allDatoCmsEvent.nodes
    .filter((event) => isUpcomingEvent(event))
    .sort((event1, event2) => Date.parse(event1.date) > Date.parse(event2.date))
    .slice(0, 3);
}

function isUpcomingEvent(event) {
  if (event.untilDate == null) {
    return useDateIsTodayOrAfterToday(event.date);
  } else {
    return useDateIsTodayOrAfterToday(event.untilDate);
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsHomepage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      content {
        blocks {
          ...titleBlock
          ...blocksWithIconsFour
          ...richText
          ...blocksWithIconsThree
          ...blocksWithImagesThree
          ...blocksWithIconsTwo
          ...quote
          ...author
          ...image
          ...contactPerson
          ...blockWithIconAndImage
          ...logosBlock
          ...separator
          ...visualSummary
          ...code
          ...button1Link
          ...button2Link
          ...blocksWithIconsTwoAndImageBlock
          ...processVisualization
          ...linkToService
          ...linkToModel3
          ...linkToModel1
          ...executableHtml
          ...hubspotForm
        }
      }
    }
    allDatoCmsNews(
      locale: $language
      sort: { meta: { createdAt: DESC } }
      limit: 3
    ) {
      nodes {
        ...newsLink
      }
    }
    allDatoCmsBlog(
      locale: $language
      sort: { meta: { createdAt: DESC } }
      limit: 3
    ) {
      nodes {
        ...blogLink
      }
    }
    allDatoCmsEvent(locale: $language) {
      nodes {
        ...eventLink
      }
    }
  }
`;
