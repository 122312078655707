import React from "react";
import { graphql } from "gatsby";
import VideoComponent from "../components/video/video-component";

const imageUtil = require("../utils/image-util.js");

export default function ImageBlock({ data }) {
  return (
    <div className="py-2" {...(data.blockId ? { id: data.blockId } : {})}>
      <div className="container">
        <div className="row">
          <div className={getBlockAlignment(data.blockAlignment)}>
            <div
              className={
                "d-flex flex-column justify-content-center " +
                getAlignment(data.alignment)
              }
            >
              {data.mediaType === "image" && data.image.gatsbyImageData ? (
                <div>
                  {getImageWithOptionalLink(data)}
                  {data.imageDescription ? (
                    <div
                      className="rich-text"
                      dangerouslySetInnerHTML={{
                        __html: data.imageDescription,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {data.mediaType === "video" && data.image.video ? (
                <VideoComponent
                  classes={getImageClasses(data.width)}
                  url={data.image.video.mp4Url}
                  thumbnailUrl={data.image.video.thumbnailUrl}
                  alt={data.image.alt}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getBlockAlignment(alignment) {
  switch (alignment) {
    case "Full":
      return "col-xl-12 offset-xl-0";
    case "Left":
      return "col-xl-6 offset-xl-0";
    case "Right":
      return "col-xl-6 offset-xl-6";
    default:
      return "";
  }
}

const getImageWithOptionalLink = (data) => {
  if (data.linkUrl) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={data.linkUrl}
        target={data.linkOpenInNewTab ? "_blank" : "_self"}
        rel={data.linkOpenInNewTab && "noreferrer noopener"}
      >
        {getImage(data)}
      </a>
    );
  }

  return getImage(data);
};

const getImage = (data) => {
  return imageUtil.getImageElements(
    data.image,
    getImageClasses(data.width),
    false,
    data.width === "full width" ? "" : data.width.replace("px", "")
  );
};

const getAlignment = (alignment) => {
  switch (alignment) {
    case "left":
      return "align-items-start";
    case "center":
      return "align-items-center";
    case "right":
      return "align-items-end";
  }
};

const getImageClasses = (width) => {
  let classes = "w-full";

  switch (width) {
    case "320px":
      return classes + " w-lg-320";
    case "360px":
      return classes + " w-lg-360";
    case "400px":
      return classes + " w-lg-400";
    case "600px":
      return classes + " w-lg-600";
    case "800px":
      return classes + " w-lg-800";
    case "1000px":
      return classes + " w-lg-1000";
    case "full width":
      return "w-max-full";
    default:
      return classes + " w-lg-320";
  }
};

export const imageFragment = graphql`
  fragment image on DatoCmsImage {
    id
    alignment
    mediaType
    imageDescription
    linkOpenInNewTab
    linkUrl
    width
    greyBackground
    model {
      apiKey
    }
    image {
      gatsbyImageData
      url
      format
      video {
        mp4Url
        thumbnailUrl
      }
      alt
    }
    blockId
  }
`;
