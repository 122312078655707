import { graphql, navigate } from "gatsby";
import { renderToString } from "react-dom/server";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useContext, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import JobFormLayout from "../components/layout/job-form-layout";
import { useModelSlugPrefix } from "../hooks/link-util";
import MailSocialmediaFooter from "../components/mail/mail-socialmedia-footer";
import SEO from "../seo/seo";
import Select from "react-select";

var escape = require("escape-html");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsJobApplicationFormPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function JobApplicationFormPage({ data, location }) {
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();
  const [fileErrors, setFileErrors] = useState({});
  const [selectedCvFile, setSelectedFile] = useState(``);
  const [selectedOtherAttachments, setSelectedOtherAttachments] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const cvUploadInputRef = useRef(null);
  const otherAttachmentsUploadInputRef = useRef(null);
  const maxFileSize = 10;
  const firstContactOptions = [
    {
      value: "aca-first-contact-social-media",
      label: t("aca-first-contact-social-media"),
    },
    {
      value: "aca-first-contact-external",
      label: t("aca-first-contact-external"),
    },
    { value: "aca-first-contact-google", label: t("aca-first-contact-google") },
    { value: "aca-first-contact-event", label: t("aca-first-contact-event") },
    {
      value: "aca-first-contact-referral",
      label: t("aca-first-contact-referral"),
    },
    {
      value: "aca-first-contact-from-aca",
      label: t("aca-first-contact-from-aca"),
    },
    { value: "aca-first-contact-other", label: t("aca-first-contact-other") },
  ];
  const knownBeforeOptions = [
    { value: "no", label: t("no") },
    { value: "yes", label: t("yes") },
  ];

  const { control, register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  function cvFileChangeHandler(event) {
    if (
      event.target.files[0] &&
      getFileSizeInMb(event.target.files[0].size) < maxFileSize
    ) {
      fileErrors["cvFile"] = undefined;
      formState.errors.cvFile = undefined;
      setSelectedFile(event.target.files[0]);
    } else {
      fileErrors["cvFile"] = t(
        "your-file-is-too-large-please-upload-a-file-under-10-mb"
      );
      setFileErrors({ ...fileErrors });
    }
  }

  function otherAttachmentsChangeHandler(event) {
    let files = selectedOtherAttachments;
    let filesContainErrors;
    let maxFilesAmountReached;

    for (let i = 0; i <= event.target.files.length - 1; i++) {
      if (
        !filesContainErrors &&
        getFileSizeInMb(event.target.files[i].size) > maxFileSize
      ) {
        filesContainErrors = true;
      } else {
        if (files.length <= 4) {
          files.push(event.target.files[i]);
        } else {
          maxFilesAmountReached = true;
        }
      }
    }

    if (filesContainErrors) {
      fileErrors["otherAttachments"] = t(
        "your-file-is-too-large-please-upload-a-file-under-10-mb"
      );
    }

    if (!maxFilesAmountReached && !filesContainErrors) {
      fileErrors["otherAttachments"] = undefined;
    }

    setFileErrors({ ...fileErrors });
    setSelectedOtherAttachments([...files]);
  }

  function handleCvValidation() {
    if (!selectedCvFile) {
      fileErrors["cvFile"] = t("please-submit-your-cv");
      setFileErrors({ ...fileErrors });
      return false;
    } else {
      fileErrors["cvFile"] = undefined;
      return true;
    }
  }

  function clickCvFileInput(e) {
    if (e.target?.id !== "chooseCvButton" && e.target?.id !== "cv") {
      e.preventDefault();
    }
  }

  function clickOtherAttachmentsFileInput(e) {
    if (
      e.target?.id !== "chooseAttachmentsButton" &&
      e.target?.id !== "otherAttachments"
    ) {
      e.preventDefault();
    }
  }

  async function onSubmit(applicationFormData) {
    if (!handleCvValidation()) {
      return;
    }

    await setSubmittingForm(true);

    var formdata = new FormData();
    formdata.append(
      "jobName",
      location.state?.jobName
        ? location.state?.jobName
        : "Spontane sollicitatie"
    );
    formdata.append("firstName", applicationFormData.firstName);
    formdata.append("lastName", applicationFormData.lastName);
    formdata.append("placeOfResidence", applicationFormData.placeOfResidence);
    formdata.append("email", applicationFormData.email);
    formdata.append("phoneNumber", applicationFormData.phoneNumber);
    formdata.append("firstContact", applicationFormData.firstContact.label);
    formdata.append("knownBefore", applicationFormData.knownBefore.label);
    if (applicationFormData.additionalInfo) {
      formdata.append("additionalInfo", applicationFormData.additionalInfo);
    }
    formdata.append(
      "shortMotivation",
      applicationFormData.shortMotivation
        ? applicationFormData.shortMotivation
        : "Er is geen korte motivatie meegedeeld."
    );
    formdata.append("cvFile", selectedCvFile, selectedCvFile.name);

    for (let i = 0; i < selectedOtherAttachments.length; i++) {
      formdata.append(
        "otherAttachment" + i,
        selectedOtherAttachments[i],
        selectedOtherAttachments[i].name
      );
    }

    const response = await window
      .fetch(`/api/job-application-form`, {
        method: `POST`,
        body: formdata,
      })
      .then((res) => res.json());

    if (response === "ok") {
      sendConfirmationEmail(
        applicationFormData,
        location.state?.jobName ? "focused" : "spontaneous"
      );
    }

    await navigate(
      location.state?.from
        ? `/${language}${location.state.from}`
        : `/${language}/${language === "nl" ? "jobs" : "careers"}`,
      {
        state: {
          response: response,
          type: "application",
        },
      }
    );
  }

  async function sendConfirmationEmail(userData, mailType) {
    var formData = new FormData();
    formData.append("userEmail", userData.email);
    formData.append(
      "title",
      mailType === "focused"
        ? t("focused-application-title-mail")
        : t("spontaneous-application-title-mail")
    );
    formData.append(
      "content",
      renderToString(
        getContentTemplate(
          userData.firstName,
          location.state?.jobName,
          mailType
        )
      )
    );

    if (language === "nl" && mailType === "focused") {
      formData.set(
        "attachment_url",
        data.datoCmsJobApplicationFormPage.jobflyer?.url
      );
      formData.set(
        "attachment_filename",
        data.datoCmsJobApplicationFormPage.jobflyer?.filename
      );
    }

    await window.fetch(`/api/sollicitation-confirmation-mail`, {
      method: `POST`,
      body: formData,
    });
  }

  function getContentTemplate(firstName, jobName, mailType) {
    return (
      <div>
        <div>
          <p>
            {t("greeting-application-mail").replace(
              "{firstName}",
              escape(firstName)
            )}
          </p>
          {mailType === "focused" ? (
            <React.Fragment>
              <p>
                {t("focused-application-content-mail-1").replace(
                  "{jobName}",
                  escape(jobName)
                )}
              </p>
              <p>{t("focused-application-content-mail-2")}</p>
              <img
                src={
                  data.datoCmsJobApplicationFormPage.confirmationMailVisual?.url
                }
                alt={
                  data.datoCmsJobApplicationFormPage.confirmationMailVisual?.alt
                }
                style={{ width: "1000px" }}
              />
              <p>
                {t("focused-application-content-mail-3")}{" "}
                <a
                  href={
                    language === "en"
                      ? "https://www.acagroup.be/en/customer-success-stories"
                      : "https://www.acagroup.be/nl/customer-success-stories"
                  }
                >
                  {t("customer-success-stories").toLowerCase()}
                </a>{" "}
                {t("focused-application-content-mail-4")}{" "}
                <a
                  href={
                    language === "en"
                      ? "https://www.acagroup.be/en/careers"
                      : "https://www.acagroup.be/nl/jobs"
                  }
                >
                  {t("open-career-opportunities")}
                </a>{" "}
                {t("focused-application-content-mail-5")}
              </p>
              <p>{t("see-you-soon")}</p>
              <p>{t("the-aca-hiring-team")}</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>{t("spontaneous-application-content-mail-1")}</p>
              <p>{t("spontaneous-application-content-mail-2")}</p>
              <img
                src={
                  data.datoCmsJobApplicationFormPage.confirmationMailVisual.url
                }
                alt={
                  data.datoCmsJobApplicationFormPage.confirmationMailVisual.alt
                }
                style={{ width: "1000px" }}
              />
              <p>
                {t("spontaneous-application-content-mail-3")}{" "}
                <a
                  href={
                    language === "en"
                      ? "https://www.acagroup.be/en/customer-success-stories"
                      : "https://www.acagroup.be/nl/customer-success-stories"
                  }
                >
                  {t("customer-success-stories").toLowerCase()}
                </a>{" "}
                {t("spontaneous-application-content-mail-4")}{" "}
                <a
                  href={
                    language === "en"
                      ? "https://www.acagroup.be/en/careers"
                      : "https://www.acagroup.be/nl/jobs"
                  }
                >
                  {t("open-career-opportunities")}
                </a>
              </p>
              <p>{t("see-you-soon")}</p>
              <p>{t("the-aca-hiring-team")}</p>
            </React.Fragment>
          )}
        </div>
        <MailSocialmediaFooter
          twitter_icon={data.twitter_icon}
          instagram_icon={data.instagram_icon}
          facebook_icon={data.facebook_icon}
          linkedin_icon={data.linkedin_icon}
          youtube_icon={data.youtube_icon}
        />
      </div>
    );
  }

  function getOtherSelectedAttachments() {
    let otherAttachmentEntries = [];

    for (let i = 0; i <= selectedOtherAttachments.length - 1; i++) {
      let deleteFunction = (e) => {
        e.preventDefault();

        let files = selectedOtherAttachments;
        files.splice(i, 1);

        setSelectedOtherAttachments([...files]);
      };

      otherAttachmentEntries.push(
        <div
          className="d-flex align-items-center"
          key={selectedOtherAttachments[i].name}
        >
          {getFileSvg()}
          <span className="mr-6 font-weight-400 font-size-600">
            {selectedOtherAttachments[i].name}
          </span>
          <a onClick={(e) => deleteFunction(e)}>{t("remove").toUpperCase()}</a>
        </div>
      );
    }

    return otherAttachmentEntries;
  }

  function getPrivacyPolicyLabel() {
    if (language.toLowerCase() === "en") {
      return (
        <div>
          {t("privacy-policy-checkbox-label")}{" "}
          <a href="/en/privacy-policy">{t("privacy-policy").toLowerCase()}</a>.
        </div>
      );
    }

    return (
      <div>
        {t("i-have-the")}{" "}
        <a href="/nl/privacy-policy" aria-label={t("privacy-policy")}>
          {t("privacy-policy").toLowerCase()}
        </a>{" "}
        {t("read-and-understood").toLowerCase()}.
      </div>
    );
  }

  return (
    <JobFormLayout navigationState={location?.state}>
      <main role="main" className="pt-6">
        <div className="py-6">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Link
                  to={
                    location.state?.jobPageSlugs
                      ? `${useModelSlugPrefix("job")}${
                          location.state?.jobPageSlugs[language]
                        }`
                      : `/${language === "nl" ? "jobs" : "careers"}`
                  }
                  className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
                >
                  <i aria-hidden={true} className="ti ti-chevron-left mr-2" />{" "}
                  {getBackButtonText(location.state?.jobPageSlugs)}
                </Link>
                <h1 className="my-4">{getTitle(location.state?.jobName)}</h1>
              </div>
            </div>
            <form role="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-4">
                  <h2 className="mt-0 mb-3">{t("personal-details")}</h2>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="firstName"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("first-name")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="given-name"
                          aria-required="true"
                          aria-invalid={formState.errors.firstName}
                          className={getTextInputClasses(
                            formState.errors.firstName
                          )}
                          type="text"
                          id="firstName"
                          {...register("firstName", { required: true })}
                        />
                        {formState.errors.firstName
                          ? getErrorMessage(t("please-fill-in-your-first-name"))
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="lastName"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("last-name")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="family-name"
                          aria-required="true"
                          aria-invalid={formState.errors.lastName}
                          className={getTextInputClasses(
                            formState.errors.lastName
                          )}
                          type="text"
                          id="lastName"
                          {...register("lastName", { required: true })}
                        />
                        {formState.errors.lastName
                          ? getErrorMessage(t("please-fill-in-your-last-name"))
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group mb-5">
                    <label
                      className="d-flex flex-column my-2"
                      htmlFor="placeOfResidence"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("place-of-residence")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="address-level2"
                          aria-required="true"
                          aria-invalid={formState.errors.placeOfResidence}
                          className={getTextInputClasses(
                            formState.errors.placeOfResidence
                          )}
                          type="text"
                          id="placeOfResidence"
                          {...register("placeOfResidence", { required: true })}
                        />
                        {formState.errors.placeOfResidence
                          ? getErrorMessage(
                              t("please-fill-in-your-place-of-residence")
                            )
                          : ""}
                      </div>
                    </label>
                  </div>

                  <h2 className="mb-3 mt-4">{t("contact-details")}</h2>

                  <div className="form-group">
                    <label className="d-flex flex-column mb-5" htmlFor="email">
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("email-address")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="email"
                          aria-required="true"
                          aria-invalid={formState.errors.email}
                          className={getTextInputClasses(
                            formState.errors.email
                          )}
                          type="email"
                          id="email"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {formState.errors.email
                          ? getErrorMessage(
                              t("please-fill-in-a-valid-email-address")
                            )
                          : ""}
                      </div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column mb-5"
                      htmlFor="phoneNumber"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("phone-number")}
                      </span>
                      <div className="position-relative">
                        <input
                          autoComplete="tel"
                          aria-required="true"
                          aria-invalid={formState.errors.phoneNumber}
                          className={getTextInputClasses(
                            formState.errors.phoneNumber
                          )}
                          type="text"
                          id="phoneNumber"
                          {...register("phoneNumber", { required: true })}
                        />
                        {formState.errors.phoneNumber
                          ? getErrorMessage(
                              t("please-fill-in-a-valid-phone-number")
                            )
                          : ""}
                      </div>
                    </label>
                  </div>
                </div>
                <h2 className="mb-3 mt-4">{t("motivation")}</h2>

                <div className="col-12 col-md-8 col-lg-4">
                  <div className="form-group">
                    <label
                      className="d-flex flex-column my-2"
                      htmlFor="firstContact"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("aca-first-contact")}
                      </span>
                      <Controller
                        id="firstContact"
                        name="firstContact"
                        control={control}
                        render={({ field }) => (
                          <Select
                            instanceId="firstContact"
                            {...register("firstContact", { required: true })}
                            {...field}
                            isSearchable={false}
                            value={field.value}
                            onChange={field.onChange}
                            options={firstContactOptions}
                            classNames={getSelectInputClasses(
                              formState.errors.firstContact
                            )}
                            styles={getSelectInputStyles()}
                          />
                        )}
                      />
                      {formState.errors.firstContact
                        ? getErrorMessage(t("please-select-an-option"))
                        : ""}
                    </label>
                  </div>

                  <div className="form-group">
                    <label
                      className="d-flex flex-column my-2"
                      htmlFor="knownBefore"
                    >
                      <span className="mb-2 font-weight-600 font-size-600">
                        {t("did-you-know-aca-before")}
                      </span>
                      <Controller
                        id="knownBefore"
                        name="knownBefore"
                        control={control}
                        render={({ field }) => (
                          <Select
                            instanceId="knownBefore"
                            {...register("knownBefore", { required: true })}
                            {...field}
                            isSearchable={false}
                            value={field.value}
                            onChange={field.onChange}
                            options={knownBeforeOptions}
                            classNames={getSelectInputClasses(
                              formState.errors.knownBefore
                            )}
                            styles={getSelectInputStyles()}
                          />
                        )}
                      />
                      {formState.errors.knownBefore
                        ? getErrorMessage(t("please-select-an-option"))
                        : ""}
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-8 col-lg-6">
                    <div className="form-group">
                      <label
                        className="d-flex flex-column my-2"
                        htmlFor="additionalInfo"
                      >
                        <span className="mb-2 font-weight-600 font-size-600">
                          {t("additional-info-about-previous-questions")}
                        </span>
                        <div className="position-relative">
                          <textarea
                            id="additionalInfo"
                            aria-invalid={formState.errors.additionalInfo}
                            rows="10"
                            {...register("additionalInfo")}
                            className={getTextAreaInputClasses()}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    className="d-flex flex-column mb-5"
                    htmlFor="cv"
                    onClick={(e) => {
                      clickCvFileInput(e);
                    }}
                  >
                    <span className="mb-2 font-weight-600 font-size-600">
                      {t("upload-your-cv")}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                      {selectedCvFile ? (
                        <div className="d-flex align-items-center">
                          {getFileSvg()}
                          <span className="mr-6 font-weight-400 font-size-600">
                            {selectedCvFile.name}
                          </span>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedFile(null);
                            }}
                          >
                            {t("remove").toUpperCase()}
                          </a>
                        </div>
                      ) : (
                        <div>
                          <input
                            aria-required="true"
                            aria-invalid={
                              formState.errors.cvFile || fileErrors["cvFile"]
                            }
                            type="file"
                            id="cv"
                            name="cv"
                            max="1"
                            accept=".pdf, .doc, .docx"
                            ref={cvUploadInputRef}
                            {...register("cvFile", { required: true })}
                            onChange={cvFileChangeHandler}
                            className="w-0 no-box-shadow"
                          />
                          <a
                            id="chooseCvButton"
                            className="btn font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
                          >
                            {t("choose-file")}
                          </a>
                          <span className="mb-2 ml-4 font-weight-300 font-size-600 color-gray-500">
                            .pdf, .doc, .docx
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="position-relative">
                      {getErrorMessage(fileErrors["cvFile"])}
                      {formState.errors.cvFile
                        ? getErrorMessage(t("please-upload-a-valid-cv"))
                        : ""}
                    </div>
                  </label>
                </div>

                <div className="form-group">
                  <label
                    className="d-flex flex-column mb-5"
                    htmlFor="otherAttachments"
                    onClick={(e) => clickOtherAttachmentsFileInput(e)}
                  >
                    <span className="mb-2 font-weight-600 font-size-600">
                      {`${t("upload-other-attachments")} (${t(
                        "optional"
                      ).toLowerCase()})`}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                      <input
                        aria-required="false"
                        aria-invalid={fileErrors["otherAttachments"]}
                        type="file"
                        id="otherAttachments"
                        name="otherAttachments"
                        accept=".pdf, .doc, .docx"
                        multiple={true}
                        max="5"
                        ref={otherAttachmentsUploadInputRef}
                        onChange={otherAttachmentsChangeHandler}
                        className="w-0 no-box-shadow"
                      />
                      <a
                        id="chooseAttachmentsButton"
                        className="btn font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
                      >
                        {t("choose-file")}
                      </a>
                      <span className="mb-2 ml-4 font-weight-300 font-size-600 color-gray-500">
                        .pdf, .doc, .docx ({t("max-5-attachments")})
                      </span>
                    </div>
                    {getOtherSelectedAttachments()}
                    <div className="position-relative">
                      {getErrorMessage(fileErrors["otherAttachments"])}
                    </div>
                  </label>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-6">
                    <div className="form-group">
                      <label
                        className="d-flex flex-column my-2"
                        htmlFor="shortMotivation"
                      >
                        <span className="mb-2 font-weight-600 font-size-600">
                          {location.state?.shortMotivationRequired
                            ? `${t("short-motivation")}`
                            : `${t("short-motivation")} (${t(
                                "optional"
                              ).toLowerCase()})`}
                        </span>
                        <div className="position-relative">
                          <textarea
                            id="shortMotivation"
                            aria-invalid={formState.errors.shortMotivation}
                            rows="10"
                            {...register("shortMotivation", {
                              required: location.state?.shortMotivationRequired,
                            })}
                            className={getTextAreaInputClasses(
                              formState.errors.shortMotivation
                            )}
                          />
                        </div>
                        <div className="position-relative my-4 my-md-0">
                          {formState.errors.shortMotivation
                            ? getErrorMessage(
                                t("please-fill-in-a-valid-short-motivation")
                              )
                            : ""}
                        </div>
                      </label>
                    </div>
                    <div className="form-group">
                      <div className="d-flex flex-column mb-5">
                        <label
                          className="form-check-label font-size-600"
                          htmlFor="privacyPolicy"
                        >
                          <div className="form-check d-flex align-items-center">
                            <input
                              aria-required="true"
                              aria-invalid={formState.errors.privacyPolicy}
                              className="form-check-input mr-2"
                              type="checkbox"
                              value=""
                              id="privacyPolicy"
                              {...register("privacyPolicy", { required: true })}
                            />
                            {getPrivacyPolicyLabel()}
                          </div>
                          <div className="position-relative my-4 my-md-0">
                            {formState.errors.privacyPolicy
                              ? getErrorMessage(
                                  t(
                                    "please-accept-the-privacy-policy-before-continuing"
                                  )
                                )
                              : ""}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="position-relative">
                      {submittingForm
                        ? getFormStatusMessage(
                            t("please-wait-we-are-processing-your-application")
                          )
                        : ""}
                    </div>
                    <button
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      disabled={submittingForm}
                      className="btn px-4 font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
                    >
                      {t("send-application")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </JobFormLayout>
  );
}

function getTitle(jobName) {
  const { t } = useTranslation();

  if (jobName) {
    return `${t("your-application-for")} ${jobName}`;
  }

  return t("your-spontaneous-application");
}

function getBackButtonText(directApplication) {
  const { t } = useTranslation();

  if (directApplication) {
    return t("back-to-job");
  }

  return t("back-to-overview");
}

function getFileSvg() {
  return (
    <svg
      aria-hidden="true"
      role="img"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-file"
      width="80"
      height="80"
      viewBox="0 0 24 24"
      strokeWidth="0.5"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
    </svg>
  );
}

function getFormStatusMessage(message) {
  return (
    <span className="font-weight-600 font-size-600 w-full">{message}</span>
  );
}

function getErrorMessage(error) {
  if (error) {
    return (
      <span className="error-message font-weight-600 font-size-600 color-tertiary-500 w-full">
        {error}
      </span>
    );
  }
}

function getTextInputClasses(hasError) {
  let classes =
    "input h-44 px-3 pb-1 w-full bg-white border-radius-pill border-2 font-size-600 ";

  if (hasError) {
    classes +=
      "hover-border-tertiary-500 focus-border-tertiary-500 border border-tertiary-500";
  }

  return classes;
}

function getTextAreaInputClasses(hasError) {
  let classes =
    "form-select input p-3 w-full bg-white border-radius-xxl focus-border-primary-700 font-size-600";

  if (hasError) {
    classes +=
      "border-2 hover-border-tertiary-500 focus-border-tertiary-500 border border-tertiary-500";
  } else {
    classes += "border hover-border-primary-700 border-sand-800";
  }

  return classes;
}

function getSelectInputClasses(hasError) {
  let controlClasses =
    "form-select input h-44 px-3 pb-1 w-full bg-white border-radius-pill hover-border-primary-700 focus-border-primary-700 border border-sand-800 font-size-400 pr-3";

  if (hasError) {
    controlClasses +=
      "hover-border-tertiary-500 focus-border-tertiary-500 border border-tertiary-500";
  }

  return {
    control: () => controlClasses,
    indicatorSeparator: () => "d-none",
    menu: () => "border border-2 border-radius-lg",
  };
}

function getSelectInputStyles() {
  return {
    control: (baseStyles) => ({
      ...baseStyles,
      lineHeight: "initial",
      boxShadow: "none",
      "&:hover": {
        borderColor: "var(--form-highlight)",
      },
    }),
  };
}

function getFileSizeInMb(size) {
  return size / 1024 / 1024;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsJobApplicationFormPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
      confirmationMailVisual {
        url
        alt
      }
      jobflyer {
        url
        filename
      }
    }
    twitter_icon: datoCmsAsset(filename: { eq: "twitter.png" }) {
      url
    }
    instagram_icon: datoCmsAsset(filename: { eq: "instagram.png" }) {
      url
    }
    facebook_icon: datoCmsAsset(filename: { eq: "facebook.png" }) {
      url
    }
    linkedin_icon: datoCmsAsset(filename: { eq: "linkedin.png" }) {
      url
    }
    youtube_icon: datoCmsAsset(filename: { eq: "youtube.png" }) {
      url
    }
  }
`;
