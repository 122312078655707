import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useModelSlugPrefix } from "../../hooks/link-util";

export default function DefaultHitComponent({ hit, searchNavigationHandler }) {
  const { t } = useTranslation();
  const hitUrl = `${useModelSlugPrefix(hit.apiKey)}${hit.slug}`;

  return (
    <div className="event-item" tabIndex="0">
      <div className="container">
        <div className="row">
          <div className="col-12 pb-4">
            <div className="font-size-700 line-height-sm font-weight-700 pb-4 border-bottom">
              <div className="d-flex flex-column flex-md-row">
                <div className="flex-grow-1">
                  <a
                    onClick={() => searchNavigationHandler(hitUrl)}
                    className="my-1"
                  >
                    <div className="rich-text search-hit-title">
                      {hit.title}
                    </div>
                  </a>
                  <div
                    className="my-1 color-gray-500 font-weight-300 font-size-500"
                    dangerouslySetInnerHTML={{ __html: hit.description }}
                  />
                  <div className="my-1 color-tertiary-500 font-weight-500 font-size-400">
                    {t(`search-result-type-${hit.apiKey}`)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
