import React from "react";
import { graphql } from "gatsby";
import LinkToModel3Block from "./link-to-model-3-block";

export default function Blocks3Recent({ data }) {
  return <LinkToModel3Block data={data} />;
}

export const blocks3RecentFragment = graphql`
  fragment blocks3Recent on DatoCmsBlocksRecentThree {
    id
    blockId
    greyBackground
    model {
      apiKey
    }
    block1Category
    block1Tags {
      ... on DatoCmsBlogPrimaryCategory {
        id
        categoryName
      }
      ... on DatoCmsNewsCategory {
        id
        categoryName
      }
      ... on DatoCmsEventCategory {
        id
        categoryName
      }
    }
    block2Category
    block2Tags {
      ... on DatoCmsBlogPrimaryCategory {
        id
        categoryName
      }
      ... on DatoCmsNewsCategory {
        id
        categoryName
      }
      ... on DatoCmsEventCategory {
        id
        categoryName
      }
    }
    block3Category
    block3Tags {
      ... on DatoCmsBlogPrimaryCategory {
        id
        categoryName
      }
      ... on DatoCmsNewsCategory {
        id
        categoryName
      }
      ... on DatoCmsEventCategory {
        id
        categoryName
      }
    }
  }
`;
