import qs from "qs";

export const createURL = (state) => {
  state.page = undefined;
  return `?${qs.stringify(state)}`;
};

export const searchStateToUrl = ({ location }, searchState) => {
  return searchState ? `${location?.pathname}${createURL(searchState)}` : "";
};

export const urlToSearchState = ({ search }) =>
  qs.parse(search ? search.slice(1) : "");
