import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";

export default function FooterCopyrightComponent() {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <div className="py-3 col-lg-4">
      <span className="font-weight-700">
        © {new Date().getFullYear()} ACA Group
      </span>
      <span className="color-silver-600"> | </span>
      <span className="color-primary-500 font-weight-700">
        <Link
          to={`/${language === "nl" ? "juridische-info" : "legal-information"}`}
        >
          {t("legal-information")}
        </Link>
      </span>
      <span className="color-silver-600"> | </span>
      <span className="color-primary-500 font-weight-700">
        <Link to="/sitemap">Sitemap</Link>
      </span>
    </div>
  );
}
