import React, { useContext } from "react";
import { navigate as doNav } from "gatsby";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";

export default function JobAlertTile({ getSelectedJobCategories }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  return (
    <li className="ais-InfiniteHits-item single-block">
      <div className="event-item-wrapper bg-primary-500 h-full">
        <div
          className="event-item d-flex justify-content-center flex-column"
          data-testid="jobEntry"
        >
          <h3 className="h2-styled color-white">
            {t("nothing-for-you-in-the-mix")}
          </h3>
          <p className="color-white font-weight-500 font-size-500">
            {t("get-aca-careers-opportunities")}
          </p>
          <button
            onClick={() => navigateToJobAlertPage()}
            className="btn white-space-wrap w-fit-content bg-white font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
          >
            {t("set-up-job-alert")}
          </button>
        </div>
      </div>
    </li>
  );

  async function navigateToJobAlertPage() {
    await doNav(language === "en" ? "/en/jobalert" : "/nl/jobalert", {
      state: getSelectedJobCategories(),
    });
  }
}
