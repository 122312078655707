import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

export default function ExecutableHtmlBlock({ data }) {
  const [thirdPartyCookieConsentAccepted, setThirdPartyCookieConsentAccepted] =
    useState(true);

  useEffect(() => {
    if (document.querySelector(`#${data.id} iframe[data-cookieconsent]`)) {
      if (Cookiebot.consent.marketing) {
        setThirdPartyCookieConsentAccepted(true);
      } else {
        setThirdPartyCookieConsentAccepted(false);
      }

      window.addEventListener(
        "CookiebotOnAccept",
        function () {
          if (Cookiebot.consent.marketing) {
            setThirdPartyCookieConsentAccepted(true);
          } else {
            setThirdPartyCookieConsentAccepted(false);
          }
        },
        false
      );
    }
  }, []);

  return (
    <div className="py-2">
      <div
        id={data.id}
        className="container custom-html__wrapper"
        dangerouslySetInnerHTML={{ __html: data.executableHtml }}
      />
      <div
        className={
          thirdPartyCookieConsentAccepted
            ? "d-none"
            : "container third-party-blocked-iframe"
        }
      >
        <i className="ti ti-eye"></i>
        <p>
          In order to view this content, you need to accept Marketing & third
          party cookies.
        </p>
        <a
          href="javascript: Cookiebot.renew()"
          className="btn font-weight-600 color-secondary-500 white-space-wrap hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
        >
          Click here to adjust your preferences
        </a>
      </div>
    </div>
  );
}

export const executableHtmlFragment = graphql`
  fragment executableHtml on DatoCmsExecutableHtml {
    id
    greyBackground
    executableHtml
    blockId
    model {
      apiKey
    }
  }
`;
