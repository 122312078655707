import { navigate } from "gatsby";
import { default as React, useContext, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SearchBox } from "react-instantsearch-dom";
import { I18nextContext } from "gatsby-plugin-react-i18next";

export default function HeaderSearchComponent({ isMobile }) {
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;
  const DEBOUNCE_TIME = 400;
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={getGlobalSearchWrapperClasses(isMobile)}>
      <div className={getSearchWrapperClasses(isMobile)} role="search">
        <SearchBox
          inputId={isMobile ? "mobile-header-search" : "header-search"}
          onSubmit={(event) => performSearch(event)}
          onKeyDown={(e) => (e.key === "Escape" ? setExpanded(false) : "")}
          translations={{
            placeholder: t("search-aca-group"),
            submitTitle: t("submit-search-query"),
          }}
        />
      </div>
      <a
        href="#"
        className={`${expanded || isMobile ? "d-none" : ""} color-black`}
        onClick={() => expandSearchBox()}
      >
        <span className="accessibility-visually-hidden">{t("search")}</span>
        <i aria-hidden={true} className="ti ti-search font-size-500" />
      </a>
    </div>
  );

  function getSearchWrapperClasses(mobileView) {
    if (mobileView) {
      return "position-relative my-3 search-wrapper w-full border-radius-pill border border-primary-500 hover-border-quaternary-500 focus-border-quaternary-500 font-size-400";
    }

    return `${
      expanded ? "" : "d-none"
    } search-wrapper position-relative bg-white border-radius-pill border border-primary-500 hover-border-quaternary-500 focus-border-quaternary-500 font-size-400`;
  }

  function getGlobalSearchWrapperClasses(mobileView) {
    if (mobileView) {
      return "global-search d-flex flex-row dropdown";
    }

    return "global-search mx-3 d-none d-xl-flex flex-row";
  }

  function performSearch(event) {
    event.preventDefault();

    if (event.nativeEvent.target[0].value !== "") {
      setTimeout(() => {
        navigate(`/${language}/search?query=${event?.target[0].value}`);
      }, DEBOUNCE_TIME);
    }

    if (window.location.pathname.endsWith(`/${language}/search`)) {
      setTimeout(() => window.location.reload(), DEBOUNCE_TIME);
    }
  }

  async function expandSearchBox() {
    await setExpanded(true);
    isMobile
      ? document.getElementById("mobile-header-search").focus()
      : document.getElementById("header-search").focus();
  }
}
