import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import OfficeComponent from "../office-component";
import FooterBottomRowComponent from "./footer-bottom-row-component";
import FooterContactComponent from "./footer-contact-component";
import { Link } from "gatsby";

export default function FooterComponent({
  workAtAca,
  showFooter = true,
  location,
}) {
  const { t } = useTranslation();
  const offices = {
    hasselt: {
      key: "office-hasselt",
      mapUrl:
        "https://www.google.be/maps/place/ACA+IT-Solutions+Hasselt/@50.9420748,5.3193945,17z/data=!3m1!4b1!4m5!3m4!1s0x47c12199d2036e87:0x86df6707b3167589!8m2!3d50.9420748!4d5.3215832",
      address: "Herkenrodesingel 8B/2.01",
      city: "3500 Hasselt",
    },
    gent: {
      key: "office-gent",
      mapUrl:
        "https://www.google.com/maps/place/ACA+IT-Solutions+Gent/@51.0776689,3.725629,17z/data=!3m1!4b1!4m5!3m4!1s0x47c370d87bff9411:0xac2c08b8d11e6b8d!8m2!3d51.0776769!4d3.7278167",
      address: "Dublinstraat 31/010",
      city: "9000 " + t("gent"),
    },
    leuven: {
      key: "office-leuven",
      mapUrl:
        "https://www.google.com/maps/place/Diestsepoort+1,+3000+Leuven/@50.8827386,4.7118603,17z/data=!3m1!4b1!4m5!3m4!1s0x47c160d3176f1873:0xb05e434df3b2f0e3!8m2!3d50.8827386!4d4.714049",
      address: "Diestsepoort 1",
      city: "3000 " + t("leuven"),
    },
    antwerpen: {
      key: "office-antwerpen",
      mapUrl:
        "https://www.google.com/maps/place/Anneessensstraat+1%2F3,+2018+Antwerpen/@51.2190928,4.418533,17z/data=!3m1!4b1!4m6!3m5!1s0x47c3f701b572c6ab:0xdef4bff7de50f3b2!8m2!3d51.2190928!4d4.418533!16s%2Fg%2F11r129smdf?entry=ttu",
      address: "office-antwerpen-street",
      city: "2018 " + t("antwerpen"),
    },
  };

  return (
    <footer
      role="contentinfo"
      className={`${showFooter ? "" : "d-none"} border-top`}
    >
      {renderMobileFooter()}
      {renderFooter()}
    </footer>
  );

  function renderMobileFooter() {
    return (
      <div className="container py-5 d-lg-none">
        <div className="row py-3">
          {renderBackToTopLink()}
          <div className="col-lg-8">
            <h2 className="font-size-900 font-weight-700 m-0">
              {workAtAca
                ? t("share-our-passion-for-it")
                : t("dont-be-shy-talk-to-us")}
            </h2>
            <div className="font-size-500">
              {workAtAca
                ? t("we-love-to-hear-from-you")
                : t("we-reply-to-everyone")}
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <FooterContactComponent workAtAca={workAtAca} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4 py-4">
            <OfficeComponent office={offices.hasselt} />
          </div>
          <div className="col-sm-6 col-lg-4 py-4">
            <OfficeComponent office={offices.gent} />
          </div>
          <div className="col-sm-6 col-lg-4 py-4">
            <OfficeComponent office={offices.leuven} />
          </div>
          <div className="col-sm-6 col-lg-4 py-4">
            <OfficeComponent office={offices.antwerpen} />
          </div>
        </div>
        <FooterBottomRowComponent />
      </div>
    );
  }

  function renderFooter() {
    return (
      <div className="container py-5 d-none d-lg-block">
        <div className="row pt-3">{renderBackToTopLink()}</div>
        <div className="row">
          <div className="col-md-9 d-flex flex-column justify-content-between">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="font-size-900 font-weight-700 m-0">
                  {workAtAca
                    ? t("share-our-passion-for-it")
                    : t("dont-be-shy-talk-to-us")}
                </h2>
                <div className="font-size-500">
                  {workAtAca
                    ? t("we-love-to-hear-from-you")
                    : t("we-reply-to-everyone")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 pb-6">
            <FooterContactComponent workAtAca={workAtAca} />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-sm-6 col-lg-3">
            <OfficeComponent office={offices.hasselt} />
          </div>
          <div className="col-sm-6 col-lg-3">
            <OfficeComponent office={offices.gent} />
          </div>
          <div className="col-sm-6 col-lg-3">
            <OfficeComponent office={offices.leuven} />
          </div>
          <div className="col-sm-6 col-lg-3">
            <OfficeComponent office={offices.antwerpen} />
          </div>
        </div>
        <FooterBottomRowComponent />
      </div>
    );
  }

  function renderBackToTopLink() {
    return (
      <Link to="#top" className="pb-3" state={location?.state}>
        <i aria-hidden={true} className="ti ti-arrow-up mr-1" />
        {t("back-to-top")}
      </Link>
    );
  }
}
