import React, { useEffect } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const imageUtil = require("../utils/image-util.js");

export default function BlogEntryComponent({ blog, filter, shouldFocus }) {
  const { t } = useTranslation();

  let blogEntry = null;

  useEffect(() => {
    if (shouldFocus) {
      blogEntry.focus();
    }
  }, []);

  return (
    <li
      data-testid="blogEntry"
      className="col-md-6 col-lg-4 py-3"
      ref={(entry) => {
        blogEntry = entry;
      }}
      tabIndex="0"
    >
      <div className="py-4 border-radius-lg h-full">
        <Link
          to={"/blog/" + blog.pageSlug}
          state={{ appliedFilter: filter }}
          className="flex-column w-full"
        >
          <div className="mb-3 w-full" aria-hidden={true}>
            <div className="picture position-relative">
              {imageUtil.getImageElements(blog.coverImage, "w-full")}
              <div className="picture__cover">
                <i className="ti ti-arrow-narrow-right font-size-900 color-white" />
              </div>
              <span className="picture__tag bg-white color-tertiary-500 font-size-300 font-weight-700 p-1 px-2 m-3">
                {blog.primaryCategory.categoryName}
              </span>
            </div>
          </div>
          <div className=" w-full justify-content-left font-size-700 line-height-sm font-weight-700 mb-3">
            {blog.headTitle}
          </div>
        </Link>
        <div className="mb-3 w-full">
          <div
            className="rich-text"
            dangerouslySetInnerHTML={{ __html: blog.excerpt }}
          />
        </div>
        <div className="d-flex w-full flex-row flex-wrap">
          <div className="line-height-1 mr-3">
            {getAuthorPhoto(blog.author)}
          </div>
          <Link
            to={`/${t("author").toLowerCase()}/${blog.author.pageSlug}`}
            aria-label={`${t("go-to-author-page")} ${blog.author.fullName}`}
          >
            <div className="flex-grow-1">
              <span className="m-0 font-size-500 font-weight-700">
                {blog.author.fullName}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </li>
  );

  function getAuthorPhoto(author) {
    if (author.photo) {
      return imageUtil.getImageElements(
        author.photo,
        "m-0 w-36 h-36 overflow-hidden border-radius-circle"
      );
    }

    return (
      <StaticImage
        src="../images/placeholder-avatar.jpg"
        alt=""
        className="m-0 w-36 h-36 overflow-hidden border-radius-circle"
      />
    );
  }
}
