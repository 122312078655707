import moment from "moment";
import "moment/locale/nl";
import { useContext } from "react";
import { I18nextContext } from "gatsby-plugin-react-i18next";

export function useTimeOfDate(date) {
  const locale = useContext(I18nextContext).language;

  if (date) {
    return moment(date).locale(locale).local().format("HH:mm");
  }
}

export function useDateFormatWithDefaultFormat(date) {
  const locale = useContext(I18nextContext).language;

  if (date) {
    return moment(date).locale(locale).local().format("DD/MM/YYYY");
  }
}

export function useDateFormat(date, format) {
  const locale = useContext(I18nextContext).language;

  if (date && format) {
    return moment(date).locale(locale).local().format(format);
  }
}

export function useDateIsTodayOrAfterToday(date) {
  const locale = useContext(I18nextContext).language;

  if (date) {
    return moment(date)
      .locale(locale)
      .local()
      .isSameOrAfter(moment().local().startOf("day"));
  }
}

export function useDateIsBeforeToday(date) {
  const locale = useContext(I18nextContext).language;

  if (date) {
    return moment(date)
      .locale(locale)
      .local()
      .isBefore(moment().local().startOf("day"));
  }
}
