import React, { useContext, useEffect } from "react";
import JobTile from "./tiles/job-tile";
import QuoteTile from "./tiles/quote-tile";
import JobAlertTile from "./tiles/job-alert-tile";
import ReferralTile from "./tiles/referral-tile";
import SpontaneousSollicitationTile from "./tiles/spontaneous-sollicitation-tile";
import FormType from "./domain/FormType";
import QuoteEntry from "./domain/QuoteEntry";
import { CareersHitContext } from "../../../utils/contexts";

export default function CareersHits({ hits }) {
  const careersHitContext = useContext(CareersHitContext);
  let currentFormTileType = FormType.JOB_ALERT;
  let tileCounter = 1;
  let quoteCounter = 1;
  let hitsCount = hits.length;
  let latestTileIsForm = false;
  let jobAlertHasBeenRendered = false;
  let spontaneousHasBeenRendered = false;
  let referralHasBeenRendered = false;

  useEffect(() => {
    careersHitContext.setAmountOfHits(hitsCount);
  }, [hits]);

  return (
    <ul className="ais-InfiniteHits-list job-list">
      {renderTiles()}
      {(tileCounter - 1) % 2 === 1 ? getNextFormTile() : ""}
      {hitsCount <= 12 ? getTrailingForms() : ""}
    </ul>
  );

  function renderTiles() {
    return hits.map((hit, index) => {
      return getTile(hit, index + 1);
    });
  }

  function getTrailingForms() {
    return (
      <React.Fragment>
        {!jobAlertHasBeenRendered ? (
          <JobAlertTile
            key={`jobalert-${tileCounter}`}
            getSelectedJobCategories={
              careersHitContext.getSelectedJobCategories
            }
          />
        ) : (
          ""
        )}
        {!spontaneousHasBeenRendered ? (
          <SpontaneousSollicitationTile key={`spontaneous-${tileCounter}`} />
        ) : (
          ""
        )}
        {!referralHasBeenRendered ? (
          <ReferralTile key={`referral-${tileCounter}`} />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  function getNextFormTile() {
    return getFormTileDom();
  }

  function getTile(hit, index) {
    switch (true) {
      case latestTileIsForm: {
        latestTileIsForm = false;
        tileCounter += 1;
        let quoteEntry = QuoteEntry.getEntry(quoteCounter);
        quoteCounter = quoteCounter === 10 ? 1 : quoteCounter + 1;
        return (
          <React.Fragment>
            <QuoteTile key={`quote-${tileCounter}`} quoteEntry={quoteEntry} />
            <JobTile
              key={hit.objectID}
              hit={hit}
              tileNavigationHandler={careersHitContext.tileNavigationHandler}
            />
          </React.Fragment>
        );
      }
      case tileCounter % 6 === 0 && index % 4 === 0: {
        let formTile = getFormTileDom();
        let quoteEntry = QuoteEntry.getEntry(quoteCounter);
        currentFormTileType = getNextFormTileType();
        tileCounter += 2;
        quoteCounter = quoteCounter === 9 ? 1 : quoteCounter + 1;
        return (
          <React.Fragment>
            <JobTile
              key={hit.objectID}
              hit={hit}
              tileNavigationHandler={careersHitContext.tileNavigationHandler}
            />
            <QuoteTile key={`quote-${tileCounter}`} quoteEntry={quoteEntry} />
            {formTile}
          </React.Fragment>
        );
      }
      case tileCounter % 6 === 0: {
        tileCounter += 1;
        let quoteEntry = QuoteEntry.getEntry(quoteCounter);
        quoteCounter = quoteCounter === 10 ? 1 : quoteCounter + 1;
        return (
          <React.Fragment>
            <JobTile
              key={hit.objectID}
              hit={hit}
              tileNavigationHandler={careersHitContext.tileNavigationHandler}
            />
            <QuoteTile key={`quote-${tileCounter}`} quoteEntry={quoteEntry} />
          </React.Fragment>
        );
      }
      case index % 4 === 0: {
        let formTile = getFormTileDom();
        currentFormTileType = getNextFormTileType();
        tileCounter += 2;
        latestTileIsForm = tileCounter % 6 === 1;
        return (
          <React.Fragment>
            <JobTile
              key={hit.objectID}
              hit={hit}
              tileNavigationHandler={careersHitContext.tileNavigationHandler}
            />
            {formTile}
          </React.Fragment>
        );
      }
      default:
        tileCounter += 1;
        return (
          <JobTile
            key={hit.objectID}
            hit={hit}
            tileNavigationHandler={careersHitContext.tileNavigationHandler}
          />
        );
    }
  }

  function getFormTileDom() {
    switch (currentFormTileType) {
      case FormType.JOB_ALERT:
        jobAlertHasBeenRendered = true;
        return (
          <JobAlertTile
            key={`jobalert-${tileCounter}`}
            getSelectedJobCategories={
              careersHitContext.getSelectedJobCategories
            }
          />
        );
      case FormType.SPONTANEOUS:
        spontaneousHasBeenRendered = true;
        return (
          <SpontaneousSollicitationTile key={`spontaneous-${tileCounter}`} />
        );
      case FormType.REFERRAL:
        referralHasBeenRendered = true;
        return <ReferralTile key={`referral-${tileCounter}`} />;
    }
  }

  function getNextFormTileType() {
    switch (currentFormTileType) {
      case FormType.JOB_ALERT:
        return FormType.SPONTANEOUS;
      case FormType.SPONTANEOUS:
        return FormType.REFERRAL;
      case FormType.REFERRAL:
        return FormType.JOB_ALERT;
    }
  }
}
