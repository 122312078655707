import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function InternshipsMotivationBlock() {
  const { t } = useTranslation();

  return (
    <div className="py-2 title-block" id="internships-motivation-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 py-3">
            <div className="w-full d-none d-lg-block">
              <StaticImage src="../../images/intern2.jpeg" alt="" />
            </div>
          </div>
          <div className="col-lg-6 py-1">
            <div className="my-3">
              <h2 className="mb-3">{t("internships-motivation-title")}</h2>
              <p className="mb-3">
                {t("aca-is-a")}
                <strong>{t("growing-company")}</strong>
                {t("that-realizes-1")}
              </p>
              <p>
                {t("that-realizes-2")}
                <strong>{t("during-your-internship")}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
