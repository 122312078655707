import React from "react";
import { graphql } from "gatsby";

const imageUtil = require("../utils/image-util.js");

export default function BlockWithIconImage({ data }) {
  return (
    <div
      className="py-2 block-with-icon-image"
      {...(data.blockId ? { id: data.blockId } : {})}
    >
      <div className="container">
        <div className="row">
          {getImageDisplay(data, data.showImageMobile, "d-lg-none")}
          {getImageDisplay(data, data.imageLeft, "d-none d-lg-block")}
          {getBlockDisplay(data)}
          {getImageDisplay(data, !data.imageLeft, "d-none d-lg-block")}
        </div>
      </div>
    </div>
  );
}

function getHeadTitle(data) {
  return (
    <h2 className="font-size-700 line-height-sm font-weight-700 mb-3 mt-0">
      {data.headTitle}
    </h2>
  );
}

function getIconImage(data) {
  if (data.iconImage) {
    return (
      <div className="mb-3">
        {imageUtil.getImageElements(data.iconImage, "w-32")}
      </div>
    );
  }
}

function getBlockDisplay(data) {
  return (
    <div className="col-md-6 py-3">
      <div className="py-4 border-radius-lg h-full">
        {getIconImage(data)}
        {getHeadTitle(data)}
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        {getLink(data)}
      </div>
    </div>
  );
}

function getLink(data) {
  if (data.linkText && data.linkHref) {
    return (
      <div className="py-4">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href={data.linkHref}
          rel={data.openLinkInNewTab && "noreferrer noopener"}
          target={data.openLinkInNewTab ? "_blank" : "_self"}
          className="align-items-center"
        >
          {data.linkText}
          <i aria-hidden={true} className="ti ti-chevron-right ml-1" />
        </a>
      </div>
    );
  }
}

function getImageDisplay(data, visible, customClasses) {
  let classes = "col-md-6 py-3 " + customClasses;

  if (visible) {
    if (data.imageLinkUrl) {
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={data.imageLinkUrl}
          target={data.openImageLinkInNewTab ? "_blank" : "_self"}
          rel={data.openImageLinkInNewTab && "noreferrer noopener"}
          className={classes}
        >
          <div data-testid="image">
            {imageUtil.getImageElements(data.image, "w-full d-block")}
          </div>
        </a>
      );
    }

    return (
      <div className={classes} data-testid="image">
        {imageUtil.getImageElements(data.image, "w-full d-block")}
      </div>
    );
  }
}

export const blockWithIconAndImageFragment = graphql`
  fragment blockWithIconAndImage on DatoCmsBlockWithIconAndImage {
    id
    headTitle
    content
    greyBackground
    linkText
    linkHref
    openLinkInNewTab
    iconImage {
      format
      gatsbyImageData
      url
      alt
    }
    model {
      apiKey
    }
    showImageMobile
    imageLeft
    image {
      url
      gatsbyImageData
      format
      alt
    }
    openImageLinkInNewTab
    imageLinkUrl
    blockId
  }
`;
