import { graphql } from "gatsby";
import {
  I18nextContext,
  Link,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import Layout from "../components/layout/layout";
import {
  getBlock,
  getSectionClassesWithoutPaddingTop,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import SEO from "../seo/seo";

const dateUtil = require("../hooks/date-util");
const imageUtil = require("../utils/image-util.js");

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsEvent.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function EventModel({ data, location }) {
  const event = data.datoCmsEvent;
  const { t } = useTranslation();
  const language = useContext(I18nextContext).language;

  const date = dateUtil.useDateFormatWithDefaultFormat(event.date);
  const time = dateUtil.useTimeOfDate(event.date);
  const untilDate = dateUtil.useDateFormatWithDefaultFormat(event.untilDate);
  const untilTime = event.untilTime;

  return (
    <Layout location={location} pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredArticleData() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: getStructuredBreadCrumbData() }}
        />
        <div className="py-6">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Link
                  to={
                    location?.state?.previous
                      ? `${location.state.previous}${window.location.search}`
                      : "/events"
                  }
                  className="font-weight-600 color-primary-500 hover-color-quaternary-500 focus-color-quaternary-500 transition hover-text-decoration-underline focus-text-decoration-underline"
                >
                  <i aria-hidden={true} className="ti ti-chevron-left mr-2" />{" "}
                  {location?.state?.previous || location?.search
                    ? t("back-to-search")
                    : t("back-to-events-overview")}
                </Link>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-lg-6 py-3 d-flex flex-column">
                <div className="my-3">
                  <div className="rich-text">
                    <h1>{event.headTitle}</h1>
                    <h2 className="color-gray-400 h4-styled">
                      {`${event.eventType.categoryName} - ${
                        event.primaryCategory.categoryName
                      } - ${event.presentationMethod.categoryName}${
                        event.academy ? " - ACAdemy" : ""
                      }`}
                    </h2>
                  </div>
                </div>
                <div className="my-3">
                  <table className="font-weight-700">
                    <tbody>
                      <tr>
                        <td>{t("date")}:</td>
                        <td>
                          {date}
                          {untilDate && untilDate !== date
                            ? ` - ${untilDate}`
                            : ""}
                        </td>
                      </tr>
                      {time && time !== "00:00" ? (
                        <tr>
                          <td>{t("time")}:</td>
                          <td>
                            {time}
                            {untilTime && untilTime !== time
                              ? ` - ${untilTime}`
                              : ""}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td className="d-flex align-items-top pr-3">
                          {t("location")}:
                        </td>
                        <td className="rich-text">
                          {event.location ? (
                            <div>
                              <div
                                className="rich-text"
                                dangerouslySetInnerHTML={{
                                  __html: event.location,
                                }}
                              />
                              {getMapLink(event)}
                            </div>
                          ) : (
                            ""
                          )}
                          {event.linkDigitalEvent ? (
                            <a
                              href={event.linkDigitalEvent}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="font-weight-700"
                            >
                              {t("link-digital-event-label")}
                              <i
                                aria-hidden={true}
                                className="ti ti-external-link ml-1"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {getRegisterButton()}
                </div>
              </div>
              <div className="col-lg-6 py-3">
                {imageUtil.getImageElements(event.image, "w-full")}
              </div>
            </div>
          </div>
        </div>
        <section role="region" className="background last">
          <div className="py-2">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 offset-xl-0">
                  <div className="d-flex flex-column">
                    <h2>{t("about-event")}</h2>
                    <div
                      className="html-content"
                      dangerouslySetInnerHTML={{ __html: event.description }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {event.content?.blocks.map((block, index) => {
          return (
            <section
              role="region"
              className={getSectionClassesWithoutPaddingTop(
                event.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  );

  function getRegisterButton() {
    if (event.registerButtonUrl) {
      return (
        <div className="d-flex align-items-center mt-60">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={event.registerButtonUrl}
            target={event.registerButtonOpenNewTab ? "_blank" : "_self"}
            rel={event.registerButtonOpenNewTab && "noreferrer noopener"}
            className="btn font-weight-600 color-white hover-color-white bg-primary-500 hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill"
          >
            {t("register-now")}
          </a>
        </div>
      );
    }
  }

  function getStructuredBreadCrumbData() {
    return `
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Blog",
        "item": "https://www.acagroup.be/${language}/events"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${event.headTitle}",
        "item": "https://www.acagroup.be/${language}/events/${event.pageSlug}"
      }]
    }`;
  }

  function getStructuredArticleData() {
    return `
    {
     "@context": "https://schema.org",
      "@type": "Event",
      "name": "${event.headTitle}",
      "startDate": "${event.date}",
      "endDate": "${event.untilDate ? event.untilDate : event.date}",
      "eventAttendanceMode": "${getStructuredEventAttendanceMode()}",
      "eventStatus": "https://schema.org/EventScheduled",
      "location": ${getStructuredEventLocation()},
      "image": [
        "${event.image.url}"
       ],
      "description": "${event.excerpt}",
      ${getStructuredEventOffer()}
      "organizer": {
        "@type": "Organization",
        "name": "ACA Group",
        "url": "https://acagroup.be"
      }
    }`;
  }

  function getStructuredEventOffer() {
    if (event.ticketUrl && event.ticketPrice) {
      return `"offers": {
          "@type": "Offer",
          "url": "${event.ticketUrl}",
          "price": "${event.ticketPrice}",
          "priceCurrency": "EUR",
          "availability": "https://schema.org/InStock"
    },`;
    }

    return "";
  }

  function getStructuredEventAttendanceMode() {
    switch (event.presentationMethod.categoryName) {
      case "Digital event":
        return "https://schema.org/OnlineEventAttendanceMode";
      case "Hybrid event":
        return "https://schema.org/MixedEventAttendanceMode";
      case "Physical event":
        return "https://schema.org/OfflineEventAttendanceMode";
    }
  }

  function getStructuredEventLocation() {
    switch (event.presentationMethod.categoryName) {
      case "Digital event":
        return `{
          "@type": "VirtualLocation",
          "url": "${event.onlineEventUrl}"
        }`;
      case "Hybrid event":
        return `[
          {
            "@type": "VirtualLocation",
            "url": "${event.onlineEventUrl}"
          },
          {
            "@type": "Place",
            "name": "${event.locationName}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${event.locationAddress}",
              "addressLocality": "${event.locationTown}",
              "postalCode": "${event.locationPostalCode}",
              "addressCountry": "${event.locationCountry}"
            }
          }
        ]`;
      case "Physical event":
        return `{
            "@type": "Place",
            "name": "${event.locationName}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${event.locationAddress}",
              "addressLocality": "${event.locationTown}",
              "postalCode": "${event.locationPostalCode}",
              "addressCountry": "${event.locationCountry}"
            }
          }`;
    }
  }
}

function getMapLink(event) {
  const { t } = useTranslation();
  if (event.mapLocation) {
    return (
      <a
        href={`http://www.google.com/maps/place/${event.mapLocation.latitude},${event.mapLocation.longitude}`}
        target="_blank"
        rel="noreferrer noopener"
        className="font-weight-700"
      >
        {t("view-location")}
        <i aria-hidden={true} className="ti ti-external-link ml-1" />
      </a>
    );
  }
}

export const query = graphql`
  query ($language: String!, $originalId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsEvent(originalId: { eq: $originalId }, locale: $language) {
      ...eventFull
    }
    pageSlugs: datoCmsEvent(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
