import { graphql } from "gatsby";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import {
  default as React,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ClearRefinements,
  Configure,
  InfiniteHits,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom";
import Layout from "../components/layout/layout";
import AlgoliaSearchContextComponent from "../components/search/algolia-search-context-component";
import DownloadEmptyMessageComponent from "../components/search/download-empty-message-component";
import { connectStateResults } from "react-instantsearch-core";
import SearchHeaderComponent from "../components/search/search-header-component";
import DownloadHitComponent from "../components/search/download-hit-component";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsDownloadOverviewPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function DownloadsPage({ location }) {
  const language = useContext(I18nextContext).language;
  const { t } = useTranslation();
  const mobileFilterRef = useRef(null);
  const openMobileFilterRef = useRef(null);
  const [mobileFilterOpened, setMobileFilterOpened] = useState(false);

  const ConnectedEmptyMessageComponent = connectStateResults(
    DownloadEmptyMessageComponent
  );
  const ConnectedSearchHeaderComponent = connectStateResults(
    SearchHeaderComponent
  );

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        mobileFilterOpened &&
        mobileFilterRef.current !== null &&
        !mobileFilterRef.current.contains(e.target) &&
        openMobileFilterRef.current !== null &&
        !openMobileFilterRef.current.contains(e.target)
      ) {
        setMobileFilterOpened(!mobileFilterOpened);
        document.body.classList = "";
      }
    };

    if (mobileFilterOpened) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [mobileFilterOpened]);

  return (
    <Layout>
      <main role="main" className="py-6">
        <AlgoliaSearchContextComponent>
          <Configure
            filters={`type:Download AND locale:${language}`}
            hitsPerPage={10}
          />
          {getMobileOverlay()}
          {getMobileFilter()}
          <div className="container">
            <div className="my-0 pt-6 pb-0 py-xl-0">
              <div className="row">
                <h1 className="w-auto d-inline m-0 col-10">{t("downloads")}</h1>
                {getOpenMobileFilterButton()}
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 offset-xl-0 d-none d-lg-block">
                  <div className="filter-background search-filters px-1 py-4 border border-primary-500">
                    <div className="global-search mb-4 mx-3 d-flex flex-row">
                      <div
                        className="w-full search-wrapper position-relative bg-white border-radius-pill border border-primary-500 hover-border-quaternary-500 focus-border-quaternary-500 font-size-400"
                        role="search"
                      >
                        <SearchBox
                          defaultRefinement={
                            location?.state?.searchQuery
                              ? location.state.searchQuery
                              : ""
                          }
                          autoFocus={true}
                          translations={{
                            placeholder: t("search-here"),
                            submitTitle: t("submit-search-query"),
                          }}
                        />
                      </div>
                    </div>
                    <div className="mx-3 d-flex flex-column">
                      <h2 className="mt-0 mb-2 h3-styled">{t("categories")}</h2>
                      <RefinementList attribute="primaryCategory" />
                      <div className="mt-3 d-flex ">
                        <ClearRefinements
                          // Optional parameters
                          clearsQuery={true}
                          translations={{
                            reset: t("remove-all-filters"),
                          }}
                          className="btn w-full font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-0 search-result-list">
                  <h2 className="mt-0 mb-4">
                    <ConnectedSearchHeaderComponent />
                  </h2>
                  <InfiniteHits
                    hitComponent={DownloadHitComponent}
                    translations={{
                      loadMore: t("show-more"),
                    }}
                  />
                  <ConnectedEmptyMessageComponent />
                </div>
              </div>
            </div>
          </div>
        </AlgoliaSearchContextComponent>
      </main>
    </Layout>
  );

  function getMobileFilter() {
    return (
      <div
        className={`${
          mobileFilterOpened ? "d-flex" : "d-none"
        } d-lg-none align-items-center line-height-normal`}
      >
        <div
          className="dropdown-content position-fixed top-0 pb-4 horizontal-center overflow-auto w-full shadow-lg mobile-filter filter-background"
          ref={mobileFilterRef}
        >
          <div className="px-6 py-1 search-filters">
            <div className="row">
              <h2 className="mb-2 col-10 h3-styled">{t("search-term")}</h2>
              <a
                className="font-weight-700 font-size-600 color-black col-2 align-items-center justify-content-end"
                onClick={() => {
                  setMobileFilterOpened(!mobileFilterOpened);
                  document.body.classList = "";
                }}
              >
                <span className="accessibility-visually-hidden">
                  {t("close-filters")}
                </span>
                <i aria-hidden={true} className="ti ti-x font-size-700" />
              </a>
              <div className="global-search mb-4 -flex flex-row">
                <div
                  className="w-full search-wrapper position-relative bg-white border-radius-pill border border-primary-500 hover-border-quaternary-500 focus-border-quaternary-500 font-size-400"
                  role="search"
                >
                  <SearchBox
                    defaultRefinement={
                      location?.state?.searchQuery
                        ? location.state.searchQuery
                        : ""
                    }
                    autoFocus={true}
                    translations={{
                      placeholder: t("search-here"),
                    }}
                  />
                </div>
              </div>
              <h2 className="mt-0 mb-2 h3-styled">{t("filter-results")}</h2>
              <RefinementList attribute="primaryCategory" />
              <div className="mt-3 d-flex ">
                <ClearRefinements
                  // Optional parameters
                  clearsQuery={true}
                  translations={{
                    reset: t("remove-all-filters"),
                  }}
                  className="btn w-full font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getOpenMobileFilterButton() {
    return (
      <div className="col-xl-8 mobile-quick-filter-button col-sm-2 d-flex justify-content-end align-items-center">
        <button
          ref={openMobileFilterRef}
          onClick={() => {
            setMobileFilterOpened(!mobileFilterOpened);
            document.body.className = "overflow-hidden";
          }}
          className="btn mobile-quick-filter-button font-weight-600 color-secondary-500 hover-color-white hover-bg-quaternary-500 hover-border-quaternary-500 focus-border-quaternary-500 border-radius-pill border border-2 border-secondary-500"
        >
          <i className="ti ti-filter font-size-700" />
          Filter
        </button>
      </div>
    );
  }

  function getMobileOverlay() {
    if (mobileFilterOpened) {
      return <div className="mobile-filter__overlay d-lg-none" />;
    }
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsDownloadOverviewPage(locale: $language) {
      seoMetaTags(locale: $language) {
        tags
      }
    }
  }
`;
